import React from "react";
import { Row, Col } from "antd";
import styles from "./formbutton.module.css";
// ===============================================================================
function FormButton(props) {
  const { submit, submit_text, reset, reset_text, cancel, cancel_text } = props;
  return (
    <Row className="mb-4 mt-4 border-top pt-2">
      <Col span={24} className="text-left">
        <button
          className={`${styles["save-button"]}`}
          onClick={() => {
            submit();
          }}
        >
          {submit_text ? submit_text : "Save"}
        </button>
        {reset ? (
          <button
            className={`ml-3 ${styles["reset-button"]}`}
            onClick={() => {
              reset();
            }}
          >
            {reset_text ? reset_text : "Reset"}
          </button>
        ) : (
          ""
        )}
        {cancel ? (
          <button
            className={`ml-3 ${styles["cancel-button"]}`}
            onClick={() => {
              cancel();
            }}
          >
            {cancel_text ? cancel_text : "Cancel"}
          </button>
        ) : (
          ""
        )}
      </Col>
    </Row>
  );
}
export default FormButton;
