import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { Image, message, Row, Col } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { consulting_scheme_api } from "../../library/consulting_scheme_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea/";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable/";
import PageHeading2 from "../part/PageHeading2";
import { add_sno } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FormImageUploadGroup from "../part/Form/FormImageUploadGroup";
import FilterInputGroup from "../part/Filter/FilterInputGroup";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";
import { PORTAL } from "../../currrent_info";
import { get_data } from "../server_data/get_data";
// ===============================================================================
class Consulting_scheme extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: consulting_scheme_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        scheme: { valMet: vj_val.checkLength, valLen: 1 },
        image: { valMet: vj_val.checkImage },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      uploadImageList: [],
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    scheme: {
      title: "Scheme",
      // width: 300,
      className: "scheme",
      sorter: true,
      ellipsis: true,
      order: 1,
    },
    active: {
      title: "Status",
      width: 120,
      className: "active",
      sorter: true,
      ellipsis: true,
      order: 5,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Active</span>;
        return <span className="text-danger font-weight-bold">Deactive</span>;
      },
    },
    image: {
      title: "Image",
      width: 120,
      className: "image",
      order: 6,
      custom: (text, record) => {
        if (text && text.length)
          return (
            <Image
              width={50}
              // src={`${consulting_scheme_api}/image/${text}`}
              src={`${PORTAL.api_url}uploaded_images/${text}`}
              alt="logo"
            />
          );
        return "";
      },
    },
  };
  imageUpload = async (fl) => {
    const { uploadImageList, Chabi } = this.state;
    const dont_run = uploadImageList.some(
      (upl) => upl.uid && upl.uid == fl.uid
    );
    if (dont_run) return false;
    const { originFileObj: file } = fl;
    const url = consulting_scheme_api + "/image_upload";
    const formData = new FormData();
    formData.append("file", file, file.name); //notice here
    const response = await myAxios({
      method: "post",
      url,
      request: formData,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      const { records } = data;
      this.setRequest({ name: "image", value: records[0].filename });
      const final_obj = { ...fl, ...records[0] };
      uploadImageList.push(final_obj);
      this.setState({ uploadImageList });
      this.setValidation({ name: "image", res: true });
    }
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
    const response = await get_data({
      primary: "consulting_scheme",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
        let { records } = data;
        records = add_sno(records);
        this.setState({
          records,
        });
      }
    } else console.log("Network Error");
    // const url = consulting_scheme_api;
    // const response = await myAxios({ method: "get", url,    header: { Chabi }, });
    // if (response.status == 200) {
    //   const { data } = response;
    //   if (data.status == 420) {
    //     this.setState({
    //       loggedUser: undefined,
    //       loginStatus: undefined,
    //     });
    //     doUserLogout();
    //   }
    //   if (data.status == 200) {
    //     let { records } = data;
    //     records = add_sno(records);
    //     this.setState({ records });
    //   }
    // } else console.log("Network Error");
  };
  addConsulting_scheme = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = consulting_scheme_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    console.log("add consulting_scheme ", response);
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
        message.success("Record Saved");
        const { records: r } = data;
        records.push({ ...r[0] });
        records = add_sno(records);
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };
  editConsulting_scheme = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const { scheme, _id, order, image } = request;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = consulting_scheme_api;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: {
        _id,
        scheme,
        image,
        order,
      },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout(); 
else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            rec["scheme"] = scheme;
            rec["image"] = image;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };
  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, doUserLogout, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${consulting_scheme_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  render() {
    console.log("consulting_scheme State ", this.state);
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    return (
      <>
        <PageHeading2
          active="Consulting Scheme"
          page={["Website Administration", "Consulting", "Consulting Scheme"]}
        >
          <button
            className="add-btn"
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          >
            Add New
          </button>
        </PageHeading2>
        <MainArea>
          {/* <Row style={{ marginTop: "20px" }}>
            <Col span={8}>
              <FilterSelectGroup
                id="active"
                name="active"
                label="Status"
                placeholder="Status"
                options={[
                  { text: "Active", value: "1" },
                  { text: "Deactive", value: "0" },
                ]}
                option_value="value"
                option_text="text"
                sendValue={this.set_filter}
              />
            </Col>
          </Row>
          <Row>
            <Col span={2} className="m-2">
              <button className="filter-btn" onClick={this.run_filter}>
                Filter
              </button>
            </Col>
          </Row> */}
          {records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              loadData={this.loadRecord}
              removeData={this.removeRecord}
              activeData={this.activeRecord}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Consulting Scheme"
          close={this.close_modal}
          className="drawer-form-consulting_scheme"
        >
          <FormContainer>
            <FormInputGroup
              id="scheme"
              name="scheme"
              label="Scheme"
              placeholder="Scheme"
              data={this.state}
              required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            <FormImageUploadGroup
              // src={`${page_api}/image/`}
              src={`${PORTAL.api_url}uploaded_images/`}
              id="image"
              name="image"
              label="Consulting Scheme Image"
              sub_label="(Size: Below 5Mb, Format: jpeg, jpg, png, Resolution: 217x90 pixel)"
              imageCount="1"
              data={this.state}
              required
              imageUpload={this.imageUpload}
              changeUploadImageList={this.changeUploadImageList}
            />
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editConsulting_scheme();
                else this.addConsulting_scheme();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Consulting_scheme);
