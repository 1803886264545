import React from "react";
import { Layout, Row, Col, Menu, Breadcrumb } from "antd";
import { connect } from "react-redux";
// ===============================================================================
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
// ===============================================================================
import TopNavDesktop from "./part/TopNavDesktop";
// import Gmail from "./Gmail";
import "../assets/custom-all.css";
import urls from "../urls";
import { PORTAL } from "../currrent_info";
import MySider from "./part/MySider";
import Footer from "./part/Footer";
// ===============================================================================
const { Header, Content } = Layout;
function LmsPortal(props) {
  const { loginStatus } = props;
  return (
    <Layout style={{ backgroundColor: "unset" }}>
      <Router basename={PORTAL.basename}>
        {/* it will hide on mobile screen */}
        {loginStatus ? (
          <Header
            style={{
              backgroundColor: "#e9ecef",
              position: "fixed",
              width: "100%",
              left: 0,
              top: 0,
              right: 0,
              zIndex: 1000,
            }}
          >
            {/* <span className=" d-none d-lg-block "> */}
            <TopNavDesktop />
            {/* </span> */}
          </Header>
        ) : (
          ""
        )}
        <Layout className="layout2">
          {loginStatus ? <MySider /> : ""}
          <Content style={{ backgroundColor: "#e9ecef" }}>
            <Routes>
              <Route
                exact
                path={urls.LOGIN.path}
                element={<urls.LOGIN.component />}
              />
              <Route
                exact
                path={urls.APITEST.path}
                element={<urls.APITEST.component />}
              />
              <Route
                path={urls.DASHBOARD.path}
                element={<urls.DASHBOARD.component />}
              />
              <Route
                path={urls.ACCREDITATION.path}
                element={<urls.ACCREDITATION.component />}
              />
              <Route
                path={urls.AUDITING_SCHEME.path}
                element={<urls.AUDITING_SCHEME.component />}
              />
              <Route path={urls.NDA.path} element={<urls.NDA.component />} />
              <Route
                path={urls.AUDITING_SERVICE_TYPE_POINT.path}
                element={<urls.AUDITING_SERVICE_TYPE_POINT.component />}
              />
              <Route
                path={urls.PAYMENT_ORDERS.path}
                element={<urls.PAYMENT_ORDERS.component />}
              />
              <Route
                path={urls.BULK_ORDER.path}
                element={<urls.BULK_ORDER.component />}
              />
              <Route
                path={urls.PURCHASE_ORDER.path}
                element={<urls.PURCHASE_ORDER.component />}
              />
              <Route
                path={urls.CAREER.path}
                element={<urls.CAREER.component />}
              />
              <Route
                path={urls.AUDITING_TYPE.path}
                element={<urls.AUDITING_TYPE.component />}
              />
              <Route
                path={urls.COUPON_LEARNER.path}
                element={<urls.COUPON_LEARNER.component />}
              />
              <Route
                path={urls.COUPON_ORGANIZATION.path}
                element={<urls.COUPON_ORGANIZATION.component />}
              />
              <Route
                path={urls.TICKET.path}
                element={<urls.TICKET.component />}
              />
              <Route
                path={urls.AUDITING_SERVICE.path}
                element={<urls.AUDITING_SERVICE.component />}
              />
              <Route
                path={urls.PRODUCT_FAM_POINTS.path}
                element={<urls.PRODUCT_FAM_POINTS.component />}
              />
              <Route
                path={urls.BANNER.path}
                element={<urls.BANNER.component />}
              />
              <Route
                path={urls.CLIENTS.path}
                element={<urls.CLIENTS.component />}
              />
              <Route
                path={urls.COMPENTENCY_UNITS.path}
                element={<urls.COMPENTENCY_UNITS.component />}
              />
              <Route
                path={urls.CORPORATE_BROCHURE.path}
                element={<urls.CORPORATE_BROCHURE.component />}
              />
              <Route
                path={urls.CONSULTANTS.path}
                element={<urls.CONSULTANTS.component />}
              />
              <Route
                path={urls.CONSULTING_SCHEME.path}
                element={<urls.CONSULTING_SCHEME.component />}
              />
              <Route
                path={urls.CONSULTING_SERVICE_TYPE_POINT.path}
                element={<urls.CONSULTING_SERVICE_TYPE_POINT.component />}
              />
              <Route
                path={urls.CONSULTING_TYPE.path}
                element={<urls.CONSULTING_TYPE.component />}
              />
              <Route
                path={urls.CONSULTING_SERVICE.path}
                element={<urls.CONSULTING_SERVICE.component />}
              />
              <Route
                path={urls.CONTACT_DETAILS.path}
                element={<urls.CONTACT_DETAILS.component />}
              />
              <Route
                path={urls.INDUSTRIES.path}
                element={<urls.INDUSTRIES.component />}
              />
              <Route
                path={urls.CONTENT.path}
                element={<urls.CONTENT.component />}
              />
              <Route
                path={urls.COURSE.path}
                element={<urls.COURSE.component />}
              />
              <Route
                path={urls.COURSE_CATEGORY.path}
                element={<urls.COURSE_CATEGORY.component />}
              />
              <Route
                path={urls.COURSE_TYPE.path}
                element={<urls.COURSE_TYPE.component />}
              />
              <Route
                path={urls.DELIVERY_METHOD.path}
                element={<urls.DELIVERY_METHOD.component />}
              />
              <Route
                path={urls.EVALUATION.path}
                element={<urls.EVALUATION.component />}
              />
              <Route
                path={urls.EVALUATION_QUESTION.path}
                element={<urls.EVALUATION_QUESTION.component />}
              />
              <Route
                path={urls.EVALUATION_SECTION.path}
                element={<urls.EVALUATION_SECTION.component />}
              />
              <Route path={urls.EXAM.path} element={<urls.EXAM.component />} />
              <Route
                path={urls.EXAM_SECTION.path}
                element={<urls.EXAM_SECTION.component />}
              />
              <Route path={urls.FAQ.path} element={<urls.FAQ.component />} />
              <Route
                path={urls.INSIGHT.path}
                element={<urls.INSIGHT.component />}
              />
              <Route
                path={urls.INSIGHT_CATEGORY.path}
                element={<urls.INSIGHT_CATEGORY.component />}
              />
              <Route
                path={urls.LANGUAGE.path}
                element={<urls.LANGUAGE.component />}
              />
              <Route path={urls.LCA.path} element={<urls.LCA.component />} />
              <Route
                path={urls.LEARNING_SCHEME.path}
                element={<urls.LEARNING_SCHEME.component />}
              />
              <Route
                path={urls.LOCATION.path}
                element={<urls.LOCATION.component />}
              />
              <Route
                path={urls.MAPPING.path}
                element={<urls.MAPPING.component />}
              />
              <Route
                path={urls.MESSAGE.path}
                element={<urls.MESSAGE.component />}
              />
              <Route
                path={urls.NEWSLETTER.path}
                element={<urls.NEWSLETTER.component />}
              />
              <Route
                path={urls.NEWSUPDATE.path}
                element={<urls.NEWSUPDATE.component />}
              />
              <Route path={urls.TEAM.path} element={<urls.TEAM.component />} />
              <Route
                path={urls.ORGANIZATION.path}
                element={<urls.ORGANIZATION.component />}
              />
              <Route
                path={urls.REVIEW_QUESTION.path}
                element={<urls.REVIEW_QUESTION.component />}
              />
              <Route
                path={urls.REGION.path}
                element={<urls.REGION.component />}
              />
              <Route path={urls.SEO.path} element={<urls.SEO.component />} />
              <Route
                path={urls.SETTINGS.path}
                element={<urls.SETTINGS.component />}
              />
              <Route
                path={urls.SOCIAL_MEDIA.path}
                element={<urls.SOCIAL_MEDIA.component />}
              />
              <Route
                path={urls.TEAM_GROUP.path}
                element={<urls.TEAM_GROUP.component />}
              />
              <Route path={urls.USER.path} element={<urls.USER.component />} />
              <Route
                path={urls.VENUE.path}
                element={<urls.VENUE.component />}
              />
              <Route
                path={urls.TESTIMONIAL.path}
                element={<urls.TESTIMONIAL.component />}
              />
              <Route
                path={urls.TRAINING.path}
                element={<urls.TRAINING.component />}
              />
              <Route
                path={urls.TRAINING365.path}
                element={<urls.TRAINING365.component />}
              />
              <Route
                path={urls.TRAINING_BUNDAL.path}
                element={<urls.TRAINING_BUNDAL.component />}
              />
              <Route
                path={urls.TRAINING_ENQUIRY.path}
                element={<urls.TRAINING_ENQUIRY.component />}
              />
              <Route
                path={urls.COURSE_ENQUIRY.path}
                element={<urls.COURSE_ENQUIRY.component />}
              />
              <Route
                path={urls.CONTACT_ENQUIRY.path}
                element={<urls.CONTACT_ENQUIRY.component />}
              />
              <Route
                path={urls.BANNER.path}
                element={<urls.BANNER.component />}
              />
              <Route
                path={urls.CLIENTS.path}
                element={<urls.CLIENTS.component />}
              />
              <Route
                path={urls.TESTIMONIAL.path}
                element={<urls.TESTIMONIAL.component />}
              />
              <Route
                path={urls.LOCATION.path}
                element={<urls.LOCATION.component />}
              />
              <Route
                path={urls.VENUE.path}
                element={<urls.VENUE.component />}
              />
              <Route
                path={urls.REGION.path}
                element={<urls.REGION.component />}
              />
              <Route
                path={urls.CONTENT.path}
                element={<urls.CONTENT.component />}
              />
              <Route
                path={urls.LOGOUT.path}
                element={<urls.LOGOUT.component />}
              />
              <Route
                path={urls.LEARNERS.path}
                element={<urls.LEARNERS.component />}
              />
              <Route path={urls.USER.path} element={<urls.USER.component />} />
              <Route
                path={urls.NOT_FOUND.path}
                element={<urls.NOT_FOUND.component />}
              />
              {/* <Route
                  path={urls.CHANGE_PASSWORD.path}
                  element={<urls.CHANGE_PASSWORD.component />}
                /> */}
            </Routes>
          </Content>
        </Layout>
        {/* {loginStatus ? (
          <Footer style={{ backgroundColor: "#e9ecef", height: "30px" }}>
            i am footer
          </Footer>
        ) : (
          ""
        )} */}
      </Router>
    </Layout>
  );
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
export default connect(mapStateToProps)(LmsPortal);
