import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
// ===============================================================================
// ===============================================================================
import FormParent from "./FormParent";
import PageHeading2 from "../part/PageHeading2";
import MainArea from "../part/MainArea";
// ===============================================================================
class Dashboard extends FormParent {
  constructor(props) {
    super(props);
    const { match } = this.props;
    let useruid;

    if (match) {
      const { params } = match;
      useruid = params.useruid;
    }
    this.state = {
      useruid: useruid ? useruid : 0,
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
    };
  }
  render() {
    console.log("Dashboard State ", this.state);
    if (this.props.loginStatus != true) return <Navigate to="/" />;
    return (
      <>
        <PageHeading2 active="Region" page={["Dashboard"]}></PageHeading2>
        <MainArea></MainArea>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
