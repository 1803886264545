import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Row, Col } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { newsletter_api } from "../../library/newsletter_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormTextEditorGroup from "../part/Form/FormTextEditorGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FilterInputGroup from "../part/Filter/FilterInputGroup";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";
import FormMultiSelectGroup from "../part/Form/FormMultiSelectGroup";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import { get_learners, get_region } from "../server_data/list_data";
import { get_data } from "../server_data/get_data";
// ===============================================================================
class Newsletter extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: newsletter_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        subject: { valMet: vj_val.checkLength, valLen: 1 },
        region: { valMet: vj_val.checkLength, valLen: 1 },
        learners: { valMet: vj_val.checkLength, valLen: 1 },
        message: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    subject: {
      title: "Subject",
      // width: 300,
      className: "subject",
      sorter: true,
      ellipsis: true,
      order: 1,
    },
    region: {
      title: "region",
      // width: 300,
      className: "region",
      sorter: true,
      ellipsis: true,
      order: 2,
      custom: (text, record) => this.state.region2[text],
    },
    learners: {
      title: "learners",
      // width: 300,
      className: "learners",
      sorter: true,
      ellipsis: true,
      order: 3,
      custom: (text, record) => this.state.learner2[text],
    },
    message: {
      title: "Message",
      // width: 300,
      className: "message",
      sorter: true,
      ellipsis: true,
      order: 4,
    },
    // active: {
    //   title: "Status",
    //   width: 120,
    //   className: "active",
    //   sorter: true,
    //   ellipsis: true,
    //   order: 5,
    //   custom: (text, record) => {
    //     if (text)
    //       return <span className="text-success font-weight-bold">Active</span>;
    //     return <span className="text-danger font-weight-bold">Deactive</span>;
    //   },
    // },
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
    const response = await get_data({
      others: ["region", "learner"],
      primary: "newsletter",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let { region, learner, records } = data;
        records = add_sno(records);
        let learner2 = create_object({
          arr: learner,
          title: "first_name",
        });
        let region2 = create_object({
          arr: region,
          title: "region_name",
        });
        this.setState({
          region,
          region2,
          learner,
          learner2,
          records,
        });
      }
    } else console.log("Network Error");
    // const learner = await get_learners({ Chabi });
    // let learner2 = {};
    // learner.forEach((l) => {
    //   learner2[l._id] = l.first_name + " " + l.last_name;
    // });
    // const region = await get_region({ Chabi });
    // let region2 = {};
    // region.forEach((l) => {
    //   region2[l._id] = l.region_name;
    // });
    // this.setState({
    //   region,
    //   region2,
    //   learner,
    //   learner2,
    // });
    // const url = newsletter_api;
    // const response = await myAxios({ method: "get", url, header: { Chabi } });
    // if (response.status == 200) {
    //   const { data } = response;
    //   if (data.status == 420) {
    //     this.setState({
    //       loggedUser: undefined,
    //       loginStatus: undefined,
    //     });
    //     doUserLogout();
    //   }
    //   if (data.status == 200) {
    //     let { records } = data;
    //     records = add_sno(records);
    //     this.setState({ records });
    //   }
    // } else console.log("Network Error");
  };
  addNewsletter = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = newsletter_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    console.log("add newsletter ", response);
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
        const { records: r } = data;
        records.push({ ...r[0] });
        records = add_sno(records);
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };
  // editNewsletter = async () => {
  //   this.setState({ showErr: true });
  //   const val_result = this.checkValidation();
  //   if (!val_result) return false;
  //   const { request, Chabi } = this.state;
  //   let { records } = this.state;
  //   const { subject, _id, region, learners, message: message1 } = request;
  //   // --------------------------------------------------------------------------------
  //   // --------------------------------------------------------------------------------
  //   const url = newsletter_api;
  //   const response = await myAxios({
  //     method: "put",
  //     url,
  //     header: { Chabi },
  //     request: { _id, subject, region, learners, message: message1 },
  //   });
  //   if (response.status == 200) {
  //     const { data } = response;
  //     if (data.status == 420) {
  //       this.setState({
  //         loggedUser: undefined,
  //         loginStatus: undefined,
  //       });
  //       doUserLogout();
  //     } else if (data.status == 2000) {
  //       message.success("Nothing Changed");
  //       this.setState({
  //         validation: {},
  //         request: {},
  //         cmodal: false,
  //       });
  //     } else if (data.status == 200) {
  //       message.success("Record Updated");
  //       records = records.map((rec) => {
  //         if (rec._id == _id) {
  //           rec["subject"] = subject;
  //           rec["region"] = region;
  //           rec["learners"] = learners;
  //           rec["message"] = message1;
  //         }
  //         return rec;
  //       });
  //       this.setState({
  //         records,
  //         validation: {},
  //         request: {},
  //         cmodal: false,
  //       });
  //     } else {
  //       const { messages } = data;
  //       if (messages) return alert(messages);
  //     }
  //   } else alert("Network Error !");
  // };
  // fastEdit = async (obj) => {
  //   const { field, value, record } = obj;
  //   let { records, doUserLogout, Chabi } = this.state;
  //   if (record[field] == value) return;
  //   const _id = record["_id"];
  //   const url = `${newsletter_api}/f`;
  //   const response = await myAxios({
  //     method: "put",
  //     url,
  //     header: { Chabi },
  //     request: { _id, [field]: value },
  //   });
  //   if (response.status == 200) {
  //     const { data } = response;
  //     if (data.status == 420) {
  //       this.setState({
  //         loggedUser: undefined,
  //         loginStatus: undefined,
  //       });
  //       doUserLogout();
  //     }
  //     if (data.status == 200) {
  //       records = records.map((r) => {
  //         if (r._id == _id) r[field] = value;
  //         return r;
  //       });
  //       this.setState({ records });
  //       return true;
  //     } else {
  //       const { messages } = data;
  //       if (messages) {
  //         message.error(messages);
  //         return false;
  //       }
  //     }
  //   } else alert("Network Error !");
  // };
  render() {
    console.log("newsletter State ", this.state);
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
      region,
      learner,
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    // console.log(
    //   "filter ",
    //   learner.filter(({ region }) => {
    //     if (request.region) return request.region.split(",").includes[region];
    //     else return true;
    //   })
    // );
    let final_learners = learner;
    if (request.region) {
      const region_id_array = request.region.split(",");
      final_learners = learner.filter(({ region }) => {
        return region_id_array.includes(region);
      });
    }
    return (
      <>
        <PageHeading2 active="Newsletter" page={["Newsletter"]}>
          <button
            className="add-btn"
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          >
            Add New
          </button>
        </PageHeading2>
        <MainArea>
          {/* <Row style={{ marginTop: "20px" }}>
            <Col span={8}>
              <FilterSelectGroup
                id="active"
                name="active"
                label="Status"
                placeholder="Status"
                options={[
                  { text: "Active", value: "1" },
                  { text: "Deactive", value: "0" },
                ]}
                option_value="value"
                option_text="text"
                sendValue={this.set_filter}
              />
            </Col>
          </Row>
          <Row>
            <Col span={2} className="m-2">
              <button className="filter-btn" onClick={this.run_filter}>
                Filter
              </button>
            </Col>
          </Row> */}
          {records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              // loadData={this.loadRecord}
              // removeData={this.removeRecord}
              // activeData={this.activeRecord}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Newsletter"
          close={this.close_modal}
          className="drawer-form-newsletter"
        >
          <FormContainer>
            <FormInputGroup
              id="subject"
              name="subject"
              label="Subject"
              placeholder="Subject"
              data={this.state}
              required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            <FormMultiSelectGroup
              allowClear
              id="region"
              name="region"
              label="Region"
              placeholder="Region"
              data={this.state}
              required
              options={region}
              option_value="_id"
              option_text="region_name"
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            <FormMultiSelectGroup
              allowClear
              id="learners"
              name="learners"
              label="Learner"
              placeholder="Learner"
              data={this.state}
              required
              options={final_learners}
              option_value="_id"
              option_text="first_name"
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            {/* <FormInputGroup
              id="learners"
              name="learners"
              label="Learners"
              placeholder="Learners"
              data={this.state}required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            /> */}
            <FormTextEditorGroup
              id="message"
              name="message"
              label="Message"
              placeholder="Message"
              data={this.state}
              required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editNewsletter();
                else this.addNewsletter();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Newsletter);
