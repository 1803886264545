import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { Image, message, Row, Col } from "antd";
import { Tabs } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { learners_api } from "../../library/learners_api";
import { user_training_api } from "../../library/user_training_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormEmailGroup from "../part/Form/FormEmailGroup";
import FormMobileGroup from "../part/Form/FormMobileGroup";
import FormPasswordGroup from "../part/Form/FormPasswordGroup";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FormImageUploadGroup from "../part/Form/FormImageUploadGroup";
import { country_array } from "../local_data/variousCountryListFormats";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import { PORTAL } from "../../currrent_info";
import CustomOffCanvas from "../part/CustomOffCanvas";
import { get_data } from "../server_data/get_data";
const { TabPane } = Tabs;
// ===============================================================================
class Learners extends FormParent {
    constructor(props) {
        super(props);
        this.state = {
            page_api: learners_api,
            filter: {},
            records: [],
            request: {},
            request2: {},
            validation: {},
            validation_info: {
                first_name: { valMet: vj_val.checkLength, valLen: 1 },
                last_name: { valMet: vj_val.checkLength, valLen: 1 },
                email: { valMet: vj_val.checkEmail },
                mobile: { valMet: vj_val.checkMobile },
                organization: { valMet: vj_val.checkLength, valLen: 1 },
                address: { valMet: vj_val.checkLength, valLen: 1 },
                city: { valMet: vj_val.checkLength, valLen: 1 },
                zip_code: { valMet: vj_val.checkLength, valLen: 1 },
                country: { valMet: vj_val.checkLength, valLen: 1 },
                region: { valMet: vj_val.checkLength, valLen: 1 },
                // ============================================================
                // ============================================================
            },
            Chabi: props.Chabi,
            loggedUser: props.loggedUser,
            loginStatus: props.loginStatus,
            doUserLogout: props.doUserLogout,
            cmodal: false,
            uploadImageList: [],
            activeKey: "1",
            coffcanvas: false,
            selectedRecord: {},
            nextTabMsg: "",
            chPwdMsg: "",
            training: [],
        };
    }
    displayFields = {
        sno: {
            title: "SN",
            width: 70,
            defaultSortOrder: "descend",
            sorter: true,
            order: 0,
        },
        first_name: {
            title: "First Name",
            // width: 300,
            className: "first_name",
            sorter: true,
            ellipsis: true,
            order: 1,
        },
        last_name: {
            title: "Last Name",
            // width: 300,
            className: "last_name",
            sorter: true,
            ellipsis: true,
            order: 2,
        },
        email: {
            title: "Email",
            // width: 300,
            className: "email",
            sorter: true,
            ellipsis: true,
            order: 3,
        },
        // mobile: {
        //   title: "Mobile",
        //   // width: 300,
        //   className: "mobile",
        //   sorter: true,
        //   order: 4,
        // },
        // organization: {
        //   title: "organization",
        //   // width: 300,
        //   className: "organization",
        //   sorter: true,
        //   order: 5,
        // },
        // fax: {
        //   title: "fax",
        //   // width: 300,
        //   className: "fax",
        //   sorter: true,
        //   order: 6,
        // },
        // address: {
        //   title: "address",
        //   // width: 300,
        //   className: "address",
        //   sorter: true,
        //   order: 7,
        // },
        // city: {
        //   title: "city",
        //   // width: 300,
        //   className: "city",
        //   sorter: true,
        //   order: 8,
        // },
        // zip_code: {
        //   title: "zip_code",
        //   // width: 300,
        //   className: "zip_code",
        //   sorter: true,
        //   order: 9,
        // },
        // country: {
        //   title: "Country",
        //   // width: 300,
        //   className: "country",
        //   order: 10,
        //   sorter: true,
        //   custom: (text, record) => countryListAlpha2[text],
        // },
        // region: {
        //   title: "region",
        //   // width: 300,
        //   className: "region",
        //   sorter: true,
        //   order: 11,
        //   custom: (text, record) => this.state.region2[text],
        // },
        email_varified: {
            title: "Email Verified",
            // width: 300,
            className: "email_varified",
            sorter: true,
            ellipsis: true,
            order: 12,
            custom: (text, record) => {
                if (text)
                    return <span className="text-success font-weight-bold">Yes</span>;
                return <span className="text-danger font-weight-bold">No</span>;
            },
        },
        admin_varified: {
            title: " Verified By Admin",
            // width: 300,
            className: "admin_varified",
            sorter: true,
            ellipsis: true,
            order: 13,
            custom: (text, record) => {
                if (text)
                    return <span className="text-success font-weight-bold">Yes</span>;
                return <span className="text-danger font-weight-bold">No</span>;
            },
        },
        active: {
            title: "Status",
            width: 120,
            className: "active",
            sorter: true,
            ellipsis: true,
            order: 14,
            custom: (text, record) => {
                if (text)
                    return <span className="text-success font-weight-bold">Active</span>;
                return <span className="text-danger font-weight-bold">Deactive</span>;
            },
        },
        image: {
            title: "Image",
            width: 120,
            className: "image",
            order: 13,
            custom: (text, record) => {
                if (text && text.length)
                    return (
                        <Image
                            width={50}
                            // src={`${learners_api}/image/${text}`}
                            src={`${PORTAL.api_url}uploaded_images/${text}`}
                            alt="logo"
                        />
                    );
                return "";
            },
        },
    };
    imageUpload = async (fl) => {
        const { uploadImageList, Chabi } = this.state;
        const dont_run = uploadImageList.some(
            (upl) => upl.uid && upl.uid == fl.uid
        );
        if (dont_run) return false;
        const { originFileObj: file } = fl;
        const url = learners_api + "/image_upload";
        const formData = new FormData();
        formData.append("file", file, file.name); //notice here
        const response = await myAxios({
            method: "post",
            url,
            request: formData,
            header: { Chabi },
        });
        if (response.status == 200) {
            const { data } = response;
            const { records } = data;
            this.setRequest({ name: "image", value: records[0].filename });
            const final_obj = { ...fl, ...records[0] };
            uploadImageList.push(final_obj);
            this.setState({ uploadImageList });
            this.setValidation({ name: "image", res: true });
        }
    };
    componentDidMount = async () => {
        const { Chabi } = this.state;
        const response = await get_data({
            others: [
                "organization",
                "region",
                "language",
                "delivery_method",
                "training",
                "course",
            ],
            primary: "learner",
            Chabi,
        });
        // console.log("respons ", response);
        if (response.status == 200) {
            const { data } = response;
            if (data.status == 420) this.setLogout();
            else if (data.status == 200) {
                let {
                    records,
                    organization,
                    region,
                    language,
                    delivery_method,
                    course,
                    training,
                } = data;
                console.log("course=====", course);
                records = add_sno(records);
                let course2 = create_object({
                    arr: course,
                    title: "main_head",
                });
                console.log("course2=> ", course2);
                let language2 = create_object({
                    arr: language,
                    title: "language", // attribute change and value displayed
                });
                let delivery_method2 = create_object({
                    arr: delivery_method,
                    title: "title",  // attribute change and value displayed
                });
                let organization2 = create_object({
                    arr: organization,
                    title: "organization_name",
                });
                let region2 = create_object({ arr: region, title: "region_name" });
                const course_training = training.map((trn) => {
                    trn["course_name"] = course2[trn["course_name"]];
                    return trn;
                });
                this.setState({
                    language,
                    language2,
                    delivery_method,
                    delivery_method2,
                    organization,
                    organization2,
                    region,
                    region2,
                    records,
                    course,
                    course2,
                    training,
                    course_training,
                });
            }
        } else console.log("Network Error");
        //   const region = await get_region({ Chabi });
        //   let region2 = {};
        //   region.forEach((l) => {
        //     region2[l._id] = l.region_name;
        //   });
        //   const organization = await get_organization({ Chabi });
        //   let organization2 = {};
        //   organization.forEach((l) => {
        //     organization2[l._id] = l.organization_name;
        //   });
        //   this.setState({
        //     region,
        //     region2,
        //     organization,
        //     organization2,
        //   });
        //   const url = learners_api + "/get";
        //   const response = await myAxios({ method: "post", url, header: { Chabi } });
        //   if (response.status == 200) {
        //     const { data } = response;
        //     if (data.status == 420) {
        //       this.setState({
        //         loggedUser: undefined,
        //         loginStatus: undefined,
        //       });
        //       doUserLogout();
        //     }
        //     if (data.status == 200) {
        //       let { records } = data;
        //       records = add_sno(records);
        //       this.setState({ records });
        //     }
        //   } else console.log("Network Error");
    };
    addLearners = async () => {
        this.setState({ showErr: true });
        const val_result = this.checkValidation();
        if (!val_result) {
            this.setState({
                nextTabMsg: "Please fill Detail inforamtion on next tab",
            });
            return false;
        }
        // --------------------------------------------------------------------------------
        // --------------------------------------------------------------------------------
        const { request, Chabi } = this.state;
        let { records } = this.state;
        const url = learners_api + "/add";
        const response = await myAxios({
            method: "post",
            url,
            header: { Chabi },
            request,
        });
        console.log("add learner ", response);
        if (response.status == 200) {
            const { data } = response;
            if (data.status == 420) this.setLogout();
            else if (data.status == 200) {
                message.success("Record Saved");
                const { records: r } = data;
                records.push({ ...r[0] });
                records = add_sno(records);
                this.setState({
                    records,
                    request: {},
                    validation: {},
                    uploadImageList: [],
                    cmodal: false,
                    activeKey: "1",
                    nextTabMsg: "",
                });
            } else {
                const { error, message } = data;
                if (message) return console.log(message);
            }
        } else alert("Network Error !");
    };
    editLearners = async () => {
        this.setState({ showErr: true });
        const val_result = this.checkValidation();
        if (!val_result) {
            this.setState({
                nextTabMsg: "Please fill Detail inforamtion on next tab",
            });
            return false;
        }
        const { request, Chabi } = this.state;
        let { records } = this.state;
        const {
            _id,
            image,
            first_name,
            last_name,
            email,
            mobile,
            organization,
            fax,
            address,
            city,
            zip_code,
            country,
            region,
        } = request;
        // --------------------------------------------------------------------------------
        // --------------------------------------------------------------------------------
        const url = learners_api;
        const response = await myAxios({
            method: "put",
            url,
            header: { Chabi },
            request: {
                _id,
                image,
                first_name,
                last_name,
                email,
                mobile,
                organization,
                fax,
                address,
                city,
                zip_code,
                country,
                region,
            },
        });
        if (response.status == 200) {
            const { data } = response;
            if (data.status == 420) this.setLogout();
            else if (data.status == 2000) {
                message.success("Nothing Changed");
                this.setState({
                    validation: {},
                    request: {},
                    cmodal: false,
                    nextTabMsg: "",
                });
            } else if (data.status == 200) {
                message.success("Record Updated");
                records = records.map((rec) => {
                    if (rec._id == _id) {
                        rec["image"] = image;
                        rec["first_name"] = first_name;
                        rec["last_name"] = last_name;
                        rec["email"] = email;
                        rec["mobile"] = mobile;
                        rec["organization"] = organization;
                        rec["fax"] = fax;
                        rec["address"] = address;
                        rec["city"] = city;
                        rec["zip_code"] = zip_code;
                        rec["country"] = country;
                        rec["region"] = region;
                    }
                    return rec;
                });
                this.setState({
                    records,
                    validation: {},
                    request: {},
                    cmodal: false,
                    activeKey: "1",
                    nextTabMsg: "",
                });
            } else {
                const { messages } = data;
                if (messages) return alert(messages);
            }
        } else alert("Network Error !");
    };
    fastEdit = async (obj) => {
        const { field, value, record } = obj;
        let { records, doUserLogout, Chabi } = this.state;
        if (record[field] == value) return;
        const _id = record["_id"];
        const url = `${learners_api}/f`;
        const response = await myAxios({
            method: "put",
            url,
            header: { Chabi },
            request: { _id, [field]: value },
        });
        if (response.status == 200) {
            const { data } = response;
            if (data.status == 420) this.setLogout();
            else if (data.status == 200) {
                records = records.map((r) => {
                    if (r._id == _id) r[field] = value;
                    return r;
                });
                this.setState({ records });
                return true;
            } else {
                const { messages } = data;
                if (messages) {
                    message.error(messages);
                    return false;
                }
            }
        } else alert("Network Error !");
    };
    nextManage = ({ activeKey }) => {
        if (activeKey == "2") {
            const fields = [
                "first_name",
                "last_name",
                "email",
                "mobile",
                "organization",
            ];
            const { val_result, reason } = this.checkNextValidation({
                fields,
            });
            if (val_result) this.setState({ activeKey: "2" });
            else this.setState({ validation: reason });
        } else this.setState({ activeKey: "1" });
    };
    render() {
        // console.log("learner State ", this.state);
        let {
            records,
            edit,
            page_api,
            loginStatus,
            cmodal,
            request,
            request2,
            language2,
            delivery_method2,
            filtered_records,
            organization2,
            region2,
            region,
            organization,
            activeKey,
            coffcanvas,
            selectedRecord,
            Chabi,
            nextTabMsg,
            chPwdMsg,
            training,
            course,
            course2,
            course_training,
        } = this.state;
        // console.log("this state", training);
        console.log("selectedRecord.extra.pre_order=> ", selectedRecord);
        const { region_info } = request;
        if (loginStatus != true) return <Navigate to="/" />;
        if (filtered_records) records = filtered_records;
        records = [...records];
        const columns = createColumn({
            records,
            displayFields: this.displayFields,
            fastEdit: this.fastEdit,
        });
        columns.push({
            title: "Details",
            className: "details",
            // width: 70,
            dataIndex: "details",
            render: (text, selectedRecord) => {
                return (
                    <span
                        onClick={async () => {
                            const { Chabi } = this.state;
                            const url = learners_api + "/get_full";
                            const response = await myAxios({
                                method: "post",
                                url,
                                request: { _id: selectedRecord._id },
                                header: { Chabi },
                            });
                            // console.log("response ", response);
                            let learner = {};
                            let organization = {};
                            let user_training = [];
                            let pre_order = [];
                            if (response.status == 200) {
                                learner = response.data.records[0];
                                organization = response.data.organization[0];
                                pre_order = response.data.pre_order;
                                user_training = response.data.user_training;
                            }
                            this.setState({
                                coffcanvas: true,
                                selectedRecord: {
                                    ...selectedRecord,
                                    learner,
                                    extra: { organization, pre_order, user_training },
                                },
                            });
                        }}
                        className="btn btn-warning btn-sm"
                    >
                        Details
                    </span>
                );
            },
        });
        return (
            <>
                <PageHeading2 active="Learners" page={["Learners"]}>
                    <button
                        className="add-btn"
                        onClick={() => {
                            this.setState({ cmodal: !cmodal, edit: false, request: {} });
                        }}
                    >
                        Add New
                    </button>
                </PageHeading2>
                <MainArea>
                    {/* <Row style={{ marginTop: "20px" }}>
                        <Col span={8}>
                            <FilterSelectGroup
                                id="active"
                                name="active"
                                label="Status"
                                placeholder="Status"
                                options={[
                                    { text: "Active", value: "1" },
                                    { text: "Deactive", value: "0" },
                                ]}
                                option_value="value"
                                option_text="text"
                                sendValue={this.set_filter}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={2} className="m-2">
                            <button className="filter-btn" onClick={this.run_filter}>
                                Filter
                            </button>
                        </Col>
                    </Row> */}
                    {records && records.length && columns && columns.length > 0 ? (
                        <MyDataTable
                            rowKey="_id"
                            columns={columns}
                            dataSource={records}
                            loadData={this.loadRecord}
                            removeData={this.removeRecord}
                            activeData={this.activeRecord}
                            pagination={true}
                        />
                    ) : (
                        <MyEmpty />
                    )}
                </MainArea>
                <CustomModal
                    key={request._id}
                    status={cmodal}
                    title="Manage Learners"
                    close={this.close_modal}
                    className="modal-form-course"
                >
                    <FormContainer>
                        <Tabs
                            defaultActiveKey={"1"}
                            activeKey={activeKey}
                            onChange={(activeKey) => {
                                this.nextManage({ activeKey });
                            }}
                        >
                            <TabPane tab="Info" key="1">
                                <FormImageUploadGroup
                                    // src={`${page_api}/image/`}
                                    src={`${PORTAL.api_url}uploaded_images/`}
                                    id="image"
                                    name="image"
                                    label="Learner Image"
                                    sub_label="(Optional) (Size: Below 5Mb, Format: jpeg, jpg, png, Resolution: 217x90 pixel)"
                                    imageCount="1"
                                    data={this.state}
                                    imageUpload={this.imageUpload}
                                    changeUploadImageList={this.changeUploadImageList}
                                />
                                <Row>
                                    <Col span={8}>
                                        <FormInputGroup
                                            allowClear
                                            id="first_name"
                                            name="first_name"
                                            label="First Name"
                                            placeholder="First Name"
                                            data={this.state}
                                            required
                                            setValidation={this.setValidation}
                                            sendValue={this.setRequest}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <FormInputGroup
                                            allowClear
                                            id="last_name"
                                            name="last_name"
                                            label="Last Name"
                                            placeholder="Last Name"
                                            data={this.state}
                                            required
                                            setValidation={this.setValidation}
                                            sendValue={this.setRequest}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <FormEmailGroup
                                            id="email"
                                            name="email"
                                            label="Email"
                                            placeholder="Email"
                                            data={this.state}
                                            required
                                            setValidation={this.setValidation}
                                            sendValue={this.setRequest}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col span={8}>
                                        <FormMobileGroup
                                            id="mobile"
                                            name="mobile"
                                            label="Mobile"
                                            placeholder="Mobile"
                                            data={this.state}
                                            required
                                            setValidation={this.setValidation}
                                            sendValue={this.setRequest}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <FormSelectGroup
                                            allowClear
                                            id="organization"
                                            name="organization"
                                            label="Organization Name"
                                            placeholder="Organization Name"
                                            data={this.state}
                                            required
                                            options={organization}
                                            option_value="_id"
                                            option_text="organization_name"
                                            setValidation={this.setValidation}
                                            sendValue={this.setRequest}
                                        />
                                    </Col>
                                    <Col span={8}>
                                        <FormInputGroup
                                            type="number"
                                            id="fax"
                                            name="fax"
                                            label="Fax"
                                            sub_label="(Optional)"
                                            placeholder="Fax"
                                            data={this.state}
                                            setValidation={this.setValidation}
                                            sendValue={this.setRequest}
                                        />
                                    </Col>
                                </Row>
                                <button
                                    className="add-btn"
                                    onClick={() => {
                                        this.nextManage({ activeKey: "2" });
                                    }}
                                >
                                    Next
                                </button>
                                <span className="d-inline-block ml-2 text-danger">
                                    {nextTabMsg}
                                </span>
                            </TabPane>
                            <TabPane tab="Details" key="2">
                                <Row>
                                    <Col span={24}>
                                        <FormTextareaGroup
                                            id="address"
                                            name="address"
                                            label="Address"
                                            placeholder="Address"
                                            data={this.state}
                                            required
                                            setValidation={this.setValidation}
                                            sendValue={this.setRequest}
                                        />
                                    </Col>
                                </Row>
                                <Row className="mb-4">
                                    <Col span={6}>
                                        <FormInputGroup
                                            id="city"
                                            name="city"
                                            label="City"
                                            placeholder="City"
                                            data={this.state}
                                            required
                                            setValidation={this.setValidation}
                                            sendValue={this.setRequest}
                                        />
                                    </Col>
                                    <Col span={6}>
                                        <FormInputGroup
                                            id="zip_code"
                                            name="zip_code"
                                            label="Zip Code"
                                            placeholder="Zip Code"
                                            data={this.state}
                                            required
                                            setValidation={this.setValidation}
                                            sendValue={this.setRequest}
                                        />
                                    </Col>
                                    <Col span={6}>
                                        <FormSelectGroup
                                            id="country"
                                            name="country"
                                            label="Country"
                                            placeholder="Country"
                                            data={this.state}
                                            required
                                            options={country_array}
                                            option_value="country_code"
                                            option_text="country_name"
                                            setValidation={this.setValidation}
                                            sendValue={this.setRequest}
                                        />
                                    </Col>
                                    <Col span={6}>
                                        <FormSelectGroup
                                            allowClear
                                            id="region"
                                            name="region"
                                            label="Region"
                                            placeholder="Region"
                                            data={this.state}
                                            required
                                            options={region}
                                            option_value="_id"
                                            option_text="region_name"
                                            setValidation={this.setValidation}
                                            sendValue={this.setRequest}
                                        />
                                    </Col>
                                </Row>
                                <button
                                    className="add-btn"
                                    onClick={() => {
                                        this.nextManage({ activeKey: "1" });
                                    }}
                                >
                                    Back
                                </button>
                            </TabPane>
                        </Tabs>
                        <FormButton
                            submit={() => {
                                //check validation status here
                                if (edit) this.editLearners();
                                else this.addLearners();
                            }}
                            reset={() => this.setState({ request: {} })}
                            cancel={this.close_modal}
                        />
                    </FormContainer>
                </CustomModal>
                {selectedRecord._id ? (
                    <CustomOffCanvas
                        key={selectedRecord._id}
                        status={coffcanvas}
                        placement={"right"}
                        title="Manage Users"
                        close={() => {
                            this.setState({
                                coffcanvas: false,
                                selectedRecord: {},
                                chPwdMsg: "",
                            });
                        }}
                        className="drawer-form-organization"
                    >
                        <Tabs defaultActiveKey="1" onChange={() => { }}>
                            <TabPane tab="Profile" key="1">
                                <div className="bg-light p-2 mt-2">
                                    {[selectedRecord].map((profile) => {
                                        const {
                                            city,
                                            country,
                                            email,
                                            first_name,
                                            last_name,
                                            mobile,
                                            organization,
                                            region,
                                        } = profile;
                                        return (
                                            <div className="p-2">
                                                <p className="info-main-heading">Learner Details</p>
                                                <div className="row mt-1 mb-1">
                                                    <div className="col-4 text-left">
                                                        <span className="d-block info-heading">Name</span>
                                                        <span className="d-block info-value">
                                                            {first_name} {` `}
                                                            {last_name}
                                                        </span>
                                                    </div>
                                                    <div className="col-4">
                                                        <span className="d-block info-heading">Email</span>
                                                        <span className="d-block info-value">{email}</span>
                                                    </div>
                                                    <div className="col-4">
                                                        <span className="d-block info-heading">Mobile</span>
                                                        <span className="d-block info-value">{mobile}</span>
                                                    </div>
                                                </div>
                                                <div className="row mt-1 mb-1">
                                                    <div className="col-4">
                                                        <span className="d-block info-heading">
                                                            Organization
                                                        </span>
                                                        <span className="d-block info-value">
                                                            {organization2[organization]}
                                                        </span>
                                                    </div>
                                                    <div className="col-4">
                                                        <span className="d-block info-heading">
                                                            Country
                                                        </span>
                                                        <span className="d-block info-value">
                                                            {country}
                                                        </span>
                                                    </div>
                                                    <div className="col-4 text-left">
                                                        <span className="d-block info-heading">Region</span>
                                                        <span className="d-block info-value">
                                                            {region2[region]}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </TabPane>
                            <TabPane tab="Trainings" key="2">
                                {selectedRecord.extra.user_training.length ? (
                                    <div className="bg-light p-2 mt-2">
                                        <p className="info-main-heading mt-3">Training Details </p>
                                        {selectedRecord.extra.user_training.map((t) => {
                                            const {
                                                status,
                                                order_id,
                                                user_id,
                                                create_at,
                                                training,
                                                removed_by_admin,
                                            } = t;
                                            const {
                                                _id: training_id,
                                                certificate_fees,
                                                course_fees,
                                                course_name,
                                                languages: lang,
                                                delivery_method: dm,
                                                is365,
                                                material_fees,
                                                start_date,
                                                start_time,
                                                timezone,
                                            } = training;
                                            return (
                                                <div
                                                    style={{
                                                        background: "#ffffff",
                                                        marginBottom: "20px",
                                                        padding: "10px",
                                                    }}
                                                >
                                                    <div className="row mt-1 mb-1">
                                                        <div className="col-4 text-left">
                                                            <span className="d-block info-heading">
                                                                Course
                                                            </span>
                                                            <span className="d-block info-value">
                                                                {course2[course_name]}
                                                            </span>
                                                        </div>
                                                        {is365 ? (
                                                            // "" // commented by govind
                                                            <div className="col-4">
                                                                <span className="d-block info-heading">
                                                                    Delivery Method
                                                                </span>
                                                                <span className="d-block info-value">
                                                                    {dm
                                                                        ? dm
                                                                            .split(",")
                                                                            .map((t) => delivery_method2[t])
                                                                            .toString(",")
                                                                        : ""}
                                                                </span>
                                                            </div>
                                                        ) : (
                                                            <div className="col-4">
                                                                <span className="d-block info-heading">
                                                                    Delivery Method
                                                                </span>
                                                                <span className="d-block info-value">
                                                                    {dm
                                                                        ? dm
                                                                            .split(",")
                                                                            .map((t) => delivery_method2[t])
                                                                            .toString(",")
                                                                        : ""}
                                                                </span>
                                                            </div>
                                                        )}
                                                        <div className="col-4">
                                                            <span className="d-block info-heading">
                                                                Languages
                                                            </span>
                                                            <span className="d-block info-value">
                                                                {lang
                                                                    ? lang
                                                                        .split(",")
                                                                        .map((t) => language2[t])
                                                                        .toString(",")
                                                                    : ""}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    {is365 ? (
                                                        ""
                                                    ) : (
                                                        <div className="row mt-1 mb-1">
                                                            <div className="col-4 text-left">
                                                                <span className="d-block info-heading">
                                                                    Date
                                                                </span>
                                                                <span className="d-block info-value">
                                                                    {start_date}
                                                                </span>
                                                            </div>
                                                            <div className="col-4">
                                                                <span className="d-block info-heading">
                                                                    Time
                                                                </span>
                                                                <span className="d-block info-value">
                                                                    {start_time}
                                                                </span>
                                                            </div>
                                                            <div className="col-4">
                                                                <span className="d-block info-heading">
                                                                    Timezone
                                                                </span>
                                                                <span className="d-block info-value">
                                                                    {timezone}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <div className="row">
                                                        <div className="col-4 text-left">
                                                            <span className="d-block info-heading">
                                                                Course Fees
                                                            </span>
                                                            <span className="d-block info-value">
                                                                {course_fees}
                                                            </span>
                                                        </div>
                                                        <div className="col-4">
                                                            <span className="d-block info-heading">
                                                                Certificate Fees
                                                            </span>
                                                            <span className="d-block info-value">
                                                                {certificate_fees}
                                                            </span>
                                                        </div>
                                                        <div className="col-4">
                                                            <span className="d-block info-heading">
                                                                Material Fees
                                                            </span>
                                                            <span className="d-block info-value">
                                                                {material_fees}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-3">
                                                        <div className="col-4 text-left">
                                                            {removed_by_admin ? (
                                                                <a className="btn btn-warning btn-sm">
                                                                    Removed
                                                                </a>
                                                            ) : (
                                                                <a
                                                                    className="btn btn-danger btn-sm"
                                                                    onClick={async () => {
                                                                        if (!window.confirm("Are you sure?"))
                                                                            return false;
                                                                        const { Chabi } = this.state;
                                                                        const url = user_training_api + "/remove";
                                                                        const response = await myAxios({
                                                                            method: "put",
                                                                            url,
                                                                            request: {
                                                                                order_id,
                                                                                training_id,
                                                                                user_id,
                                                                            },
                                                                            header: { Chabi },
                                                                        });
                                                                        // console.log("response ", response);
                                                                        if (
                                                                            response.status == 200 &&
                                                                            response.data.status == 200
                                                                        ) {
                                                                            const selectedRecord2 =
                                                                                selectedRecord.extra.user_training.map(
                                                                                    (t) => {
                                                                                        if (
                                                                                            t.order_id == order_id &&
                                                                                            t.training._id == training_id
                                                                                        ) {
                                                                                            t["removed_by_admin"] = 1;
                                                                                            t["removed"] = 1;
                                                                                        }
                                                                                        return t;
                                                                                    }
                                                                                );
                                                                            selectedRecord["extra"]["user_training"] =
                                                                                selectedRecord2;
                                                                            this.setState({ selectedRecord });
                                                                        } else {
                                                                        }
                                                                    }}
                                                                >
                                                                    Remove Training
                                                                </a>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <MyEmpty />
                                )}
                            </TabPane>
                            <TabPane tab="Payments" key="3">
                                {selectedRecord.extra.pre_order.length ? (
                                    <div className="bg-light p-2 mt-2">
                                        <p className="info-main-heading mt-3">Order Details </p>
                                        {selectedRecord.extra.pre_order.map((t) => {
                                            const {
                                                amount,
                                                create_at,
                                                discount,
                                                items,
                                                payment_ref,
                                                status,
                                                tax_amount,
                                                total_amount,
                                                _id: pre_order_id,
                                            } = t;
                                            return (
                                                <div
                                                    style={{
                                                        background: "#ffffff",
                                                        marginBottom: "20px",
                                                        padding: "10px",
                                                    }}
                                                >
                                                    <div className="row mt-1 mb-1">
                                                        <div className="col-3 text-left">
                                                            <span className="d-block info-heading">Date</span>
                                                            <span className="d-block info-value">
                                                                {create_at}
                                                            </span>
                                                        </div>
                                                        <div className="col-3 text-left">
                                                            <span className="d-block info-heading">
                                                                Order id
                                                            </span>
                                                            <span className="d-block info-value">
                                                                {pre_order_id}
                                                            </span>
                                                        </div>
                                                        <div className="col-3">
                                                            <span className="d-block info-heading">
                                                                Payment Code
                                                            </span>
                                                            <span className="d-block info-value">
                                                                {payment_ref}
                                                            </span>
                                                        </div>
                                                        <div className="col-3">
                                                            <span className="d-block info-heading">
                                                                Status
                                                            </span>
                                                            <span className="d-block info-value">
                                                                {status}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row mt-1 mb-1">
                                                        <div className="col-3 text-left">
                                                            <span className="d-block info-heading">
                                                                Amount
                                                            </span>
                                                            <span className="d-block info-value">
                                                                {amount}
                                                            </span>
                                                        </div>
                                                        <div className="col-3 text-left">
                                                            <span className="d-block info-heading">
                                                                Tax Amount
                                                            </span>
                                                            <span className="d-block info-value">
                                                                {tax_amount}
                                                            </span>
                                                        </div>
                                                        <div className="col-3">
                                                            <span className="d-block info-heading">
                                                                Discount
                                                            </span>
                                                            <span className="d-block info-value">
                                                                {discount}
                                                            </span>
                                                        </div>
                                                        <div className="col-3">
                                                            <span className="d-block info-heading">
                                                                Total Amount
                                                            </span>
                                                            <span className="d-block info-value">
                                                                {total_amount}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-4">
                                                            <span className="d-block info-heading">
                                                                Total Training
                                                            </span>
                                                            <span className="d-block info-value">
                                                                {items.length}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <MyEmpty />
                                )}
                            </TabPane>
                            <TabPane tab="Reset Password" key="4">
                                <Row className="border">
                                    <Col spanb={8}>
                                        <Row>
                                            <Col span={24}>
                                                <FormPasswordGroup
                                                    id="password"
                                                    name="password"
                                                    label="Password"
                                                    placeholder="Password"
                                                    value={request2["password"]}
                                                    sendValue={this.setRequest2}
                                                />
                                            </Col>
                                            <Col span={24}>
                                                <FormPasswordGroup
                                                    id="cpassword"
                                                    name="cpassword"
                                                    label="Confirm Password"
                                                    placeholder="Confirm Password"
                                                    value={request2["cpassword"]}
                                                    sendValue={this.setRequest2}
                                                />
                                            </Col>
                                            <Col span={24} className="m-2">
                                                {chPwdMsg == "1" ? (
                                                    <span
                                                        className="text-success"
                                                        style={{ fontSize: "16px", fontWeight: "bold" }}
                                                    >
                                                        Learner Password Changed
                                                    </span>
                                                ) : chPwdMsg == "0" ? (
                                                    <span
                                                        className="text-danger"
                                                        style={{ fontSize: "16px", fontWeight: "bold" }}
                                                    >
                                                        Somethign Wrong
                                                    </span>
                                                ) : chPwdMsg == "-1" ? (
                                                    <span
                                                        className="text-danger"
                                                        style={{ fontSize: "16px", fontWeight: "bold" }}
                                                    >
                                                        Password and Confirm Password must be same and not
                                                        blank
                                                    </span>
                                                ) : (
                                                    ""
                                                )}
                                            </Col>
                                            <Col span={24} className="m-2">
                                                <FormButton
                                                    reset={() => {
                                                        this.setState({ request2: {}, chPwdMsg: "" });
                                                    }}
                                                    submit={async () => {
                                                        // console.log("request2 ", request2);
                                                        const { password, cpassword } = request2;
                                                        if (
                                                            password &&
                                                            cpassword &&
                                                            password.length >= 6 &&
                                                            password == cpassword
                                                        ) {
                                                            const url = learners_api + "/cpa";
                                                            const response = await myAxios({
                                                                method: "put",
                                                                url,
                                                                header: { Chabi },
                                                                request: {
                                                                    _id: selectedRecord._id,
                                                                    ...request2,
                                                                },
                                                            });
                                                            if (response.status == 200) {
                                                                this.setState({ request2: {}, chPwdMsg: "1" });
                                                            } else this.setState({ chPwdMsg: "0" });
                                                        } else this.setState({ chPwdMsg: "-1" });
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col spanb={8}></Col>
                                    <Col spanb={8}></Col>
                                </Row>
                            </TabPane>
                            <TabPane tab="Assign" key="5">
                                <Row className="mb-4">
                                    <Col span={8}>
                                        <FormSelectGroup
                                            allowClear
                                            id="Training"
                                            name="Training"
                                            label="training"
                                            placeholder="training"
                                            // data={this.state}
                                            // required
                                            options={course_training}
                                            option_value="_id"
                                            option_text="course_name"
                                            // setValidation={this.setValidation}
                                            sendValue={({ value }) => {
                                                this.setState({ training_id: value });
                                            }}
                                        />
                                    </Col>
                                    <FormButton
                                        submit={async () => {
                                            if (!window.confirm("Are you sure?")) return false;
                                            const { selectedRecord, training_id, Chabi } = this.state;
                                            if (training_id && training_id.length) {
                                                const { _id: user_id } = selectedRecord;
                                                const url = user_training_api + "/add";
                                                const response = await myAxios({
                                                    method: "post",
                                                    url,
                                                    header: { Chabi },
                                                    request: { user_id, training_id },
                                                });
                                                // console.log("add course ", response);
                                                if (
                                                    response.status == 200 &&
                                                    response.data.status == 200
                                                ) {
                                                    const { records } = response.data;
                                                    selectedRecord["extra"]["user_training"].push(
                                                        records
                                                    );
                                                    this.setState({ selectedRecord, training_id: "" });
                                                    alert("Training assigned to learner");
                                                }
                                            } else alert("please select training ");
                                        }}
                                    />
                                </Row>
                            </TabPane>
                        </Tabs>
                        {/* <div className="row bg-success" style={{ height: "500px" }}></div>
                        <div className="row">
                            <div className="col">
                                <input
                                    type="button"
                                    value="Verified"
                                    className="btn btn-success"
                                    onClick={() => {
                                        if (!window.confirm("Are you sure?")) return false;
                                        this.fastEdit({
                                            field: "admin_varified",
                                            value: 1,
                                            record: selectedRecord,
                                        });
                                    }}
                                />
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                            <div className="col"></div>
                        </div> */}
                    </CustomOffCanvas>
                ) : (
                    ""
                )}
            </>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        loginStatus: state.loginStatus,
        Chabi: state.Chabi,
        loggedUser: state.loggedUser,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        doUserLogout: () => dispatch(userLogout()),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Learners);
