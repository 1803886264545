import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import CustomOffCanvas from "../part/CustomOffCanvas";
// ===============================================================================
import { order_org_api } from "../../library/order_org_api";
import { organization_api } from "../../library/organization_api";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import { countryListAlpha2 } from "../local_data/variousCountryListFormats";
import { get_data } from "../server_data/get_data";
// ===============================================================================
class Bulk_order extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: order_org_api,
      filter: {},
      records: [],
      request: {},
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      uploadImageList: [],
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    user_id: {
      title: "User Id",
      // width: 300,
      sorter: true,
      ellipsis: true,
      order: 1,
    },
    payment_ref: {
      title: "Payment Ref",
      // width: 300,
      sorter: true,
      ellipsis: true,
      order: 2,
    },
    gatway_ref: {
      title: "Gateway Ref",
      // width: 300,
      sorter: true,
      ellipsis: true,
      order: 3,
    },
    amount: {
      title: "Amount",
      // width: 300,
      sorter: true,
      ellipsis: true,
      order: 4,
    },
    discount: {
      title: "Discount",
      // width: 300,
      sorter: true,
      ellipsis: true,
      order: 5,
    },
    create_at: {
      title: "Date",
      // width: 300,
      sorter: true,
      ellipsis: true,
      order: 6,
    },
    status: {
      title: "Status",
      // width: 300,
      sorter: true,
      ellipsis: true,
      order: 7,
    },
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
    const response = await get_data({
      others: [
        "region",
        "organization",
        "course",
        "language",
        "delivery_method",
      ],
      primary: "order_org",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          region,
          records,
          organization,
          course,
          language,
          delivery_method,
        } = data;
        records = add_sno(records);
        let region2 = create_object({
          arr: region,
          title: "region_name",
        });
        let organization2 = create_object({
          arr: organization,
          title: "organization_name",
        });
        let course2 = create_object({
          arr: course,
          title: "main_head",
        });
        let language2 = create_object({
          arr: language,
          title: "language",
        });
        let delivery_method2 = create_object({
          arr: delivery_method,
          title: "title",
        });
        this.setState({
          records,
          region,
          region2,
          organization2,
          course2,
          language2,
          delivery_method2,
        });
      }
    } else console.log("Network Error");
  };
  render() {
    console.log("Pre Order State ", this.state);
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
      selectedRecord,
      coffcanvas,
      region2,
      organization2,
      course2,
      language2,
      delivery_method2,
    } = this.state;
    console.log("selectedRecord ", selectedRecord);
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    columns.push({
      title: "Details",
      className: "details",
      // width: 70,
      dataIndex: "details",
      render: (text, selectedRecord) => {
        return (
          <span
            onClick={async () => {
              const { Chabi } = this.state;
              const url = organization_api + "/get";
              const response = await myAxios({
                method: "post",
                url,
                request: { _id: selectedRecord.user_id },
                header: { Chabi },
              });
              let organization = {};
              if (response.status == 200)
                organization = response.data.records[0];
              this.setState({
                coffcanvas: true,
                selectedRecord: { ...selectedRecord, organization },
              });
            }}
            className="btn btn-warning btn-sm"
          >
            Details
          </span>
        );
      },
    });
    return (
      <>
        <PageHeading2
          active="Bulk Orders"
          page={["Orders", "Bulk Orders"]}
        ></PageHeading2>
        <MainArea>
          {/* <Row style={{ marginTop: "20px" }}>
            <Col span={8}>
              <FilterSelectGroup
                id="active"
                name="active"
                label="Status"
                placeholder="Status"
                options={[
                  { text: "Active", value: "1" },
                  { text: "Deactive", value: "0" },
                ]}
                option_value="value"
                option_text="text"
                sendValue={this.set_filter}
              />
            </Col>
          </Row>
          <Row>
            <Col span={2} className="m-2">
              <button className="filter-btn" onClick={this.run_filter}>
                Filter
              </button>
            </Col>
          </Row> */}
          {records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        {coffcanvas ? (
          <CustomOffCanvas
            key={selectedRecord._id}
            status={coffcanvas}
            placement={"right"}
            title="Order Details"
            close={() => {
              this.setState({ coffcanvas: false, selectedRecord: {} });
            }}
            className="drawer-form-order"
          >
            <div className="p-2">
              <p className="info-main-heading">Bulk Order Details</p>
              <div className="row mt-1 mb-1">
                <div className="col-4 text-left">
                  <span className="d-block info-heading">Booked On</span>
                  <span className="d-block info-value">
                    {selectedRecord.create_at}
                  </span>
                </div>
                <div className="col-4">
                  <span className="d-block info-heading">Amount</span>
                  <span className="d-block info-value">
                    {selectedRecord.total_amount}
                  </span>
                </div>
              </div>
              <div className="row mt-1 mb-1">
                <div className="col-4 text-left">
                  <span className="d-block info-heading">Status</span>
                  <span className="d-block info-value">
                    {selectedRecord.status}
                  </span>
                </div>
                <div className="col-4">
                  <span className="d-block info-heading">Discount</span>
                  <span className="d-block info-value">
                    {selectedRecord.discount}
                  </span>
                </div>
                <div className="col-4">
                  <span className="d-block info-heading">Tax</span>
                  <span className="d-block info-value">
                    {selectedRecord.tax}
                  </span>
                </div>
              </div>
              <div className="row mt-1 mb-1">
                <div className="col-6">
                  <span className="d-block info-heading">
                    Payment Refference
                  </span>
                  <span className="d-block info-value">
                    {selectedRecord.payment_ref}
                  </span>
                </div>
                <div className="col-6">
                  <span className="d-block info-heading">
                    Gateway Refference
                  </span>
                  <span className="d-block info-value">
                    {selectedRecord.gatway_ref}
                  </span>
                </div>
              </div>
            </div>
            {[selectedRecord.organization].map((organization) => {
              const {
                organization_name,
                email,
                mobile,
                country,
                region,
                website,
              } = organization;
              return (
                <div className="p-2">
                  <p className="info-main-heading">Organization Details</p>
                  <div className="row mt-1 mb-1">
                    <div className="col-4 text-left">
                      <span className="d-block info-heading">
                        Organization Name
                      </span>
                      <span className="d-block info-value">
                        {organization_name}
                      </span>
                    </div>
                    <div className="col-4">
                      <span className="d-block info-heading">Email</span>
                      <span className="d-block info-value">{email}</span>
                    </div>
                    <div className="col-4">
                      <span className="d-block info-heading">Mobile</span>
                      <span className="d-block info-value">{mobile}</span>
                    </div>
                  </div>
                  <div className="row mt-1 mb-1">
                    <div className="col-4">
                      <span className="d-block info-heading">Country</span>
                      <span className="d-block info-value">
                        {countryListAlpha2[country]}
                      </span>
                    </div>
                    <div className="col-4 text-left">
                      <span className="d-block info-heading">Region</span>
                      <span className="d-block info-value">
                        {region2[region]}
                      </span>
                    </div>
                    <div className="col-4 text-left">
                      <span className="d-block info-heading">Website</span>
                      <span className="d-block info-value">{website}</span>
                    </div>
                  </div>
                </div>
              );
            })}
            {[selectedRecord.product].map((product) => {
              const {
                delivery_method,
                languages,
                material_fees,
                start_date,
                start_time,
                timezone,
                duration,
                course_name,
                course_fees,
                certificate_fees,
              } = product;
              return (
                <div className="p-2">
                  <p className="info-main-heading">Training Details</p>
                  <div
                    style={{
                      background: "#ffffff",
                      marginBottom: "20px",
                      padding: "10px",
                    }}
                  >
                    <div className="row mt-1 mb-1">
                      <div className="col-12 text-left">
                        <span className="d-block info-heading">Course</span>
                        <span className="d-block info-value">
                          {course2[course_name]}
                        </span>
                      </div>
                    </div>
                    <div className="row mt-1 mb-1">
                      <div className="col-4">
                        <span className="d-block info-heading">
                          Delivery Method
                        </span>
                        <span className="d-block info-value">
                          {delivery_method
                            .split(",")
                            .map((dm) => {
                              return delivery_method2[dm];
                            })
                            .toString(",")}
                        </span>
                      </div>
                      <div className="col-4">
                        <span className="d-block info-heading">Languages</span>
                        <span className="d-block info-value">
                          {languages
                            .split(",")
                            .map((lm) => {
                              return language2[lm];
                            })
                            .toString(",")}
                        </span>
                      </div>
                    </div>
                    <div className="row mt-1 mb-1">
                      <div className="col-4 text-left">
                        <span className="d-block info-heading">Date</span>
                        <span className="d-block info-value">{start_date}</span>
                      </div>
                      <div className="col-4">
                        <span className="d-block info-heading">Time</span>
                        <span className="d-block info-value">{start_time}</span>
                      </div>
                      <div className="col-4">
                        <span className="d-block info-heading">Timezone</span>
                        <span className="d-block info-value">{timezone}</span>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-4 text-left">
                        <span className="d-block info-heading">
                          Course Fees
                        </span>
                        <span className="d-block info-value">
                          {course_fees}
                        </span>
                      </div>
                      <div className="col-4">
                        <span className="d-block info-heading">
                          Certificate Fees
                        </span>
                        <span className="d-block info-value">
                          {certificate_fees}
                        </span>
                      </div>
                      <div className="col-4">
                        <span className="d-block info-heading">
                          Material Fees
                        </span>
                        <span className="d-block info-value">
                          {material_fees}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="p-2">
              <p className="info-main-heading">Learner Details</p>
              {selectedRecord.learners.map((learner) => {
                const { first_name, last_name, email } = learner;
                return (
                  <div className="row mt-1 mb-1">
                    <div className="col-4 text-left">
                      <span className="d-block info-heading">Learner Name</span>
                      <span className="d-block info-value">
                        {first_name} {` `}
                        {last_name}
                      </span>
                    </div>
                    <div className="col-4">
                      <span className="d-block info-heading">Email</span>
                      <span className="d-block info-value">{email}</span>
                    </div>
                  </div>
                );
              })}
            </div>
          </CustomOffCanvas>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Bulk_order);
