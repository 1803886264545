import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Row, Col } from "antd";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import CustomSwitch from "../part/Display/CustomSwitch";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { course_category_api } from "../../library/course_category_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FilterInputGroup from "../part/Filter/FilterInputGroup";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";
import { get_learning_scheme, get_region } from "../server_data/list_data";
import { get_data } from "../server_data/get_data";
import MyToolTip from "../part/MyToolTip/MyToolTip";
// ===============================================================================
class Course_category extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: course_category_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        // category: { valMet: vj_val.checkLength, valLen: 1 },
        main_head: { valMet: vj_val.checkLength, valLen: 1 },
        learning_scheme: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      order: 0,
      sorter: true,
      ellipsis: true,
    },
    main_head: {
      title: "Main Head",
      // width: 300,
      className: "main_head",
      sorter: true,
      ellipsis: true,
      order: 1,
    },
    learning_scheme: {
      title: "Scheme",
      // width: 300,
      className: "learning_scheme",
      order: 2,
      custom: (text, record) => this.state.learning_scheme2[text],
    },
    active: {
      title: "Status",
      width: 120,
      className: "active",
      sorter: true,
      ellipsis: true,
      order: 3,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Active</span>;
        return <span className="text-danger font-weight-bold">Deactive</span>;
      },
    },
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;

    const response = await get_data({
      others: ["region", "learning_scheme"],
      primary: "course_category",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let { region, learning_scheme, records } = data;
        records = add_sno(records);
        let learning_scheme2 = create_object({
          arr: learning_scheme,
          title: "main_head",
        });
        let region2 = create_object({
          arr: region,
          title: "region_name",
        });
        this.setState({
          region,
          region2,
          learning_scheme,
          learning_scheme2,
          records,
        });
      }
    } else console.log("Network Error");

    // const region = await get_region({ Chabi });
    // let region2 = {};
    // region.forEach((l) => {
    //   region2[l._id] = l.region_name;
    // });
    // this.setState({ region, region2 });
    // const learning_scheme = await get_learning_scheme({ Chabi });
    // let learning_scheme2 = {};
    // learning_scheme.forEach((l) => {
    //   learning_scheme2[l._id] = l.main_head;
    // });
    // this.setState({ learning_scheme, learning_scheme2 });
    // const url = course_category_api;
    // const response = await myAxios({ method: "get", url,    header: { Chabi }, });
    // if (response.status == 200) {
    //   const { data } = response;
    //   if (data.status == 420) {
    //     this.setState({
    //       loggedUser: undefined,
    //       loginStatus: undefined,
    //     });
    //     doUserLogout();
    //   }
    //   if (data.status == 200) {
    //     let { records } = data;
    //     records = add_sno(records);
    //     this.setState({ records });
    //   }
    // } else console.log("Network Error");
  };
  addCourse_category = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = course_category_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    console.log("add course_category ", response);
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
        const { records: r } = data;
        records.push({ ...r[0] });
        records = add_sno(records);
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };
  editCourse_category = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const { learning_scheme, _id, main_head, region_info } = request;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = course_category_api;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, learning_scheme, main_head, region_info },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            rec["learning_scheme"] = learning_scheme;
            rec["main_head"] = main_head;
            rec["region_info"] = region_info;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };
  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, doUserLogout, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${course_category_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  render() {
    console.log("course_category State ", this.state);
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
      learning_scheme,
      region,
      selectedTab,
    } = this.state;
    const { region_info } = request;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    let filter_region = [];
    if (request.learning_scheme) {
      const selected_region_info = learning_scheme.filter((ls) => {
        return ls._id == request.learning_scheme;
      })[0]["region_info"];
      const selected_region = Object.keys(selected_region_info).filter(
        (sri) => selected_region_info[sri].active
      );
      filter_region = region.filter((r1) => {
        return selected_region.includes(r1._id);
      });
    }
    console.log("filter_region ", filter_region);
    return (
      <>
        <PageHeading2
          active="Course Category"
          page={["Website Administration", "Learning", "Course Category"]}
        >
          <button
            className="add-btn"
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          >
            Add New
          </button>
        </PageHeading2>
        <MainArea>
          {/* <Row style={{ marginTop: "20px" }}>
            <Col span={8}>
              <FilterSelectGroup
                id="active"
                name="active"
                label="Status"
                placeholder="Status"
                options={[
                  { text: "Active", value: "1" },
                  { text: "Deactive", value: "0" },
                ]}
                option_value="value"
                option_text="text"
                sendValue={this.set_filter}
              />
            </Col>
          </Row>
          <Row>
            <Col span={2} className="m-2">
              <button className="filter-btn" onClick={this.run_filter}>
                Filter
              </button>
            </Col>
          </Row> */}
          {records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              loadData={this.loadRecord}
              removeData={this.removeRecord}
              activeData={this.activeRecord}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Course_category"
          close={this.close_modal}
          className="modal-form-course_category"
        >
          <FormContainer>
            <FormSelectGroup
              id="learning_scheme"
              name="learning_scheme"
              label="Scheme"
              placeholder="Scheme"
              data={this.state}
              required
              options={learning_scheme}
              option_value="_id"
              option_text="main_head"
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            <FormInputGroup
              id="main_head"
              name="main_head"
              label="Main Head"
              placeholder="Main Head"
              data={this.state}
              required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            {/* ================================== */}
            {filter_region && filter_region.length ? (
              <Tab.Container defaultActiveKey="default">
                <Row className="mt-4">
                  <Col span={4}>
                    <Nav variant="" className="flex-column">
                      {filter_region.map((r) => {
                        let single_region;
                        if (region_info)
                          single_region = { ...region_info[r._id] };
                        let active = 0;
                        if (single_region) active = single_region["active"];
                        return (
                          <Row
                            className={`region-nav-link ${
                              r._id == selectedTab ? "region-active" : ""
                            }`}
                          >
                            <MyToolTip
                              title={active == 1 ? "" : "Enable before select"}
                              placement="left"
                              color="#c6001d"
                            >
                              <Col span={18}>
                                <Nav.Link
                                  eventKey={r.region_name}
                                  disabled={active == 1 ? false : true}
                                  onClick={() => {
                                    this.setState({ selectedTab: r._id });
                                  }}
                                >
                                  {r.region_name}
                                </Nav.Link>
                              </Col>
                            </MyToolTip>
                            <Col span={6}>
                              <CustomSwitch
                                key={active}
                                activeData={(active) => {
                                  this.set_region_request({
                                    name: "active",
                                    value: +active,
                                    _id: r._id,
                                  });
                                }}
                                active={active}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    </Nav>
                  </Col>
                  <Col span={20} className="border pb-2">
                    <Tab.Content>
                      <Tab.Pane eventKey="default">
                        <MyEmpty message="Region Not Selected" />
                      </Tab.Pane>
                      {filter_region.map((r) => {
                        let single_region;
                        if (region_info)
                          single_region = { ...region_info[r._id] };
                        let category = "";
                        let active = false;
                        if (single_region) {
                          category = single_region["category"];
                          active = single_region["active"];
                        }
                        return (
                          <Tab.Pane eventKey={r.region_name}>
                            <Row className="mt-2">
                              <Col
                                span={20}
                                className="text-center font-weight-bold"
                              >
                                Region : {r.region_name}
                              </Col>
                              <Col span={4}>
                                <button
                                  className="add-btn"
                                  onClick={() => {
                                    const region_info2 = {};
                                    filter_region.forEach((r2) => {
                                      region_info2[r2._id] = {
                                        category,
                                        active,
                                      };
                                    });
                                    this.setState({
                                      request: {
                                        ...request,
                                        region_info: region_info2,
                                      },
                                    });
                                  }}
                                  // disabled={!active}
                                >
                                  Copy To All
                                </button>
                              </Col>
                            </Row>
                            <FormInputGroup
                              id="category"
                              name="category"
                              label="Category"
                              placeholder="Category"
                              data={this.state}
                              required
                              value={category}
                              sendValue={(obj) => {
                                this.set_region_request({ ...obj, _id: r._id });
                              }}
                              disabled={!active}
                            />
                          </Tab.Pane>
                        );
                      })}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            ) : (
              ""
            )}
            {/* ================================== */}
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editCourse_category();
                else this.addCourse_category();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Course_category);
