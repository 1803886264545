import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Row, Col, Button } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { training_api } from "../../library/training_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormMultiSelectGroup from "../part/Form/FormMultiSelectGroup";
import { get_data } from "../server_data/get_data";
import MyLoading from "../part/MyLoading";
import AddNew from "../part/AddNew";
import CustomOffCanvas from "../part/CustomOffCanvas";
// ===============================================================================
class Training_bundal extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: training_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        _id: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      total_training: 1,
      sub_training: [1],
      coffcanvas: false,
      selectedRecord: {},
      training_normal: [],
      all_training: [],
      region: [],
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    course_name: {
      title: "Course Name",
      width: 700,
      className: "course_name",
      sorter: true,
      order: 1,
      custom: (text, record) => this.state.course2[text],
    },
    region: {
      title: "Region",
      // width: 300,
      className: "region",
      order: 2,
      sorter: true,
      custom: (text, record) => this.state.region2[text],
    },
    training_bundel: {
      title: "Trainings",
      width: 200,
      className: "training_bundel",
      sorter: true,
      ellipsis: true,
      order: 3,
      ellipsis: true,
      custom: (text, record) => text.length,
    },
    course_fees: {
      title: "Course Fees",
      // width: 300,
      className: "course_fees",
      order: 4,
      sorter: true,
      ellipsis: true,
    },
  };
  componentDidMount = async () => {
    const { Chabi } = this.state;
    const response = await get_data({
      others: ["course", "region"],
      primary: "training",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let { records, course, region } = data;
        let course2 = create_object({
          arr: course,
          title: "main_head",
        });
        let region2 = create_object({
          arr: region,
          title: "region_name",
        });

        let bundeled_training = [];
        let training_365 = [];
        let training_normal = [];

        let all_training = records.map((r) => {
          r["course"] = course2[r.course_name];
          if (r.bundel && r.training_bundel && r.training_bundel.length)
            bundeled_training.push(r); //which are already bundeled
          else if (!r.is365) training_normal.push(r);

          if (r.is365) training_365.push(r);

          return r;
        });

        bundeled_training = add_sno(bundeled_training);
        this.setState({
          records: bundeled_training,
          training_365,
          all_training,
          training_normal,
          course,
          course2,
          region,
          region2,
        });
      }
    } else console.log("Network Error");
    this.setState({ CDM: true });
  };
  addTraining = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi, sub_training, all_training } = this.state;
    const { _id } = request;
    let valid_data = true;
    const training_bundel = sub_training.map((key) => {
      const training_id = request[`training_id_${key}`];
      const fees = request[`fees_${key}`];
      if (!training_id || !training_id.length || !fees) valid_data = false;
      return { training_id, fees };
    });
    if (!valid_data) {
      alert("Please Fill All Required Fields");
      return false;
    }
    const url = training_api + "/add_bundel";
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request: { _id, training_bundel },
    });
    if (response.status == 200) {
      if (response.data.status == 420) this.setLogout();
      else if (response.data.status == 200) {
        message.success("Bundel Saved");
        let all_training2 = all_training.map((r) => {
          if (r._id == _id) {
            r["bundel"] = 1;
            r["training_bundel"] = training_bundel;
          }
          return r;
        });
        let bundeled_training = [];
        const training_normal = [];
        all_training2.forEach((r) => {
          if (r.bundel && r.training_bundel && r.training_bundel.length)
            bundeled_training.push(r);
          else if (!r.is365) training_normal.push(r);
        });
        bundeled_training = add_sno(bundeled_training);
        this.setState({
          records: bundeled_training,
          all_training: all_training2,
          training_normal,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = response.data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };
  render() {
    console.log("training State ", this.state);
    let {
      request,
      records,
      loginStatus,
      cmodal,
      filtered_records,
      sub_training,
      total_training,
      training_365,
      training_normal,
      all_training,
      CDM,
      selectedRecord,
      region,
      coffcanvas,
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    columns.push({
      title: "Details",
      className: "details",
      // width: 70,
      dataIndex: "details",
      render: (text, selectedRecord) => {
        return (
          <span
            onClick={() => this.setState({ coffcanvas: true, selectedRecord })}
            className="btn btn-warning btn-sm"
          >
            Details
          </span>
        );
      },
    });

    if (request._id) {
      // ==================removing already selected training from bundel training list
      // const sub_training_ids = sub_training.map(
      //   (key) => request[`training_id_${key}`]
      // );
      // training_for_bundel = training_for_bundel.filter(
      //   ({ _id }) => !sub_training_ids.includes(_id)
      // );
    }
    return (
      <>
        <PageHeading2 active="Training" page={["Training"]}>
          <AddNew
            disabled={!CDM}
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          />
        </PageHeading2>
        <MainArea>
          {!CDM ? (
            <MyLoading />
          ) : records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              loadData={(_id) => {
                let { records, request } = this.state;
                const record = records.filter((r) => r._id == _id)[0];
                request = { ...request, ...record };
                const { training_bundel } = record;
                const total_training = training_bundel.length;
                const sub_training = training_bundel.map(
                  (t, index) => index + 1
                );
                sub_training.forEach((key, index) => {
                  request[`training_id_${key}`] =
                    training_bundel[index]["training_id"];
                  request[`fees_${key}`] = training_bundel[index]["fees"];
                });
                this.setState({
                  edit: true,
                  request,
                  cmodal: true,
                  total_training,
                  sub_training,
                });
              }}
              removeData={async (record) => {
                let { all_training, Chabi } = this.state;
                const { _id } = record;
                if (!window.confirm("Are you sure? Bundel will be delete!"))
                  return false;
                const url = training_api + "/delete_bundel";
                const response = await myAxios({
                  method: "post",
                  url,
                  header: { Chabi },
                  request: { _id },
                });
                if (response.status == 200) {
                  if (response.data.status == 420) this.setLogout();
                  else if (response.data.status == 200) {
                    message.success("Bundel Deleted");
                    let all_training2 = all_training.map((r) => {
                      if (r._id == _id) {
                        r["bundel"] = 0;
                        r["training_bundel"] = [];
                      }
                      return r;
                    });
                    let bundeled_training = [];
                    const training_normal = [];
                    all_training2.forEach((r) => {
                      if (
                        r.bundel &&
                        r.training_bundel &&
                        r.training_bundel.length
                      )
                        bundeled_training.push(r);
                      else if (!r.is365) training_normal.push(r);
                    });
                    bundeled_training = add_sno(bundeled_training);
                    this.setState({
                      records: bundeled_training,
                      all_training: all_training2,
                      training_normal,
                    });
                  }
                }
              }}
              // activeData={this.activeRecord}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          // key={request._id}
          status={cmodal}
          title="Training Bundel"
          close={this.close_modal}
          className="modal-form-course"
        >
          <FormContainer>
            <Row>
              <Col span={4}>
                <FormSelectGroup
                  allowClear
                  id="region"
                  name="region"
                  label="Region"
                  placeholder="Region"
                  data={this.state}
                  required
                  options={region}
                  option_value="_id"
                  option_text="region_name"
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={20}>
                <FormSelectGroup
                  allowClear
                  id="_id"
                  name="_id"
                  label="Training"
                  placeholder="Training"
                  data={this.state}
                  required
                  options={training_normal.filter(
                    ({ region }) => region == request.region
                  )}
                  option_value="_id"
                  option_text="course"
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
            </Row>
            {sub_training.map((key) => {
              return (
                <Row>
                  <Col span={2}>
                    <Button
                      className="bg-success text-light"
                      onClick={() => {
                        const total_training2 = total_training + 1;
                        sub_training.push(total_training2);
                        this.setState({
                          sub_training,
                          total_training: total_training2,
                        });
                      }}
                      style={{ marginTop: "38px" }}
                    >
                      Add
                    </Button>
                  </Col>
                  <Col span={16}>
                    <FormSelectGroup
                      allowClear
                      name={`training_id_${key}`}
                      label="Training"
                      placeholder="Training"
                      data={this.state}
                      required
                      options={training_365}
                      option_value="_id"
                      option_text="course"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={4}>
                    <FormInputGroup
                      type="number"
                      name={`fees_${key}`}
                      label="Course Fees"
                      placeholder="Course Fees"
                      data={this.state}
                      required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={2}>
                    {sub_training.length < 2 ? (
                      ""
                    ) : (
                      <Button
                        className="bg-danger text-light"
                        onClick={() => {
                          const sub_training2 = sub_training.filter(
                            (k) => k != key
                          );
                          this.setState({
                            sub_training: sub_training2,
                          });
                        }}
                        style={{ marginTop: "38px" }}
                      >
                        Remove
                      </Button>
                    )}
                  </Col>
                </Row>
              );
            })}
            <FormButton
              submit={() => {
                //check validation status here
                // if (edit) this.editTraining();
                // else
                this.addTraining();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
        {selectedRecord._id ? (
          <CustomOffCanvas
            key={selectedRecord._id}
            status={coffcanvas}
            placement={"right"}
            title="Manage Users"
            close={() => {
              this.setState({
                coffcanvas: false,
                selectedRecord: {},
                chPwdMsg: "",
              });
            }}
            className="drawer-form-organization"
          >
            <div className="bg-light p-2 mt-2">
              <p className="info-main-heading">Training Details</p>
              {[selectedRecord].map(({ training_bundel }) => {
                return training_bundel.map((tb) => {
                  const { training_id, fees } = tb;
                  const tran = all_training.filter(
                    ({ _id }) => _id == training_id
                  )[0];
                  const { course, course_fees } = tran;
                  return (
                    <div className="p-2">
                      <div className="row mt-1 mb-1">
                        <div className="col-8 text-left">
                          <span className="d-block info-heading">Course</span>
                          <span className="d-block info-value">{course}</span>
                        </div>
                        <div className="col-2 text-left">
                          <span className="d-block info-heading">
                            Actual Fees
                          </span>
                          <span className="d-block info-value">
                            {course_fees}
                          </span>
                        </div>
                        <div className="col-2">
                          <span className="d-block info-heading">Fees</span>
                          <span className="d-block info-value">{fees}</span>
                        </div>
                      </div>
                    </div>
                  );
                });
              })}
            </div>
          </CustomOffCanvas>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Training_bundal);
