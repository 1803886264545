import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { Image, message, Row, Col } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import CustomOffCanvas from "../part/CustomOffCanvas";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { organization_api } from "../../library/organization_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FormImageUploadGroup from "../part/Form/FormImageUploadGroup";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";
import {
  country_array,
  countryListAlpha2,
} from "../local_data/variousCountryListFormats";
import { get_region } from "../server_data/list_data";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import { PORTAL } from "../../currrent_info";
import { get_data } from "../server_data/get_data";
// ===============================================================================
class Organization extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: organization_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        organization_name: { valMet: vj_val.checkLength, valLen: 1 },
        email: { valMet: vj_val.checkEmail },
        region: { valMet: vj_val.checkLength, valLen: 1 },
        // ============================================================
        // ============================================================
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      uploadImageList: [],
      activeKey: "1",
      coffcanvas: false,
      selectedRecord: {},
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    organization_name: {
      title: "Organization Name",
      // width: 300,
      className: "organization_name",
      sorter: true,
      ellipsis: true,
      order: 1,
    },
    email: {
      title: "Email",
      // width: 300,
      className: "email",
      sorter: true,
      ellipsis: true,
      order: 3,
    },
    mobile: {
      title: "Mobile",
      // width: 300,
      className: "mobile",
      sorter: true,
      ellipsis: true,
      order: 4,
    },
    contact_person: {
      title: "Contact Person",
      // width: 300,
      className: "contact_person",
      sorter: true,
      ellipsis: true,
      order: 5,
    },
    region: {
      title: "Region",
      // width: 300,
      className: "region",
      sorter: true,
      ellipsis: true,
      order: 6,
    },
    country: {
      title: "Country",
      // width: 300,
      className: "country",
      sorter: true,
      ellipsis: true,
      order: 7,
    },
    active: {
      title: "Status",
      width: 120,
      className: "active",
      sorter: true,
      ellipsis: true,
      order: 12,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Active</span>;
        return <span className="text-danger font-weight-bold">Deactive</span>;
      },
    },
    admin_varified: {
      title: " Verified By Admin",
      // width: 300,
      className: "admin_varified",
      sorter: true,
      ellipsis: true,
      order: 13,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Yes</span>;
        return <span className="text-danger font-weight-bold">No</span>;
      },
    },
    active: {
      title: "Status",
      width: 120,
      className: "active",
      sorter: true,
      ellipsis: true,
      order: 14,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Active</span>;
        return <span className="text-danger font-weight-bold">Deactive</span>;
      },
    },
    image: {
      title: "Image",
      width: 120,
      className: "image",
      order: 15,
      custom: (text, record) => {
        if (text && text.length)
          return (
            <Image
              width={50}
              // src={`${organization_api}/image/${text}`}
              src={`${PORTAL.api_url}uploaded_images/${text}`}
              alt="logo"
            />
          );
        return "";
      },
    },
  };
  imageUpload = async (fl) => {
    const { uploadImageList, Chabi } = this.state;
    const dont_run = uploadImageList.some(
      (upl) => upl.uid && upl.uid == fl.uid
    );
    if (dont_run) return false;
    const { originFileObj: file } = fl;
    const url = organization_api + "/image_upload";
    const formData = new FormData();
    formData.append("file", file, file.name); //notice here
    const response = await myAxios({
      method: "post",
      url,
      request: formData,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      const { records } = data;
      this.setRequest({ name: "image", value: records[0].filename });
      const final_obj = { ...fl, ...records[0] };
      uploadImageList.push(final_obj);
      this.setState({ uploadImageList });
      this.setValidation({ name: "image", res: true });
    }
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
    const response = await get_data({
      others: ["region"],
      primary: "organization",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let { region, records } = data;
        records = add_sno(records);
        let region2 = create_object({
          arr: region,
          title: "region_name",
        });
        this.setState({
          region,
          region2,
          records,
        });
      }
    } else console.log("Network Error");
    // const region = await get_region({ Chabi });
    // let region2 = {};
    // region.forEach((l) => {
    //   region2[l._id] = l.region_name;
    // });
    // this.setState({ region, region2 });
    // const url = organization_api + "/get";
    // const response = await myAxios({ method: "post", url, header: { Chabi } });
    // if (response.status == 200) {
    //   const { data } = response;
    //   if (data.status == 420) {
    //     this.setState({
    //       loggedUser: undefined,
    //       loginStatus: undefined,
    //     });
    //     doUserLogout();
    //   }
    //   if (data.status == 200) {
    //     let { records } = data;
    //     records = add_sno(records);
    //     this.setState({ records });
    //   }
    // } else console.log("Network Error");
  };
  addOrganization = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = organization_api + "/add";
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    console.log("add course ", response);
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
        const { records: r } = data;
        records.push({ ...r[0] });
        records = add_sno(records);
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };
  editOrganization = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const {
      _id,
      image,
      organization_name,
      website,
      email,
      mobile,
      contact_person,
      designation,
      fax,
      address,
      city,
      postcode,
      country,
      region,
    } = request;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = organization_api + "/update";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: {
        _id,
        image,
        organization_name,
        website,
        email,
        mobile,
        contact_person,
        designation,
        fax,
        address,
        city,
        postcode,
        country,
        region,
      },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            rec["image"] = image;
            rec["organization_name"] = organization_name;
            rec["website"] = website;
            rec["email"] = email;
            rec["mobile"] = mobile;
            rec["contact_person"] = contact_person;
            rec["designation"] = designation;
            rec["fax"] = fax;
            rec["address"] = address;
            rec["city"] = city;
            rec["postcode"] = postcode;
            rec["country"] = country;
            rec["region"] = region;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };
  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    console.log("obj");
    let { records, doUserLogout, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${organization_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  render() {
    console.log("training State ", this.state);
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
      region,
      activeKey,
      coffcanvas,
      selectedRecord,
    } = this.state;
    console.log("selectedRecord ", selectedRecord);
    const { region_info } = request;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    columns.push({
      title: "Details",
      className: "details",
      // width: 70,
      dataIndex: "details",
      render: (text, selectedRecord) => {
        return (
          <span
            onClick={() => {
              this.setState({ coffcanvas: true, selectedRecord });
            }}
            className="btn btn-warning btn-sm"
          >
            Details
          </span>
        );
      },
    });
    return (
      <>
        <PageHeading2 active="Organization" page={["Organization"]}>
          <button
            className="add-btn"
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          >
            Add New
          </button>
        </PageHeading2>
        <MainArea>
          {/* <Row style={{ marginTop: "20px" }}>
            <Col span={8}>
              <FilterSelectGroup
                id="active"
                name="active"
                label="Status"
                placeholder="Status"
                options={[
                  { text: "Active", value: "1" },
                  { text: "Deactive", value: "0" },
                ]}
                option_value="value"
                option_text="text"
                sendValue={this.set_filter}
              />
            </Col>
          </Row>
          <Row>
            <Col span={2} className="m-2">
              <button className="filter-btn" onClick={this.run_filter}>
                Filter
              </button>
            </Col>
          </Row> */}
          {records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              loadData={this.loadRecord}
              removeData={this.removeRecord}
              activeData={this.activeRecord}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Organization"
          close={this.close_modal}
          className="modal-form-course"
        >
          <FormContainer>
            <Row>
              <Col span={6}>
                <FormInputGroup
                  allowClear
                  id="organization_name"
                  name="organization_name"
                  label="Organization Name"
                  placeholder="Organization Name"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={6}>
                <FormInputGroup
                  id="email"
                  name="email"
                  label="Email"
                  placeholder="Email"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={6}>
                <FormInputGroup
                  type="number"
                  id="mobile"
                  name="mobile"
                  label="Mobile"
                  sub_label="(Optional)"
                  placeholder="Mobile"
                  data={this.state}
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={6}>
                <FormInputGroup
                  type="number"
                  id="fax"
                  name="fax"
                  label="Fax"
                  sub_label="(Optional)"
                  placeholder="Fax"
                  data={this.state}
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <FormSelectGroup
                  id="country"
                  name="country"
                  label="Country"
                  sub_label="(Optional)"
                  placeholder="Country"
                  data={this.state}
                  options={country_array}
                  option_value="country_code"
                  option_text="country_name"
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={6}>
                <FormSelectGroup
                  allowClear
                  id="region"
                  name="region"
                  label="Region"
                  placeholder="Region"
                  data={this.state}
                  required
                  options={region}
                  option_value="_id"
                  option_text="region_name"
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={6}>
                <FormInputGroup
                  id="city"
                  name="city"
                  label="City"
                  sub_label="(Optional)"
                  placeholder="City"
                  data={this.state}
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={6}>
                <FormInputGroup
                  id="postcode"
                  name="postcode"
                  label="Postcode"
                  sub_label="(Optional)"
                  placeholder="Postcode"
                  data={this.state}
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <FormInputGroup
                  id="website"
                  name="website"
                  label="Website"
                  sub_label="(Optional)"
                  placeholder="Website"
                  data={this.state}
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={8}>
                <FormInputGroup
                  id="contact_person"
                  name="contact_person"
                  label="Contact Person"
                  sub_label="(Optional)"
                  placeholder="Contact Person"
                  data={this.state}
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={8}>
                <FormInputGroup
                  id="designation"
                  name="designation"
                  label="Contact Person Designation"
                  sub_label="(Optional)"
                  placeholder="Contact Person Designation"
                  data={this.state}
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <FormImageUploadGroup
                  // src={`${page_api}/image/`}
                  src={`${PORTAL.api_url}uploaded_images/`}
                  id="image"
                  name="image"
                  label="Organization Logo"
                  sub_label="(Optional) (Size: Below 5Mb, Format: jpeg, jpg, png, Resolution: 217x90 pixel)"
                  imageCount="1"
                  data={this.state}
                  imageUpload={this.imageUpload}
                  changeUploadImageList={this.changeUploadImageList}
                />
              </Col>
              <Col span={16}>
                <FormTextareaGroup
                  id="address"
                  name="address"
                  label="Address"
                  sub_label="(Optional)"
                  placeholder="Address"
                  data={this.state}
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
            </Row>
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editOrganization();
                else this.addOrganization();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
        <CustomOffCanvas
          key={selectedRecord._id}
          status={coffcanvas}
          placement={"right"}
          title="Manage Users"
          close={() => {
            this.setState({ coffcanvas: false, selectedRecord: {} });
          }}
          className="drawer-form-organization"
        >
          <span className="info-main-heading mt-3">Organization Details </span>
          <div className="row m-0 mt-1 mb-1">
            <div className="col-3 text-left">
              <span className="d-block info-heading">Company</span>
              <span className="d-block info-value">
                {selectedRecord.organization_name}
              </span>
            </div>
            <div className="col-3">
              <span className="d-block info-heading">Website</span>
              <span className="d-block info-value">
                {selectedRecord.website}
              </span>
            </div>
            <div className="col-3">
              <span className="d-block info-heading">Region</span>
              <span className="d-block info-value">
                {selectedRecord.region}
              </span>
            </div>
            <div className="col-3">
              <span className="d-block info-heading">Country</span>
              <span className="d-block info-value">
                {selectedRecord.country}
              </span>
            </div>
          </div>
          <div className="row m-0 mt-1 mb-1">
            <div className="col-3 text-left">
              <span className="d-block info-heading">Email</span>
              <span className="d-block info-value">{selectedRecord.email}</span>
            </div>
            <div className="col-3">
              <span className="d-block info-heading">Mobile</span>
              <span className="d-block info-value">
                {selectedRecord.mobile}
              </span>
            </div>
            <div className="col-3">
              <span className="d-block info-heading">Contact Person</span>
              <span className="d-block info-value">
                {selectedRecord.contact_person}
              </span>
            </div>
            <div className="col-3">
              <span className="d-block info-heading">Person Designation</span>
              <span className="d-block info-value">
                {selectedRecord.designation}
              </span>
            </div>
          </div>
          <div className="row m-0 mt-1 mb-1">
            <div className="col-3 text-left">
              <span className="d-block info-heading">Email Verified</span>
              <span className="d-block info-value">
                {selectedRecord.email_varified ? "Yes" : "No"}
              </span>
            </div>
            <div className="col-3">
              <span className="d-block info-heading">Verified By Admin</span>
              <span className="d-block info-value">
                {selectedRecord.admin_varified ? "Yes" : "No"}
              </span>
            </div>
          </div>
          {selectedRecord.admin_varified ? (
            ""
          ) : (
            <div className="row">
              <div className="col">
                <input
                  type="button"
                  value="Verified"
                  className="btn btn-success"
                  onClick={() => {
                    if (!window.confirm("Are you sure?")) return false;
                    this.fastEdit({
                      field: "admin_varified",
                      value: 1,
                      record: selectedRecord,
                    });
                  }}
                />
              </div>
            </div>
          )}
        </CustomOffCanvas>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Organization);
