import React, { useState } from "react";
import { Menu } from "antd";
import { connect } from "react-redux";
import {
  MailOutlined,
  AppstoreOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import topnavcss from "./topnavdesktop.module.css";
import { PORTAL } from "../../../currrent_info";
import urls from "../../../urls";
// ===============================================================================
const { SubMenu } = Menu;
function TopNavDesktop(props) {
  const { loginStatus, loggedUser } = props;
  const [activeItem, setActiveItem] = useState("mail");
  return (
    <>
      <div
        className="font-weight-bold"
        style={{ fontSize: "20px", float: "left", marginRight: "50px" }}
      >
        {/* <img src={logo} style={{ width: "120px" }} /> */}
        Kelmac Group
      </div>
      <Menu
        mode="horizontal"
        defaultSelectedKeys={["mail"]}
        className={`${topnavcss.topnavMenu}`}
      >
        {/* <Menu.Item key="mail" icon={<MailOutlined />}>
          Navigation One
        </Menu.Item> */}
        <Menu.SubMenu
          style={{ marginLeft: "auto" }}
          key="SubMenu"
          title="Account"
          icon={<SettingOutlined />}
        >
          {loginStatus == true ? (
            <>
              {/* <Menu.Item key="two" icon={<AppstoreOutlined />}>
            Change Password
          </Menu.Item> */}
              <Menu.Item key="three" icon={<AppstoreOutlined />}>
                <Link className="logout" to={urls.LOGOUT.path}>
                  {urls.LOGOUT.text}
                </Link>
              </Menu.Item>
            </>
          ) : (
            ""
          )}
          {/* <Menu.ItemGroup title="Item Group">
            <Menu.Item key="four" icon={<AppstoreOutlined />}>
              Navigation Four
            </Menu.Item>
            <Menu.Item key="five" icon={<AppstoreOutlined />}>
              Navigation Five
            </Menu.Item>
          </Menu.ItemGroup> */}
        </Menu.SubMenu>
      </Menu>
    </>
  );
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
export default connect(mapStateToProps)(TopNavDesktop);
