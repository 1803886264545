import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { Image, message, Row, Col } from "antd";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import CustomSwitch from "../part/Display/CustomSwitch";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { auditing_service_api } from "../../library/auditing_service_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FormImageUploadGroup from "../part/Form/FormImageUploadGroup";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormMultiSelectGroup from "../part/Form/FormMultiSelectGroup";
import SeoBox from "./SeoBox";
import { PORTAL } from "../../currrent_info";
import { get_data } from "../server_data/get_data";
import FormTextEditorGroup from "../part/Form/FormTextEditorGroup";
import MyToolTip from "../part/MyToolTip/MyToolTip";
// ===============================================================================
class Auditing_service extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: auditing_service_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        image: { valMet: vj_val.checkImage },
        auditing_scheme: { valMet: vj_val.checkLength, valLen: 1 },
        auditing_type: { valMet: vj_val.checkLength, valLen: 1 },
        main_head: { valMet: vj_val.checkLength, valLen: 1 },
        order: { valMet: vj_val.checkNumber },
        // ============================================================
        // ============================================================
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      seomodal: false,
      uploadImageList: [],
      uploadFileList: [],
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    main_head: {
      title: "Main Head",
      width: 400,
      className: "main_head",
      sorter: true,
      ellipsis: true,
      order: 1,
    },
    auditing_scheme: {
      title: "Auditing Scheme",
      width: 200,
      className: "auditing_scheme",
      sorter: true,
      ellipsis: true,
      order: 2,
      custom: (text, record) => this.state.auditing_scheme2[text],
    },
    auditing_type: {
      title: "Auditing Type",
      width: 100,
      className: "auditing_type",
      sorter: true,
      ellipsis: true,
      order: 3,
      custom: (text, record) => this.state.auditing_type2[text],
    },
    order: {
      title: "Order",
      width: 100,
      className: "order",
      sorter: true,
      ellipsis: true,
      order: 4,
    },
    active: {
      title: "Status",
      width: 120,
      className: "active",
      sorter: true,
      ellipsis: true,
      order: 5,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Active</span>;
        return <span className="text-danger font-weight-bold">Deactive</span>;
      },
    },
    image: {
      title: "Image",
      width: 120,
      className: "image",
      order: 6,
      custom: (text, record) => {
        if (text && text.length)
          return (
            <Image
              width={50}
              // src={`${auditing_service_api}/image/${text}`}
              src={`${PORTAL.api_url}uploaded_images/${text}`}
              alt="logo"
            />
          );
        return "";
      },
    },
  };
  imageUpload = async (fl) => {
    const { uploadImageList, Chabi } = this.state;
    const dont_run = uploadImageList.some(
      (upl) => upl.uid && upl.uid == fl.uid
    );
    if (dont_run) return false;
    const { originFileObj: file } = fl;
    const url = auditing_service_api + "/image_upload";
    const formData = new FormData();
    formData.append("file", file, file.name); //notice here
    const response = await myAxios({
      method: "post",
      url,
      request: formData,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      const { records } = data;
      this.setRequest({ name: "image", value: records[0].filename });
      const final_obj = { ...fl, ...records[0] };
      uploadImageList.push(final_obj);
      this.setState({ uploadImageList });
      this.setValidation({ name: "image", res: true });
    }
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
    const response = await get_data({
      others: ["auditing_type", "auditing_scheme", "region"],
      primary: "auditing_service",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let { auditing_type, auditing_scheme, region, records } = data;
        records = add_sno(records);
        let auditing_type2 = create_object({
          arr: auditing_type,
          title: "title",
        });
        let auditing_scheme2 = create_object({
          arr: auditing_scheme,
          title: "scheme",
        });
        let region2 = create_object({
          arr: region,
          title: "region_name",
        });
        this.setState({
          auditing_type,
          auditing_type2,
          region,
          region2,
          auditing_scheme,
          auditing_scheme2,
          records,
        });
      }
    } else console.log("Network Error");
    // const auditing_type = await get_auditing_type({ Chabi });
    // let auditing_type2 = {};
    // auditing_type.forEach((l) => {
    //   auditing_type2[l._id] = l.title;
    // });
    // const auditing_scheme = await get_auditing_scheme({ Chabi });
    // let auditing_scheme2 = {};
    // auditing_scheme.forEach((l) => {
    //   auditing_scheme2[l._id] = l.scheme;
    // });
    // const region = await get_region({ Chabi });
    // let region2 = {};
    // region.forEach((l) => {
    //   region2[l._id] = l.region_name;
    // });
    // this.setState({
    //   auditing_type,
    //   auditing_type2,
    //   auditing_scheme,
    //   auditing_scheme2,
    //   region,
    //   region2,
    // });
    // const url = auditing_service_api;
    // const response = await myAxios({ method: "get", url, header: { Chabi } });
    // if (response.status == 200) {
    //   const { data } = response;
    //   if (data.status == 420) {
    //     this.setState({
    //       loggedUser: undefined,
    //       loginStatus: undefined,
    //     });
    //     doUserLogout();
    //   }
    //   if (data.status == 200) {
    //     let { records } = data;
    //     records = add_sno(records);
    //     this.setState({ records });
    //   }
    // } else console.log("Network Error");
  };
  addAuditing_service = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = auditing_service_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    console.log("add course ", response);
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
        const { records: r } = data;
        records.push({ ...r[0] });
        records = add_sno(records);
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          uploadFileList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };
  editAuditing_service = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const {
      _id,
      main_head,
      auditing_scheme,
      image,
      auditing_type,
      order,
      region_info,
    } = request;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = auditing_service_api;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: {
        _id,
        main_head,
        image,
        auditing_scheme,
        auditing_type,
        order,
        region_info,
      },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            rec["main_head"] = main_head;
            rec["image"] = image;
            rec["auditing_scheme"] = auditing_scheme;
            rec["auditing_type"] = auditing_type;
            rec["order"] = order;
            rec["region_info"] = region_info;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };
  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, doUserLogout, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${auditing_service_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  render() {
    console.log("course State ", this.state);
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
      auditing_type,
      auditing_scheme,
      region,
      selectedTab,
      seomodal,
    } = this.state;
    const { region_info } = request;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    columns.push({
      width: 100,
      title: "SEO",
      dataIndex: "SEO",
      render: (text, record) => {
        return (
          <a
            onClick={() => {
              this.setState({ seomodal: record });
            }}
          >
            SEO
          </a>
        );
      },
    });
    return (
      <>
        <PageHeading2
          active="Auditing Service"
          page={["Website Administration", "Auditing", "Auditing Service"]}
        >
          <button
            className="add-btn"
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          >
            Add New
          </button>
        </PageHeading2>
        <MainArea>
          {records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              loadData={this.loadRecord}
              removeData={this.removeRecord}
              activeData={this.activeRecord}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Auditing Service"
          close={this.close_modal}
          className="modal-form-course"
        >
          <FormContainer>
            <div className="row">
              <div className="col">
                <FormImageUploadGroup
                  // src={`${page_api}/image/`}
                  src={`${PORTAL.api_url}uploaded_images/`}
                  id="image"
                  name="image"
                  label="Image"
                  sub_label="(Size: Below 5Mb, Format: jpeg, jpg, png, Resolution: 217x90 pixel)"
                  imageCount="1"
                  data={this.state}
                  required
                  imageUpload={this.imageUpload}
                  changeUploadImageList={this.changeUploadImageList}
                />
              </div>
            </div>
            <Row>
              <Col span={12}>
                <FormInputGroup
                  id="main_head"
                  name="main_head"
                  label="Main Head"
                  placeholder="Main Head"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={12}>
                <FormInputGroup
                  type="number"
                  id="order"
                  name="order"
                  label="Order"
                  placeholder="Order"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <FormSelectGroup
                  allowClear
                  id="auditing_scheme"
                  name="auditing_scheme"
                  label="Auditing Scheme"
                  placeholder="Auditing Scheme"
                  data={this.state}
                  required
                  options={auditing_scheme}
                  option_value="_id"
                  option_text="scheme"
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={12}>
                <FormMultiSelectGroup
                  allowClear
                  id="auditing_type"
                  name="auditing_type"
                  label="Auditing Type"
                  placeholder="Auditing Type"
                  data={this.state}
                  required
                  options={auditing_type}
                  option_value="_id"
                  option_text="title"
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
            </Row>
            {region && region.length ? (
              <Tab.Container defaultActiveKey="default">
                <Row className="mt-4">
                  <Col span={6}>
                    <Nav variant="" className="flex-column">
                      {region.map((r) => {
                        let single_region;
                        if (region_info)
                          single_region = { ...region_info[r._id] };
                        let active = 0;
                        if (single_region) active = single_region["active"];
                        return (
                          <Row
                            className={`region-nav-link ${
                              r._id == selectedTab ? "region-active" : ""
                            }`}
                          >
                            <MyToolTip
                              title={active == 1 ? "" : "Enable before select"}
                              placement="left"
                              color="#c6001d"
                            >
                              <Col span={18}>
                                <Nav.Link
                                  eventKey={r.region_name}
                                  disabled={active == 1 ? false : true}
                                  onClick={() => {
                                    this.setState({ selectedTab: r._id });
                                  }}
                                >
                                  {r.region_name}
                                </Nav.Link>
                              </Col>
                            </MyToolTip>
                            <Col span={6} style={{ lineHeight: "40px" }}>
                              <CustomSwitch
                                key={active}
                                activeData={(active) => {
                                  this.set_region_request({
                                    name: "active",
                                    value: +active,
                                    _id: r._id,
                                  });
                                }}
                                active={active}
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    </Nav>
                  </Col>
                  <Col span={18} className="border pb-2">
                    <Tab.Content>
                      <Tab.Pane eventKey="default">
                        <MyEmpty message="Region Not Selected" />
                      </Tab.Pane>
                      {region.map((r) => {
                        let single_region;
                        if (region_info)
                          single_region = { ...region_info[r._id] };
                        let title = "";
                        let banner_title = "";
                        let banner_sub_title = "";
                        let description = "";
                        let active = false;
                        if (single_region) {
                          title = single_region["title"];
                          banner_title = single_region["banner_title"];
                          banner_sub_title = single_region["banner_sub_title"];
                          description = single_region["description"];
                          active = single_region["active"];
                        }
                        return (
                          <Tab.Pane eventKey={r.region_name}>
                            <Row className="mt-2">
                              <Col
                                span={20}
                                className="text-center font-weight-bold"
                              >
                                Region : {r.region_name}
                              </Col>
                            </Row>
                            <Row>
                              <Col span={12}>
                                <FormInputGroup
                                  id="title"
                                  name="title"
                                  label="Title"
                                  placeholder="Title"
                                  data={this.state}
                                  required
                                  value={title}
                                  sendValue={(obj) => {
                                    this.set_region_request({
                                      ...obj,
                                      _id: r._id,
                                    });
                                  }}
                                  disabled={!active}
                                />
                              </Col>
                              <Col span={12}>
                                <FormInputGroup
                                  id="banner_title"
                                  name="banner_title"
                                  label="Banner Title"
                                  placeholder="Banner Title"
                                  data={this.state}
                                  required
                                  value={banner_title}
                                  sendValue={(obj) => {
                                    this.set_region_request({
                                      ...obj,
                                      _id: r._id,
                                    });
                                  }}
                                  disabled={!active}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                <FormInputGroup
                                  id="banner_sub_title"
                                  name="banner_sub_title"
                                  label="Banner Sub Title"
                                  placeholder="Banner Sub Title"
                                  data={this.state}
                                  required
                                  value={banner_sub_title}
                                  sendValue={(obj) => {
                                    this.set_region_request({
                                      ...obj,
                                      _id: r._id,
                                    });
                                  }}
                                  disabled={!active}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                <FormTextEditorGroup
                                  id="description"
                                  name="description"
                                  label="Description"
                                  placeholder="Description"
                                  data={this.state}
                                  required
                                  value={description}
                                  sendValue={(obj) => {
                                    this.set_region_request({
                                      ...obj,
                                      _id: r._id,
                                    });
                                  }}
                                  disabled={!active}
                                />
                              </Col>
                            </Row>
                          </Tab.Pane>
                        );
                      })}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            ) : (
              ""
            )}
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editAuditing_service();
                else this.addAuditing_service();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
        {seomodal ? (
          <SeoBox
            key={seomodal._id}
            record={seomodal}
            state={this.state}
            setSeoModal={() => {
              this.setState({ seomodal: false });
            }}
            updateSeo={(obj) => {
              const new_records = records.map((rec) => {
                if (rec._id == seomodal._id) rec = { ...rec, ...obj };
                return rec;
              });
              this.setState({ records: new_records });
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Auditing_service);
