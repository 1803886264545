import React from "react";
import { useSelector } from "react-redux";
import { Layout, Menu } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import urls from "../../../urls";
import "./style.css";
function MySider() {
  const location = useLocation();
  const history = useNavigate();
  const loggedUser = useSelector((state) => state.loggedUser);
  const { role } = loggedUser;
  const { SubMenu } = Menu;
  const { Sider } = Layout;
  // const [selectedKey, setSelectedKey] = useState([location.pathname]);
  return (
    <Sider className="side">
      <Menu
        mode="inline"
        // defaultSelectedKeys={selectedKey}
        // defaultOpenKeys={selectedKey}
        selectedKeys={["website", "homepage", location.pathname]}
        className="side-menu"
        onSelect={(obj) => {
          // const { selectedKeys } = obj;
          // setSelectedKey(selectedKeys);
          console.log("on select ", obj);
        }}
      >
        {role == "super" ? (
          <Menu.Item key={urls.USER.path}>
            <Link className="link" to={urls.USER.path}>
              {urls.USER.text}
            </Link>
          </Menu.Item>
        ) : (
          <>
            <Menu.Item
              key={urls.DASHBOARD.path}
              //    icon={<UnorderedListOutlined />}
            >
              <Link className="link" to={urls.DASHBOARD.path}>
                {urls.DASHBOARD.text}
              </Link>
            </Menu.Item>
            <SubMenu
              key="website"
              //   icon={<UserOutlined />}
              title="Website Administration"
            >
              <Menu.Item key={urls.REGION.path}>
                <Link className="link" to={urls.REGION.path}>
                  {urls.REGION.text}
                </Link>
              </Menu.Item>
              <SubMenu key="homepage" title="Home Page">
                <Menu.Item key={urls.BANNER.path}>
                  <Link className="link" to={urls.BANNER.path}>
                    {urls.BANNER.text}
                  </Link>
                </Menu.Item>
                <Menu.Item key={urls.CLIENTS.path}>
                  <Link className="link" to={urls.CLIENTS.path}>
                    {urls.CLIENTS.text}
                  </Link>
                </Menu.Item>
                <Menu.Item key={urls.CONTENT.path}>
                  <Link className="link" to={urls.CONTENT.path}>
                    {urls.CONTENT.text}
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="website-localization" title="Localization">
                <Menu.Item key={urls.LOCATION.path}>
                  <Link className="link" to={urls.LOCATION.path}>
                    {urls.LOCATION.text}
                  </Link>
                </Menu.Item>
                <Menu.Item key={urls.VENUE.path}>
                  <Link className="link" to={urls.VENUE.path}>
                    {urls.VENUE.text}
                  </Link>
                </Menu.Item>
              </SubMenu>
              <Menu.Item key={urls.TESTIMONIAL.path}>
                <Link className="link" to={urls.TESTIMONIAL.path}>
                  {urls.TESTIMONIAL.text}
                </Link>
              </Menu.Item>
              <SubMenu key="website-our_team" title="Our Team">
                <Menu.Item key={urls.TEAM.path}>
                  <Link className="link" to={urls.TEAM.path}>
                    {urls.TEAM.text}
                  </Link>
                </Menu.Item>
                <Menu.Item key={urls.TEAM_GROUP.path}>
                  <Link className="link" to={urls.TEAM_GROUP.path}>
                    {urls.TEAM_GROUP.text}
                  </Link>
                </Menu.Item>
              </SubMenu>
              <Menu.Item key={urls.REVIEW_QUESTION.path}>
                <Link className="link" to={urls.REVIEW_QUESTION.path}>
                  {urls.REVIEW_QUESTION.text}
                </Link>
              </Menu.Item>
              <Menu.Item key="website-news_update">
                <Link className="link" to={urls.NEWSUPDATE.path}>
                  {urls.NEWSUPDATE.text}
                </Link>
              </Menu.Item>
              <Menu.Item key={urls.FAQ.path}>
                <Link className="link" to={urls.FAQ.path}>
                  {urls.FAQ.text}
                </Link>
              </Menu.Item>
              <Menu.Item key={urls.CAREER.path}>
                <Link className="link" to={urls.CAREER.path}>
                  {urls.CAREER.text}
                </Link>
              </Menu.Item>
              <Menu.Item key="website-case_study">Case Study ---</Menu.Item>
              <Menu.Item key={urls.INDUSTRIES.path}>
                <Link className="link" to={urls.INDUSTRIES.path}>
                  {urls.INDUSTRIES.text}
                </Link>
              </Menu.Item>
              <Menu.Item key={urls.CORPORATE_BROCHURE.path}>
                <Link className="link" to={urls.CORPORATE_BROCHURE.path}>
                  {urls.CORPORATE_BROCHURE.text}
                </Link>
              </Menu.Item>
              <SubMenu key="website-insight" title="Insight">
                <Menu.Item key={urls.INSIGHT_CATEGORY.path}>
                  <Link className="link" to={urls.INSIGHT_CATEGORY.path}>
                    {urls.INSIGHT_CATEGORY.text}
                  </Link>
                </Menu.Item>
                <Menu.Item key={urls.INSIGHT.path}>
                  <Link className="link" to={urls.INSIGHT.path}>
                    {urls.INSIGHT.text}
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="website-auditing" title="Auditing">
                <Menu.Item key={urls.AUDITING_SCHEME.path}>
                  <Link className="link" to={urls.AUDITING_SCHEME.path}>
                    {urls.AUDITING_SCHEME.text}
                  </Link>
                </Menu.Item>
                <Menu.Item key="auditing-service_type">
                  <Link
                    className="link"
                    to={urls.AUDITING_SERVICE_TYPE_POINT.path}
                  >
                    {urls.AUDITING_SERVICE_TYPE_POINT.text}
                  </Link>
                </Menu.Item>
                <Menu.Item key={urls.AUDITING_TYPE.path}>
                  <Link className="link" to={urls.AUDITING_TYPE.path}>
                    {urls.AUDITING_TYPE.text}
                  </Link>
                </Menu.Item>
                <Menu.Item key={urls.AUDITING_SERVICE.path}>
                  <Link className="link" to={urls.AUDITING_SERVICE.path}>
                    {urls.AUDITING_SERVICE.text}
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="website-consulting" title="Consulting">
                <Menu.Item key={urls.CONSULTING_SCHEME.path}>
                  <Link className="link" to={urls.CONSULTING_SCHEME.path}>
                    {urls.CONSULTING_SCHEME.text}
                  </Link>
                </Menu.Item>
                <Menu.Item key={urls.CONSULTING_SERVICE_TYPE_POINT.path}>
                  <Link
                    className="link"
                    to={urls.CONSULTING_SERVICE_TYPE_POINT.path}
                  >
                    {urls.CONSULTING_SERVICE_TYPE_POINT.text}
                  </Link>
                </Menu.Item>
                <Menu.Item key={urls.CONSULTING_TYPE.path}>
                  <Link className="link" to={urls.CONSULTING_TYPE.path}>
                    {urls.CONSULTING_TYPE.text}
                  </Link>
                </Menu.Item>
                <Menu.Item key={urls.CONSULTING_SERVICE.path}>
                  <Link className="link" to={urls.CONSULTING_SERVICE.path}>
                    {urls.CONSULTING_SERVICE.text}
                  </Link>
                </Menu.Item>
              </SubMenu>
              <SubMenu key="website-learning" title="Learning">
                <Menu.Item key={urls.LEARNING_SCHEME.path}>
                  <Link className="link" to={urls.LEARNING_SCHEME.path}>
                    {urls.LEARNING_SCHEME.text}
                  </Link>
                </Menu.Item>
                <Menu.Item key={urls.ACCREDITATION.path}>
                  <Link className="link" to={urls.ACCREDITATION.path}>
                    {urls.ACCREDITATION.text}
                  </Link>
                </Menu.Item>
                <Menu.Item key={urls.COURSE_CATEGORY.path}>
                  <Link className="link" to={urls.COURSE_CATEGORY.path}>
                    {urls.COURSE_CATEGORY.text}
                  </Link>
                </Menu.Item>
                <Menu.Item key={urls.LANGUAGE.path}>
                  <Link className="link" to={urls.LANGUAGE.path}>
                    {urls.LANGUAGE.text}
                  </Link>
                </Menu.Item>
                <Menu.Item key={urls.DELIVERY_METHOD.path}>
                  <Link className="link" to={urls.DELIVERY_METHOD.path}>
                    {urls.DELIVERY_METHOD.text}
                  </Link>
                </Menu.Item>
                <Menu.Item key={urls.COURSE_TYPE.path}>
                  <Link className="link" to={urls.COURSE_TYPE.path}>
                    {urls.COURSE_TYPE.text}
                  </Link>
                </Menu.Item>
                <Menu.Item key={urls.COUPON_LEARNER.path}>
                  <Link className="link" to={urls.COUPON_LEARNER.path}>
                    {urls.COUPON_LEARNER.text}
                  </Link>
                </Menu.Item>
                <Menu.Item key={urls.COUPON_ORGANIZATION.path}>
                  <Link className="link" to={urls.COUPON_ORGANIZATION.path}>
                    {urls.COUPON_ORGANIZATION.text}
                  </Link>
                </Menu.Item>
              </SubMenu>
              <Menu.Item key={urls.SEO.path}>
                <Link className="link" to={urls.SEO.path}>
                  {urls.SEO.text}
                </Link>
              </Menu.Item>
              <Menu.Item key={urls.USER.path}>
                <Link className="link" to={urls.USER.path}>
                  {urls.USER.text}
                </Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key={urls.COURSE.path}>
              <Link className="link" to={urls.COURSE.path}>
                {urls.COURSE.text}
              </Link>
            </Menu.Item>
            <Menu.Item key={urls.TRAINING.path}>
              <Link className="link" to={urls.TRAINING.path}>
                {urls.TRAINING.text}
              </Link>
            </Menu.Item>
            <Menu.Item key={urls.TRAINING365.path}>
              <Link className="link" to={urls.TRAINING365.path}>
                {urls.TRAINING365.text}
              </Link>
            </Menu.Item>
            <Menu.Item key={urls.TRAINING_BUNDAL.path}>
              <Link className="link" to={urls.TRAINING_BUNDAL.path}>
                {urls.TRAINING_BUNDAL.text}
              </Link>
            </Menu.Item>
            <Menu.Item key={urls.LEARNERS.path}>
              <Link className="link" to={urls.LEARNERS.path}>
                {urls.LEARNERS.text}
              </Link>
            </Menu.Item>
            <Menu.Item key={urls.ORGANIZATION.path}>
              <Link className="link" to={urls.ORGANIZATION.path}>
                {urls.ORGANIZATION.text}
              </Link>
            </Menu.Item>
            <SubMenu key="consultants" title="Consultants">
              <Menu.Item key={urls.CONSULTANTS.path}>
                <Link className="link" to={urls.CONSULTANTS.path}>
                  {urls.CONSULTANTS.text}
                </Link>
              </Menu.Item>
              <Menu.Item key="consultants-requested">Requested ---</Menu.Item>
              <Menu.Item key={urls.NDA.path}>
                <Link className="link" to={urls.NDA.path}>
                  {urls.NDA.text}
                </Link>
              </Menu.Item>
              <Menu.Item key={urls.PRODUCT_FAM_POINTS.path}>
                <Link className="link" to={urls.PRODUCT_FAM_POINTS.path}>
                  {urls.PRODUCT_FAM_POINTS.text}
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="template" title="Template">
              <Menu.Item key={urls.COMPENTENCY_UNITS.path}>
                <Link className="link" to={urls.COMPENTENCY_UNITS.path}>
                  {urls.COMPENTENCY_UNITS.text}
                </Link>
              </Menu.Item>
              <Menu.Item key={urls.LCA.path}>
                <Link className="link" to={urls.LCA.path}>
                  {urls.LCA.text}
                </Link>
              </Menu.Item>
              <Menu.Item key={urls.EXAM_SECTION.path}>
                <Link className="link" to={urls.EXAM_SECTION.path}>
                  {urls.EXAM_SECTION.text}
                </Link>
              </Menu.Item>
              <Menu.Item key={urls.EXAM.path}>
                <Link className="link" to={urls.EXAM.path}>
                  {urls.EXAM.text}
                </Link>
              </Menu.Item>
              <Menu.Item key="template-certificate">Certificate ---</Menu.Item>
              <Menu.Item key={urls.EVALUATION_QUESTION.path}>
                <Link className="link" to={urls.EVALUATION_QUESTION.path}>
                  {urls.EVALUATION_QUESTION.text}
                </Link>
              </Menu.Item>
              <Menu.Item key={urls.EVALUATION_SECTION.path}>
                <Link className="link" to={urls.EVALUATION_SECTION.path}>
                  {urls.EVALUATION_SECTION.text}
                </Link>
              </Menu.Item>
              <Menu.Item key={urls.EVALUATION.path}>
                <Link className="link" to={urls.EVALUATION.path}>
                  {urls.EVALUATION.text}
                </Link>
              </Menu.Item>
            </SubMenu>
            <SubMenu key="learning_pathways" title="Learning Pathways">
              <Menu.Item key={urls.MAPPING.path}>
                <Link className="link" to={urls.MAPPING.path}>
                  {urls.MAPPING.text}
                </Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key={urls.NEWSLETTER.path}>
              <Link className="link" to={urls.NEWSLETTER.path}>
                {urls.NEWSLETTER.text}
              </Link>
            </Menu.Item>
            <Menu.Item key={urls.MESSAGE.path}>
              <Link className="link" to={urls.MESSAGE.path}>
                {urls.MESSAGE.text}
              </Link>
            </Menu.Item>
            <SubMenu key="orders" title="Orders ">
              <Menu.Item key="orders-payments">
                <Link className="link" to={urls.PAYMENT_ORDERS.path}>
                  {urls.PAYMENT_ORDERS.text}
                </Link>
              </Menu.Item>
              <Menu.Item key="orders-bulk_orders">
                <Link className="link" to={urls.BULK_ORDER.path}>
                  {urls.BULK_ORDER.text}
                </Link>
              </Menu.Item>
              <Menu.Item key="orders-purchase_orders">
                <Link className="link" to={urls.PURCHASE_ORDER.path}>
                  {urls.PURCHASE_ORDER.text}
                </Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key="reports ---">Reports ---</Menu.Item>
            <SubMenu key="enquiry" title="Enquiry">
              <Menu.Item key="enquiry-contact_us">
                <Link className="link" to={urls.CONTACT_ENQUIRY.path}>
                  {urls.CONTACT_ENQUIRY.text}
                </Link>
              </Menu.Item>
              <Menu.Item key="enquiry-course">
                <Link className="link" to={urls.COURSE_ENQUIRY.path}>
                  {urls.COURSE_ENQUIRY.text}
                </Link>
              </Menu.Item>
              <Menu.Item key="enquiry-training">
                <Link className="link" to={urls.TRAINING_ENQUIRY.path}>
                  {urls.TRAINING_ENQUIRY.text}
                </Link>
              </Menu.Item>
            </SubMenu>
            <Menu.Item key={urls.TICKET.path}>
              <Link className="link" to={urls.TICKET.path}>
                {urls.TICKET.text}
              </Link>
            </Menu.Item>
            <SubMenu key="settings" title="Settings">
              <Menu.Item key={urls.CONTACT_DETAILS.path}>
                <Link className="link" to={urls.CONTACT_DETAILS.path}>
                  {urls.CONTACT_DETAILS.text}
                </Link>
              </Menu.Item>
              <Menu.Item key={urls.SOCIAL_MEDIA.path}>
                <Link className="link" to={urls.SOCIAL_MEDIA.path}>
                  {urls.SOCIAL_MEDIA.text}
                </Link>
              </Menu.Item>
              <Menu.Item key={urls.SETTINGS.path}>
                <Link className="link" to={urls.SETTINGS.path}>
                  {urls.SETTINGS.text}
                </Link>
              </Menu.Item>
            </SubMenu>
          </>
        )}
      </Menu>
    </Sider>
  );
}
export default MySider;
