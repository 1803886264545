import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { Image, message, Row, Col } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { consultant_api } from "../../library/consultant_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import { get_region } from "../server_data/list_data";
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FormImageUploadGroup from "../part/Form/FormImageUploadGroup";
import FilterInputGroup from "../part/Filter/FilterInputGroup";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";
import {
  country_array,
  countryListAlpha2,
} from "../local_data/variousCountryListFormats";
import { get_data } from "../server_data/get_data";
// ===============================================================================
class Consultants extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: consultant_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        location_name: { valMet: vj_val.checkLength, valLen: 1 },
        country: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      uploadImageList: [],
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    first_name: {
      title: "First Name",
      // width: 300,
      className: "first_name",
      sorter: true,
      ellipsis: true,
      order: 1,
    },
    last_name: {
      title: "last_name",
      // width: 300,
      className: "last_name",
      sorter: true,
      ellipsis: true,
      order: 2,
    },
    mobile: {
      title: "mobile",
      // width: 300,
      className: "mobile",
      sorter: true,
      ellipsis: true,
      order: 3,
    },
    email: {
      title: "email",
      // width: 300,
      className: "email",
      sorter: true,
      ellipsis: true,
      order: 4,
    },
    region: {
      title: "region",
      // width: 300,
      className: "region",
      sorter: true,
      ellipsis: true,
      order: 5,
      custom: (text, record) => this.state.region2[text],
    },
    country: {
      title: "country",
      // width: 300,
      className: "country",
      sorter: true,
      ellipsis: true,
      order: 6,
      custom: (text, record) => countryListAlpha2[text],
    },
    city: {
      title: "city",
      // width: 300,
      className: "city",
      sorter: true,
      ellipsis: true,
      order: 7,
    },
    email_varified: {
      title: "Email Verified",
      // width: 300,
      className: "email_varified",
      sorter: true,
      ellipsis: true,
      order: 12,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Yes</span>;
        return <span className="text-danger font-weight-bold">No</span>;
      },
    },
    admin_varified: {
      title: " Verified By Admin",
      // width: 300,
      className: "admin_varified",
      sorter: true,
      ellipsis: true,
      order: 13,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Yes</span>;
        return <span className="text-danger font-weight-bold">No</span>;
      },
    },
    active: {
      title: "Status",
      width: 120,
      className: "active",
      sorter: true,
      ellipsis: true,
      order: 10,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Active</span>;
        return <span className="text-danger font-weight-bold">Deactive</span>;
      },
    },
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
    const response = await get_data({
      others: ["region"],
      primary: "consultant",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
        let { region, records } = data;
        records = add_sno(records);
        let region2 = create_object({
          arr: region,
          title: "region_name",
        });
        this.setState({
          region,
          region2,
          records,
        });
      }
    } else console.log("Network Error");
    // const region = await get_region({ Chabi });
    // let region2 = {};
    // region.forEach((l) => {
    //   region2[l._id] = l.region_name;
    // });
    // this.setState({
    //   region,
    //   region2,
    // });
    // const url = consultant_api + "/get";
    // const response = await myAxios({ method: "post", url, header: { Chabi } });
    // if (response.status == 200) {
    //   const { data } = response;
    //   if (data.status == 420) {
    //     this.setState({
    //       loggedUser: undefined,
    //       loginStatus: undefined,
    //     });
    //     doUserLogout();
    //   }
    //   if (data.status == 200) {
    //     let { records } = data;
    //     records = add_sno(records);
    //     this.setState({ records });
    //   }
    // } else console.log("Network Error");
  };
  addLocation = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = consultant_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    console.log("add location ", response);
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
        message.success("Record Saved");
        const { records: r } = data;
        records.push({ ...r[0] });
        records = add_sno(records);
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };
  editLocation = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const { location_name, _id, country } = request;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = consultant_api;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, location_name, country },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout(); 
else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            rec["location_name"] = location_name;
            rec["country"] = country;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };
  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, doUserLogout, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${consultant_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  render() {
    console.log("location State ", this.state);
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    return (
      <>
        <PageHeading2
          active="Consultants"
          page={["Consultants", "Consultants"]}
        >
          {/* <button
            className="add-btn"
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          >
            Add New
          </button> */}
        </PageHeading2>
        <MainArea>
          {/* <Row style={{ marginTop: "20px" }}>
            <Col span={8}>
              <FilterSelectGroup
                id="active"
                name="active"
                label="Status"
                placeholder="Status"
                options={[
                  { text: "Active", value: "1" },
                  { text: "Deactive", value: "0" },
                ]}
                option_value="value"
                option_text="text"
                sendValue={this.set_filter}
              />
            </Col>
          </Row>
          <Row>
            <Col span={2} className="m-2">
              <button className="filter-btn" onClick={this.run_filter}>
                Filter
              </button>
            </Col>
          </Row> */}
          {records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              loadData={this.loadRecord}
              removeData={this.removeRecord}
              activeData={this.activeRecord}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        {/* <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Consultants"
          close={this.close_modal}
          className="drawer-form-location"
        >
          <FormContainer>
            <FormInputGroup
              id="location_name"
              name="location_name"
              label="Consultants Name"
              placeholder="e.g. Kelmac Group"
              data={this.state}required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            <FormSelectGroup
              id="country"
              name="country"
              label="Country"
              placeholder="Country"
              data={this.state}required
              options={country_array}
              option_value="country_code"
              option_text="country_name"
              // valMet={checkUuid}
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editLocation();
                else this.addLocation();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal> */}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Consultants);
