import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { Image, message, Row, Col } from "antd";
import { Tabs } from "antd";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";
import CustomSwitch from "../part/Display/CustomSwitch";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import CustomOffCanvas from "../part/CustomOffCanvas";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { course_api } from "../../library/course_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FormImageUploadGroup from "../part/Form/FormImageUploadGroup";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";
import FormTextEditorGroup from "../part/Form/FormTextEditorGroup";
import FormFileUploadGroup from "../part/Form/FormFileUploadGroup";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormMultiSelectGroup from "../part/Form/FormMultiSelectGroup";
import SeoBox from "./SeoBox";
import { PORTAL } from "../../currrent_info";
import { get_data } from "../server_data/get_data";
import MyLoading from "../part/MyLoading";
import AddNew from "../part/AddNew";
import FormCheckboxSingleGroup from "../part/Form/FormCheckboxSingleGroup";
import MyToolTip from "../part/MyToolTip/MyToolTip";
import { object } from "joi";
const { TabPane } = Tabs;
// ===============================================================================
class Course extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: course_api,
      filter: {},
      records: [],
      request: { region_info: {} },
      validation: {},
      validation_info: {
        image: { valMet: vj_val.checkImage },
        file: { valMet: vj_val.checkImage },
        scheme: { valMet: vj_val.checkLength, valLen: 1 },
        category: { valMet: vj_val.checkLength, valLen: 1 },
        course_type: { valMet: vj_val.checkLength, valLen: 1 },
        languages: { valMet: vj_val.checkLength, valLen: 1 },
        accreditation: { valMet: vj_val.checkLength, valLen: 1 },
        main_head: { valMet: vj_val.checkLength, valLen: 1 },
        // ============================================================
        // ============================================================
      },
      region1_validation_info: {
        fees: { valMet: vj_val.checkNumber },
        duration: { valMet: vj_val.checkNumber },
        delivery_method: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      seomodal: false,
      uploadImageList: [],
      uploadFileList: [],
      activeKey: "1",
      regionEnabled: true,
      nextTabMsg: "",
      coffcanvas: false,
      selectedRecord: {},
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    main_head: {
      title: "Main Head",
      width: 400,
      className: "main_head",
      sorter: true,
      ellipsis: true,
      order: 1,
    },
    scheme: {
      title: "Scheme",
      width: 200,
      className: "scheme",
      sorter: true,
      ellipsis: true,
      order: 2,
      custom: (text, record) => this.state.learning_scheme2[text],
    },
    category: {
      title: "category",
      width: 100,
      className: "category",
      sorter: true,
      ellipsis: true,
      order: 3,
      custom: (text, record) => this.state.course_category2[text],
    },
    active: {
      title: "Status",
      width: 120,
      className: "active",
      sorter: true,
      ellipsis: true,
      order: 4,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Active</span>;
        return <span className="text-danger font-weight-bold">Deactive</span>;
      },
    },
    image: {
      title: "Image",
      width: 120,
      className: "image",
      order: 5,
      custom: (text, record) => {
        if (text && text.length)
          return (
            <Image
              width={50}
              // src={`${course_api}/image/${text}`}
              src={`${PORTAL.api_url}uploaded_images/${text}`}
              alt="logo"
            />
          );
        return "";
      },
    },
  };
  imageUpload = async (fl) => {
    const { uploadImageList, Chabi } = this.state;
    const dont_run = uploadImageList.some(
      (upl) => upl.uid && upl.uid == fl.uid
    );
    if (dont_run) return false;
    const { originFileObj: file } = fl;
    const url = course_api + "/image_upload";
    const formData = new FormData();
    formData.append("file", file, file.name); //notice here
    const response = await myAxios({
      method: "post",
      url,
      request: formData,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      const { records } = data;
      this.setRequest({ name: "image", value: records[0].filename });
      const final_obj = { ...fl, ...records[0] };
      uploadImageList.push(final_obj);
      this.setState({ uploadImageList });
      this.setValidation({ name: "image", res: true });
    }
  };
  fileUpload = async (fl) => {
    const { uploadFileList, Chabi } = this.state;
    const dont_run = uploadFileList.some((upl) => upl.uid && upl.uid == fl.uid);
    if (dont_run) return false;
    const { originFileObj: file } = fl;
    const url = course_api + "/file_upload";
    const formData = new FormData();
    formData.append("file", file, file.name); //notice here
    const response = await myAxios({
      method: "post",
      url,
      request: formData,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      const { records } = data;
      this.setRequest({ name: "file", value: records[0].filename });
      const final_obj = { ...fl, ...records[0] };
      uploadFileList.push(final_obj);
      this.setState({ uploadFileList });
      this.setValidation({ name: "file", res: true });
    }
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
    const response = await get_data({
      others: [
        "learning_scheme",
        "course_category",
        "course_type",
        "language",
        "accreditation",
        "delivery_method",
        "region",
      ],
      primary: "course",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          accreditation,
          records,
          learning_scheme,
          course_category,
          course_type,
          language,
          delivery_method,
          region,
        } = data;
        records = add_sno(records);
        let learning_scheme2 = create_object({
          arr: learning_scheme,
          title: "main_head",
        });
        let course_category2 = create_object({
          arr: course_category,
          title: "main_head",
        });
        let course_type2 = create_object({
          arr: course_type,
          title: "title",
        });
        let language2 = create_object({
          arr: language,
          title: "language",
        });
        let accreditation2 = create_object({
          arr: accreditation,
          title: "title",
        });
        let delivery_method2 = create_object({
          arr: delivery_method,
          title: "title",
        });
        let region2 = create_object({ arr: region, title: "region_name" });
        this.setState({
          learning_scheme,
          learning_scheme2,
          course_category,
          course_category2,
          course_type,
          course_type2,
          language,
          language2,
          accreditation,
          accreditation2,
          delivery_method,
          delivery_method2,
          region,
          region2,
          records,
        });
      }
    } else console.log("Network Error");
    this.setState({ CDM: true });
  };
  addCourse = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) {
      this.setState({
        nextTabMsg: "Please fill Detail inforamtion on next tab",
      });
      return false;
    }
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = course_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    console.log("add course ", response);
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
        const { records: r } = data;
        records.push({ ...r[0] });
        records = add_sno(records);
        this.setState({
          records,
          request: { region_info: {} },
          validation: {},
          uploadImageList: [],
          uploadFileList: [],
          activeKey: "1",
          selectedTab: undefined,
          cmodal: false,
          nextTabMsg: "",
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };
  editCourse = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) {
      this.setState({
        nextTabMsg: "Please fill Detail inforamtion on next tab",
      });
      return false;
    }
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const {
      _id,
      main_head,
      scheme,
      image,
      file,
      category,
      languages,
      course_type,
      accreditation,
      accreditation_number,
      region_info,
      releated_course,
    } = request;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = course_api;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: {
        _id,
        main_head,
        image,
        file,
        scheme,
        category,
        languages,
        course_type,
        accreditation,
        accreditation_number,
        region_info,
        releated_course,
      },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: { region_info: {} },
          cmodal: false,
          nextTabMsg: "",
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            rec["main_head"] = main_head;
            rec["image"] = image;
            rec["file"] = file;
            rec["scheme"] = scheme;
            rec["category"] = category;
            rec["languages"] = languages;
            rec["course_type"] = course_type;
            rec["accreditation"] = accreditation;
            rec["accreditation_number"] = accreditation_number;
            rec["region_info"] = region_info;
            rec["releated_course"] = releated_course;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: { region_info: {} },
          cmodal: false,
          activeKey: "1",
          selectedTab: undefined,
          nextTabMsg: "",
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };
  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, doUserLogout, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${course_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  nextManage = ({ activeKey }) => {
    const { region } = this.state;
    if (activeKey == "2") {
      const fields = [
        "image",
        "file",
        "scheme",
        "category",
        "course_type",
        "languages",
        "accreditation",
      ];
      const { val_result, reason } = this.checkNextValidation({
        fields,
      });
      // this.checkRegionValidation();
      if (val_result) this.setState({ activeKey: "2" });
      else this.setState({ validation: reason });
    } else this.setState({ activeKey: "1" });
  };
  save_mailer = async () => {
    const { Chabi, selectedRecord } = this.state;
    let { records } = this.state;
    const { _id, mailer } = selectedRecord;
    const url = `${course_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, mailer },
    });

    console.log("mailer response", response);
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r["mailer"] = mailer;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  render() {
    console.log("course State ", this.state);
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
      learning_scheme,
      course_category,
      course_type,
      language,
      accreditation,
      delivery_method,
      region,
      selectedTab,
      activeKey,
      seomodal,
      validation_info,
      region1_validation_info,
      nextTabMsg,
      CDM,
      selectedRecord,
      coffcanvas,
    } = this.state;
    console.log("selectedRecord ", selectedRecord);
    const { region_info } = request;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    columns.push({
      width: 100,
      title: "SEO",
      dataIndex: "SEO",
      render: (text, record) => {
        return (
          <a
            onClick={() => {
              this.setState({ seomodal: record });
            }}
          >
            SEO
          </a>
        );
      },
    });
    columns.push({
      title: "Details",
      className: "details",
      // width: 70,
      dataIndex: "details",
      render: (text, selectedRecord) => {
        return (
          <span
            onClick={() => this.setState({ coffcanvas: true, selectedRecord })}
            className="btn btn-warning btn-sm"
          >
            Details
          </span>
        );
      },
    });
    return (
      <>
        <PageHeading2 active="Course" page={["Course"]}>
          <AddNew
            disabled={!CDM}
            onClick={() => {
              this.setState({
                cmodal: !cmodal,
                edit: false,
                request: { region_info: {} },
              });
            }}
          />
        </PageHeading2>
        <MainArea>
          {records && records.length ? (
            <>
              <Row style={{ marginTop: "20px" }}>
                <Col span={4}>
                  <FilterSelectGroup
                    allowClear
                    id="active"
                    name="active"
                    label="Status"
                    placeholder="Status"
                    options={[
                      { text: "Active", value: "1" },
                      { text: "Deactive", value: "0" },
                    ]}
                    option_value="value"
                    option_text="text"
                    sendValue={this.set_filter}
                  />
                </Col>
                <Col span={4}>
                  <FilterSelectGroup
                    allowClear
                    id="scheme"
                    name="scheme"
                    label="Scheme"
                    placeholder="Scheme"
                    options={learning_scheme}
                    option_value="_id"
                    option_text="main_head"
                    sendValue={this.set_filter}
                  />
                </Col>
                <Col span={4}>
                  <FilterSelectGroup
                    allowClear
                    id="category"
                    name="category"
                    label="Category"
                    placeholder="Category"
                    options={course_category}
                    option_value="_id"
                    option_text="main_head"
                    sendValue={this.set_filter}
                  />
                </Col>
                <Col span={4}>
                  <FilterSelectGroup
                    allowClear
                    id="category"
                    name="category"
                    label="Category"
                    placeholder="Category"
                    options={course_category}
                    option_value="_id"
                    option_text="main_head"
                    sendValue={this.set_filter}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={2} className="m-2">
                  <button className="filter-btn" onClick={this.run_filter}>
                    Filter
                  </button>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
          {!CDM ? (
            <MyLoading />
          ) : records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              loadData={this.loadRecord}
              removeData={this.removeRecord}
              activeData={this.activeRecord}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Course"
          close={this.close_modal}
          className="modal-form-course"
        >
          <FormContainer>
            <Tabs
              key={request._id}
              defaultActiveKey={"1"}
              activeKey={activeKey}
              onChange={(activeKey) => {
                this.nextManage({ activeKey });
              }}
            >
              <TabPane tab="Info" key="1">
                <div className="row">
                  <div className="col-8">
                    <FormImageUploadGroup
                      // src={`${page_api}/image/`}
                      src={`${PORTAL.api_url}uploaded_images/`}
                      id="image"
                      name="image"
                      label="Course Logo"
                      sub_label="(Size: Below 5Mb, Format: jpeg, jpg, png, Resolution: 217x90 pixel)"
                      imageCount="1"
                      data={this.state}
                      required
                      imageUpload={this.imageUpload}
                      changeUploadImageList={this.changeUploadImageList}
                    />
                  </div>
                  <div className="col-4">
                    <FormFileUploadGroup
                      src={`${page_api}/file/`}
                      accept=".pdf"
                      id="file"
                      name="file"
                      label="Pdf"
                      sub_label="(Size below 5Mb, Accept pdf)"
                      FileCount="1"
                      data={this.state}
                      required
                      fileUpload={this.fileUpload}
                      changeUploadFileList={this.changeUploadFileList}
                    />
                  </div>
                </div>
                <Row>
                  <Col span={8}>
                    <FormSelectGroup
                      allowClear
                      id="scheme"
                      name="scheme"
                      label="Scheme"
                      placeholder="Scheme"
                      data={this.state}
                      required
                      options={learning_scheme}
                      option_value="_id"
                      option_text="main_head"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={8}>
                    <FormSelectGroup
                      allowClear
                      id="category"
                      name="category"
                      label="Category"
                      placeholder="Category"
                      data={this.state}
                      required
                      options={
                        request.scheme
                          ? course_category.filter(
                              (cc) => cc.learning_scheme == request.scheme
                            )
                          : []
                      }
                      option_value="_id"
                      option_text="main_head"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={8}>
                    <FormSelectGroup
                      allowClear
                      id="course_type"
                      name="course_type"
                      label="Course Type"
                      placeholder="Course Type"
                      data={this.state}
                      required
                      options={course_type}
                      option_value="_id"
                      option_text="title"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  {/* <Col span={8}>
                    <FormInputGroup
                      id="reference_code"
                      name="reference_code"
                      label="Reference Code"
                      placeholder="Reference Code"
                      data={this.state}required
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col> */}
                </Row>
                <Row>
                  <Col span={8}>
                    <FormMultiSelectGroup
                      id="languages"
                      name="languages"
                      label="Languages"
                      placeholder="Languages"
                      data={this.state}
                      required
                      options={language}
                      option_value="_id"
                      option_text="language"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={8}>
                    <FormMultiSelectGroup
                      id="accreditation"
                      name="accreditation"
                      label="Accreditation"
                      placeholder="Accreditation"
                      data={this.state}
                      required
                      options={accreditation}
                      option_value="_id"
                      option_text="title"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                  <Col span={8}>
                    <FormInputGroup
                      type="number"
                      id="accreditation_number"
                      name="accreditation_number"
                      label="Accreditation Number"
                      sub_label="(Optional)"
                      placeholder="Accreditation Number"
                      data={this.state}
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row>
                {region && region.length ? (
                  <Tab.Container key={activeKey} defaultActiveKey="default">
                    <Row className="mt-4" style={{ minHeight: "300px" }}>
                      <Col span={6}>
                        <Nav variant="" className="flex-column">
                          {region.map((r) => {
                            let single_region;
                            if (region_info)
                              single_region = { ...region_info[r._id] };
                            let active = 0;
                            if (single_region) active = single_region["active"];
                            return (
                              <Row
                                className={`region-nav-link ${
                                  r._id == selectedTab ? "region-active" : ""
                                }`}
                              >
                                <MyToolTip
                                  title={
                                    active == 1 ? "" : "Enable before select"
                                  }
                                  placement="left"
                                  color="#c6001d"
                                >
                                  <Col span={18}>
                                    <Nav.Link
                                      eventKey={r.region_name}
                                      disabled={active == 1 ? false : true}
                                      onClick={() => {
                                        this.setState({ selectedTab: r._id });
                                      }}
                                    >
                                      {r.region_name}
                                    </Nav.Link>
                                  </Col>
                                </MyToolTip>
                                <Col span={6} style={{ lineHeight: "40px" }}>
                                  <CustomSwitch
                                    key={active}
                                    activeData={(active) => {
                                      this.set_region_request({
                                        name: "active",
                                        value: +active,
                                        _id: r._id,
                                      });
                                    }}
                                    active={active}
                                  />
                                </Col>
                              </Row>
                            );
                          })}
                        </Nav>
                      </Col>
                      <Col span={18} className="border pb-2">
                        <Tab.Content>
                          <Tab.Pane eventKey="default">
                            <MyEmpty message="Region Not Selected" />
                          </Tab.Pane>
                          {region.map((r) => {
                            let single_region;
                            if (region_info)
                              single_region = { ...region_info[r._id] };
                            let fees = "";
                            let duration = "";
                            let dl = "";
                            let active = false;
                            let is_featured = "";
                            let releated_course = "";
                            if (single_region) {
                              fees = single_region["fees"];
                              duration = single_region["duration"];
                              dl = single_region["delivery_method"];
                              active = single_region["active"];
                              releated_course =
                                single_region["releated_course"];
                              is_featured = single_region["is_featured"];
                            }
                            return (
                              <Tab.Pane eventKey={r.region_name}>
                                <Row className="mt-2">
                                  <Col
                                    span={20}
                                    className="text-center font-weight-bold"
                                  >
                                    Region : {r.region_name}
                                  </Col>
                                  <Col span={4}>
                                    <button
                                      className="add-btn"
                                      onClick={() => {
                                        const region_info2 = {};
                                        Object.keys(region_info).map((ri) => {
                                          if (region_info[ri].active) {
                                            region_info2[ri] = {
                                              ...region_info[ri],
                                              fees,
                                              duration,
                                              active,
                                              delivery_method: dl,
                                              is_featured,
                                            };
                                          }
                                        });
                                        this.setState({
                                          request: {
                                            ...request,
                                            region_info: region_info2,
                                          },
                                        });
                                      }}
                                      // disabled={!active}
                                    >
                                      Copy To All
                                    </button>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={4}>
                                    <FormInputGroup
                                      id="fees"
                                      name="fees"
                                      label="Fees"
                                      placeholder="Fees"
                                      data={this.state}
                                      required
                                      value={fees}
                                      sendValue={(obj) => {
                                        this.set_region_request({
                                          ...obj,
                                          _id: r._id,
                                        });
                                      }}
                                      disabled={!active}
                                    />
                                  </Col>
                                  <Col span={6}>
                                    <FormInputGroup
                                      type="number"
                                      id="duration"
                                      name="duration"
                                      label="Duration (In Days)"
                                      placeholder="Duration"
                                      data={this.state}
                                      required
                                      value={duration}
                                      sendValue={(obj) => {
                                        this.set_region_request({
                                          ...obj,
                                          _id: r._id,
                                        });
                                      }}
                                      disabled={!active}
                                    />
                                  </Col>
                                  <Col span={14}>
                                    <FormMultiSelectGroup
                                      id="delivery_method"
                                      name="delivery_method"
                                      label="Delivery Method"
                                      placeholder="Delivery Method"
                                      value={dl}
                                      data={this.state}
                                      required
                                      options={delivery_method}
                                      option_value="_id"
                                      option_text="title"
                                      sendValue={(obj) => {
                                        this.set_region_request({
                                          ...obj,
                                          _id: r._id,
                                        });
                                      }}
                                      disabled={!active}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col span={24}>
                                    <FormMultiSelectGroup
                                      id="releated_course"
                                      name="releated_course"
                                      label="Releated Course"
                                      placeholder="Releated Course"
                                      value={releated_course}
                                      data={this.state}
                                      sub_label="(Optinal)"
                                      options={records}
                                      option_value="_id"
                                      option_text="main_head"
                                      sendValue={(obj) => {
                                        this.set_region_request({
                                          ...obj,
                                          _id: r._id,
                                        });
                                      }}
                                      disabled={!active}
                                    />
                                  </Col>
                                  <Col span={12}></Col>
                                </Row>
                                <Row>
                                  <Col span={24}>
                                    <FormCheckboxSingleGroup
                                      id="is_featured"
                                      name="is_featured"
                                      label="Is Featured"
                                      sub_label="(Optional)"
                                      defaultValue={is_featured}
                                      options={{ text: "Yes", value: "yes" }}
                                      option_value="value"
                                      option_text="text"
                                      sendValue={(obj) => {
                                        console.log("obj 1", obj);
                                        this.set_region_request({
                                          ...obj,
                                          _id: r._id,
                                        });
                                      }}
                                      disabled={!active}
                                    />
                                  </Col>
                                  <Col span={12}></Col>
                                </Row>
                              </Tab.Pane>
                            );
                          })}
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                ) : (
                  ""
                )}
                <div>
                  <button
                    className="add-btn"
                    onClick={() => {
                      this.nextManage({ activeKey: "2" });
                    }}
                  >
                    Next
                  </button>
                  <span className="d-inline-block ml-2 text-danger">
                    {nextTabMsg}
                  </span>
                </div>
              </TabPane>
              <TabPane tab="Details" key="2">
                <FormInputGroup
                  id="main_head"
                  name="main_head"
                  label="Main Head"
                  placeholder="Main Head"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
                {region && region.length ? (
                  <Tab.Container key={activeKey} defaultActiveKey="default">
                    <Row className="mt-4">
                      <Col span={4}>
                        <Nav variant="" className="flex-column">
                          {region.map((r) => {
                            let single_region;
                            if (region_info)
                              single_region = { ...region_info[r._id] };
                            let active = 0;
                            if (single_region) active = single_region["active"];
                            return (
                              <Row
                                className={`region-nav-link ${
                                  r._id == selectedTab ? "region-active" : ""
                                }`}
                              >
                                <Col span={18}>
                                  <Nav.Link
                                    eventKey={r.region_name}
                                    disabled={active == 1 ? false : true}
                                    onClick={() => {
                                      this.setState({ selectedTab: r._id });
                                    }}
                                  >
                                    {r.region_name}
                                  </Nav.Link>
                                </Col>
                                <Col span={6}>
                                  <CustomSwitch
                                    key={active}
                                    activeData={(active) => {
                                      this.set_region_request({
                                        name: "active",
                                        value: +active,
                                        _id: r._id,
                                      });
                                    }}
                                    active={active}
                                  />
                                </Col>
                              </Row>
                            );
                          })}
                        </Nav>
                      </Col>
                      <Col span={20} className="border pb-2">
                        <Tab.Content>
                          <Tab.Pane eventKey="default">
                            <MyEmpty message="Region Not Selected" />
                          </Tab.Pane>
                          {region.map((r) => {
                            let single_region;
                            if (region_info)
                              single_region = { ...region_info[r._id] };
                            let course_name = "";
                            let short_description = "";
                            let description = "";
                            let prior_knowledge = "";
                            let course_program = "";
                            let why_our_course = "";
                            let basic_course_requirments = "";
                            let virtual_technology_requirments = "";
                            let active = false;
                            if (single_region) {
                              course_name = single_region["course_name"];
                              short_description =
                                single_region["short_description"];
                              description = single_region["description"];
                              prior_knowledge =
                                single_region["prior_knowledge"];
                              course_program = single_region["course_program"];
                              why_our_course = single_region["why_our_course"];
                              basic_course_requirments =
                                single_region["basic_course_requirments"];
                              virtual_technology_requirments =
                                single_region["virtual_technology_requirments"];
                              active = single_region["active"];
                            }
                            return (
                              <Tab.Pane eventKey={r.region_name}>
                                <Row className="mt-2">
                                  <Col
                                    span={20}
                                    className="text-center font-weight-bold"
                                  >
                                    Region : {r.region_name}
                                  </Col>
                                  <Col span={4}>
                                    <button
                                      className="add-btn"
                                      onClick={() => {
                                        const region_info2 = {};
                                        Object.keys(region_info).map((ri) => {
                                          if (region_info[ri].active) {
                                            region_info2[ri] = {
                                              ...region_info[ri],
                                              course_name,
                                              short_description,
                                              description,
                                              prior_knowledge,
                                              course_program,
                                              why_our_course,
                                              basic_course_requirments,
                                              virtual_technology_requirments,
                                              active,
                                            };
                                          }
                                        });
                                        this.setState({
                                          request: {
                                            ...request,
                                            region_info: region_info2,
                                          },
                                        });
                                      }}
                                      // disabled={!active}
                                    >
                                      Copy To All
                                    </button>
                                  </Col>
                                </Row>
                                <FormInputGroup
                                  id="course_name"
                                  name="course_name"
                                  label="Course Name"
                                  placeholder="Course Name"
                                  value={course_name}
                                  data={this.state}
                                  required
                                  setValidation={this.setValidation}
                                  sendValue={(obj) => {
                                    this.set_region_request({
                                      ...obj,
                                      _id: r._id,
                                    });
                                  }}
                                  disabled={!active}
                                />
                                <FormTextareaGroup
                                  id="short_description"
                                  name="short_description"
                                  label="Short Description"
                                  sub_label="(Optional)"
                                  placeholder="Short Description"
                                  data={this.state}
                                  value={short_description}
                                  setValidation={this.setValidation}
                                  sendValue={(obj) => {
                                    this.set_region_request({
                                      ...obj,
                                      _id: r._id,
                                    });
                                  }}
                                  disabled={!active}
                                />
                                <FormTextEditorGroup
                                  value={description}
                                  id="description"
                                  name="description"
                                  label="Description"
                                  sub_label="(Optional)"
                                  placeholder="Description"
                                  data={this.state}
                                  setValidation={this.setValidation}
                                  sendValue={(obj) => {
                                    this.set_region_request({
                                      ...obj,
                                      _id: r._id,
                                    });
                                  }}
                                  disabled={!active}
                                />
                                <FormTextEditorGroup
                                  value={prior_knowledge}
                                  id="prior_knowledge"
                                  name="prior_knowledge"
                                  label="Prior Knowledge"
                                  sub_label="(Optional)"
                                  placeholder="Prior Knowledge"
                                  data={this.state}
                                  setValidation={this.setValidation}
                                  sendValue={(obj) => {
                                    this.set_region_request({
                                      ...obj,
                                      _id: r._id,
                                    });
                                  }}
                                  disabled={!active}
                                />
                                <FormTextEditorGroup
                                  value={course_program}
                                  id="course_program"
                                  name="course_program"
                                  label="Course Program"
                                  sub_label="(Optional)"
                                  placeholder="Course Program"
                                  data={this.state}
                                  setValidation={this.setValidation}
                                  sendValue={(obj) => {
                                    this.set_region_request({
                                      ...obj,
                                      _id: r._id,
                                    });
                                  }}
                                  disabled={!active}
                                />
                                <FormTextEditorGroup
                                  value={why_our_course}
                                  id="why_our_course"
                                  name="why_our_course"
                                  label="Why Our Course"
                                  sub_label="(Optional)"
                                  placeholder="Why Our Course"
                                  data={this.state}
                                  setValidation={this.setValidation}
                                  sendValue={(obj) => {
                                    this.set_region_request({
                                      ...obj,
                                      _id: r._id,
                                    });
                                  }}
                                  disabled={!active}
                                />
                                <FormTextEditorGroup
                                  value={basic_course_requirments}
                                  id="basic_course_requirments"
                                  name="basic_course_requirments"
                                  label="Basic Course Requirments"
                                  sub_label="(Optional)"
                                  placeholder="Basic Course Requirments"
                                  data={this.state}
                                  setValidation={this.setValidation}
                                  sendValue={(obj) => {
                                    this.set_region_request({
                                      ...obj,
                                      _id: r._id,
                                    });
                                  }}
                                  disabled={!active}
                                />
                                <FormTextEditorGroup
                                  value={virtual_technology_requirments}
                                  id="virtual_technology_requirments"
                                  name="virtual_technology_requirments"
                                  label="Virtual Technology Requirments"
                                  sub_label="(Optional)"
                                  placeholder="Virtual Technology Requirments"
                                  data={this.state}
                                  setValidation={this.setValidation}
                                  sendValue={(obj) => {
                                    this.set_region_request({
                                      ...obj,
                                      _id: r._id,
                                    });
                                  }}
                                  disabled={!active}
                                />
                              </Tab.Pane>
                            );
                          })}
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                ) : (
                  ""
                )}
                <button
                  className="add-btn"
                  onClick={() => {
                    this.nextManage({ activeKey: "1" });
                  }}
                >
                  Back
                </button>
              </TabPane>
            </Tabs>
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editCourse();
                else this.addCourse();
              }}
              reset={() => this.setState({ request: { region_info: {} } })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
        {selectedRecord._id ? (
          <CustomOffCanvas
            key={selectedRecord._id}
            status={coffcanvas}
            placement={"right"}
            title="Manage Training"
            close={() => {
              this.setState({
                coffcanvas: false,
                selectedRecord: {},
                chPwdMsg: "",
              });
            }}
            className="drawer-form-organization"
          >
            <Tabs defaultActiveKey="1" onChange={() => {}}>
              <TabPane tab="Info" key="1">
                <div className="bg-light p-2 mt-2">
                  {[selectedRecord].map((selectedRecord) => {
                    const {
                      main_head,
                      languages,
                      category,
                      course_type,
                      scheme,
                      mailer,
                    } = selectedRecord;
                    return (
                      <div className="p-2">
                        {/* <p className="info-main-heading">Learner Details</p> */}
                        <div className="row mt-1 mb-1">
                          <div className="col-12 text-left">
                            <span className="d-block info-heading">
                              Course Name
                            </span>
                            <span className="d-block info-value">
                              {main_head}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TabPane>
              <TabPane tab="Mailer" key="7">
                {[selectedRecord].map((selectedRecord) => {
                  const {
                    main_head,
                    languages,
                    category,
                    course_type,
                    scheme,
                    mailer,
                  } = selectedRecord;
                  return (
                    <>
                      <FormTextEditorGroup
                        id="mailer"
                        name="mailer"
                        label="Content"
                        placeholder="Content"
                        data={this.state}
                        value={mailer}
                        sendValue={({ value }) => {
                          const selectedRecord2 = selectedRecord;
                          selectedRecord2["mailer"] = value;
                          this.setState({ selectedRecord: selectedRecord2 });
                        }}
                      />
                      <FormButton submit={this.save_mailer} />
                    </>
                  );
                })}
              </TabPane>
            </Tabs>
          </CustomOffCanvas>
        ) : (
          ""
        )}
        {seomodal ? (
          <SeoBox
            key={seomodal._id}
            record={seomodal}
            state={this.state}
            setSeoModal={() => {
              this.setState({ seomodal: false });
            }}
            updateSeo={(obj) => {
              const new_records = records.map((rec) => {
                if (rec._id == seomodal._id) rec = { ...rec, ...obj };
                return rec;
              });
              this.setState({ records: new_records });
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Course);
