import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message } from "antd";
import CustomOffCanvas from "../part/CustomOffCanvas";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { enquiry_api } from "../../library/enquiry_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import FormButton from "../part/Form/FormButton";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import { countryListAlpha2 } from "../local_data/variousCountryListFormats";
import { get_data } from "../server_data/get_data";
// ===============================================================================
class Contact_enquiry extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: enquiry_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        version: { valMet: vj_val.checkLength, valLen: 1 },
        title: { valMet: vj_val.checkLength, valLen: 1 },
        scheme: { valMet: vj_val.checkLength, valLen: 1 },
        description: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      coffcanvas: false,
      selectedRecord: {},
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    full_name: {
      title: "Name",
      // width: 300,
      className: "name",
      sorter: true,
      ellipsis: true,
      order: 1,
    },
    email: {
      title: "Email",
      // width: 300,
      className: "email",
      sorter: true,
      ellipsis: true,
      order: 2,
    },
    mobile: {
      title: "Mobile",
      // width: 300,
      className: "mobile",
      sorter: true,
      ellipsis: true,
      order: 3,
    },
    company: {
      title: "Company",
      // width: 300,
      className: "company",
      sorter: true,
      ellipsis: true,
      order: 4,
    },
    city: {
      title: "City",
      // width: 300,
      className: "city",
      sorter: true,
      ellipsis: true,
      order: 5,
    },
    country: {
      title: "Country",
      // width: 300,
      className: "country",
      sorter: true,
      ellipsis: true,
      order: 6,
      custom: (text, record) => countryListAlpha2[text],
    },
    position: {
      title: "Position",
      // width: 300,
      className: "position",
      sorter: true,
      ellipsis: true,
      order: 7,
    },
    region: {
      title: "Region",
      // width: 300,
      className: "region",
      sorter: true,
      ellipsis: true,
      order: 8,
      custom: (text, record) => this.state.region2[text],
    },
    status: {
      title: "Status",
      width: 120,
      className: "status",
      sorter: true,
      ellipsis: true,
      order: 9,
      custom: (text, record) => {
        if (text)
          return (
            <span className="text-success font-weight-bold">Resolved</span>
          );
        return <span className="text-danger font-weight-bold">Open</span>;
      },
    },
  };
  componentDidMount = async () => {
    const { Chabi } = this.state;
    const response = await get_data({
      others: ["region"],
      primary: "enquiry",
      extra: { type: "contact" },
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let { region, records } = data;
        records = add_sno(records);
        let region2 = create_object({
          arr: region,
          title: "region_name",
        });
        this.setState({ region, region2, records });
      }
    } else console.log("Network Error");
    // const region = await get_region({ Chabi });
    // let region2 = {};
    // region.forEach((l) => {
    //   region2[l._id] = l.region_name;
    // });
    // const url = enquiry_api + "/get";
    // const response = await myAxios({
    //   method: "post",
    //   url,
    //   header: { Chabi },
    //   request: { type: "contact" },
    // });
    // if (response.status == 200) {
    //   const { data } = response;
    //   if (data.status == 420) this.setLogout();
    //   else if (data.status == 200) {
    //     let { records } = data;
    //     records = add_sno(records);
    //     this.setState({ records, region, region2 });
    //   }
    // } else console.log("Network Error");
  };
  submit = async () => {
    let { selectedRecord, comment, Chabi, records } = this.state;
    if (!comment.length) return;
    const _id = selectedRecord["_id"];
    const status = +!parseInt(selectedRecord["status"]);
    const url = `${enquiry_api}/comment`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, comment, status },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) {
            r["comment"] = comment;
            r["status"] = status;
          }
          return r;
        });
        this.setState({
          records,
          cmodal: false,
          selectedRecord: {},
          comment: "",
        });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  render() {
    console.log("Training Enquiry State ", this.state);
    let {
      records,
      loginStatus,
      filtered_records,
      cmodal,
      selectedRecord,
      comment,
      coffcanvas,
      region2,
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    columns.push({
      title: "Details",
      className: "details",
      // width: 70,
      dataIndex: "details",
      render: (text, selectedRecord) => {
        return (
          <span
            onClick={async () => {
              // const { Chabi } = this.state;
              // const url = learners_api + "/get_full";
              // const response = await myAxios({
              //   method: "post",
              //   url,
              //   request: { _id: selectedRecord._id },
              //   header: { Chabi },
              // });
              // console.log("response ", response);
              // let learner = {};
              // let organization = {};
              // let user_training = [];
              // let pre_order = [];
              // if (response.status == 200) {
              //   learner = response.data.records[0];
              //   organization = response.data.organization[0];
              //   pre_order = response.data.pre_order;
              //   user_training = response.data.user_training;
              // }
              this.setState({
                coffcanvas: true,
                selectedRecord: { ...selectedRecord },
              });
            }}
            className="btn btn-warning btn-sm"
          >
            Details
          </span>
        );
      },
    });
    columns.push({
      title: "Action",
      className: "details",
      // width: 70,
      dataIndex: "details",
      render: (text, selectedRecord) => {
        if (selectedRecord.status)
          return (
            <span
              onClick={async () => {
                this.setState({ selectedRecord, cmodal: true });
              }}
              className="btn btn-info btn-sm"
            >
              Open?
            </span>
          );
        else
          return (
            <span
              onClick={async () => {
                this.setState({ selectedRecord, cmodal: true });
              }}
              className="btn btn-warning btn-sm"
            >
              Resolve?
            </span>
          );
      },
    });
    return (
      <>
        <PageHeading2
          active="Contact"
          page={["Enquiry", "Contact"]}
        ></PageHeading2>
        <MainArea>
          {/* <Row style={{ marginTop: "20px" }}>
            <Col span={8}>
              <FilterSelectGroup
                id="active"
                name="active"
                label="Status"
                placeholder="Status"
                options={[
                  { text: "Active", value: "1" },
                  { text: "Deactive", value: "0" },
                ]}
                option_value="value"
                option_text="text"
                sendValue={this.set_filter}
              />
            </Col>
          </Row>
          <Row>
            <Col span={2} className="m-2">
              <button className="filter-btn" onClick={this.run_filter}>
                Filter
              </button>
            </Col>
          </Row> */}
          {records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              // activeData={this.activeRecord}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          status={cmodal}
          title="Action"
          close={this.close_modal}
          className="modal-form-comment"
        >
          <FormContainer>
            <FormTextareaGroup
              name="comment"
              label="Comment"
              placeholder="Comment"
              value={comment}
              sendValue={({ value: comment }) => {
                this.setState({ comment });
              }}
            />
            <FormButton
              submit={this.submit}
              submit_text="Submit"
              cancel={() => {
                this.setState({ cmodal: false, comment: "" });
              }}
              cancel_text="Close"
            />
          </FormContainer>
        </CustomModal>

        {selectedRecord._id ? (
          <CustomOffCanvas
            key={selectedRecord._id}
            status={coffcanvas}
            placement={"right"}
            title="Contact Us - Enquiry"
            close={() => {
              this.setState({
                coffcanvas: false,
                selectedRecord: {},
                chPwdMsg: "",
              });
            }}
            className="drawer-form-organization"
          >
            {[selectedRecord].map((selectedRecord) => {
              const {
                full_name,
                email,
                company,
                country,
                details,
                mobile,
                position,
                region,
              } = selectedRecord;
              return (
                <div className="p-2">
                  <p className="info-main-heading">Enquiry Details</p>
                  <div className="row mt-1 mb-1">
                    <div className="col-4 text-left">
                      <span className="d-block info-heading">Name</span>
                      <span className="d-block info-value">{full_name}</span>
                    </div>
                    <div className="col-4">
                      <span className="d-block info-heading">Email</span>
                      <span className="d-block info-value">{email}</span>
                    </div>
                    <div className="col-4">
                      <span className="d-block info-heading">Mobile</span>
                      <span className="d-block info-value">{mobile}</span>
                    </div>
                  </div>
                  <div className="row mt-1 mb-1">
                    <div className="col-4">
                      <span className="d-block info-heading">Organization</span>
                      <span className="d-block info-value">{company}</span>
                    </div>
                    <div className="col-4">
                      <span className="d-block info-heading">Country</span>
                      <span className="d-block info-value">
                        {countryListAlpha2[country]}
                      </span>
                    </div>
                    <div className="col-4 text-left">
                      <span className="d-block info-heading">Region</span>
                      <span className="d-block info-value">
                        {region2[region]}
                      </span>
                    </div>
                  </div>
                  <div className="row mt-1 mb-1">
                    <div className="col">
                      <span className="d-block info-heading">Details</span>
                      <span className="d-block info-value">{details}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </CustomOffCanvas>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Contact_enquiry);
