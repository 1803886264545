import React from "react";
import { Input } from "antd";
function FormCPassword(props) {
  const {
    inputClassName, // css class
    sendValue, //method
    setValidation, // method
    data,
    value,
  } = props;
  let { name, placeholder } = props;
  const { validation, showErr, request, validation_info } = data;
  const { valMet, valPas } = validation_info[name];
  let prevalue = "";
  if (value) prevalue = value;
  else if (request && request[name]) prevalue = request[name];
  const password = request[valPas];
  return (
    <Input
      type="password"
      className={`${inputClassName} ${
        showErr && validation[name] && validation[name].msg
          ? "validation-error"
          : ""
      }`}
      value={prevalue}
      placeholder={placeholder}
      onChange={(e) => {
        let value = e.target.value;
        const error = valMet({ value, password });
        if (error) setValidation({ name, res: false, msg: error });
        else setValidation({ name, res: true });
        sendValue({ name, value });
      }}
    />
  );
}
export default FormCPassword;
