import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { nda_api } from "../../library/nda_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FormFileUploadGroup from "../part/Form/FormFileUploadGroup";
import { get_data } from "../server_data/get_data";
// ===============================================================================
class Nda extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: nda_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        file: { valMet: vj_val.checkFile },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      uploadFileList: [],
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    file: {
      title: "File",
      width: 120,
      className: "file",
      order: 5,
      custom: (text, record) => {
        return (
          <a href={`${nda_api}/file/${text}`} target="_blank">
            NDA
          </a>
        );
      },
    },
  };
  fileUpload = async (fl) => {
    const { uploadFileList, Chabi } = this.state;
    const dont_run = uploadFileList.some((upl) => upl.uid && upl.uid == fl.uid);
    if (dont_run) return false;
    const { originFileObj: file } = fl;
    const url = nda_api + "/file_upload";
    const formData = new FormData();
    formData.append("file", file, file.name); //notice here
    const response = await myAxios({
      method: "post",
      url,
      request: formData,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      const { records } = data;
      this.setRequest({ name: "file", value: records[0].filename });
      const final_obj = { ...fl, ...records[0] };
      uploadFileList.push(final_obj);
      this.setState({ uploadFileList });
      this.setValidation({ name: "file", res: true });
    }
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
    const response = await get_data({
      primary: "nda",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
        let { records } = data;
        records = add_sno(records);
        this.setState({
          records,
        });
      }
    } else console.log("Network Error");
    // const url = nda_api;
    // const response = await myAxios({ method: "get", url,    header: { Chabi }, });
    // if (response.status == 200) {
    //   const { data } = response;
    //   if (data.status == 420) {
    //     this.setState({
    //       loggedUser: undefined,
    //       loginStatus: undefined,
    //     });
    //     doUserLogout();
    //   }
    //   if (data.status == 200) {
    //     let { records } = data;
    //     records = add_sno(records);
    //     this.setState({ records });
    //   }
    // } else console.log("Network Error");
  };
  addNda = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;

    let { records } = this.state;
    const url = nda_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    console.log("add nda ", response);
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout();
     else if (data.status == 200) {
        message.success("Record Saved");
        const { records: r } = data;
        records.push({ ...r[0] });
        records = add_sno(records);
        this.setState({
          records,
          request: {},
          validation: {},
          uploadFileList: [],cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };

  editNda = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;

    let { records } = this.state;
    const { _id, file } = request;
    const url = nda_api;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, file },
    });
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout(); 
else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            rec["file"] = file;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };
  // fastEdit = async (obj) => {
  //   const { field, value, record } = obj;
  //       let { records, doUserLogout, Chabi } = this.state;

  //   if (record[field] == value) return;
  //   const _id = record["_id"];
  //   const url = `${nda_api}/f`;
  //   const response = await myAxios({
  //     method: "put",
  //     url,
  //     request: { _id, [field]: value },
  //   });
  //   if (response.status == 200) {
  //     const { data } = response;
  //     if (data.status == 420) {
  //       this.setState({
  //         loggedUser: undefined,
  //         loginStatus: undefined,
  //       });
  //       doUserLogout();
  //     }
  //     if (data.status == 200) {
  //       records = records.map((r) => {
  //         if (r._id == _id) r[field] = value;
  //         return r;
  //       });
  //       this.setState({ records });
  //       return true;
  //     } else {
  //       const { messages } = data;
  //       if (messages) {
  //         message.error(messages);
  //         return false;
  //       }
  //     }
  //   } else alert("Network Error !");
  // };

  render() {
    console.log("nda State ", this.state);
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });

    return (
      <>
        <PageHeading2 active="NDA" page={["Website Administration", "NDA"]}>
          {records.length == 0 ? (
            <button
              className="add-btn"
              onClick={() => {
                this.setState({ cmodal: !cmodal, edit: false, request: {} });
              }}
            >
              Add New
            </button>
          ) : (
            ""
          )}
        </PageHeading2>
        <MainArea>
          {records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              loadData={this.loadRecord}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage NDA"
          close={this.close_modal}
          className="drawer-form-nda"
        >
          <FormContainer>
            <FormFileUploadGroup
              src={`${page_api}/file/`}
              accept=".pdf,.doc,.docx"
              id="file"
              name="file"
              label="Pdf/Doc"
              sub_label="(Size below 5Mb, Accept pdf,doc)"
              FileCount="1"
              data={this.state}
              required
              fileUpload={this.fileUpload}
              changeUploadFileList={this.changeUploadFileList}
            />
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editNda();
                else this.addNda();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Nda);
