import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Row, Col } from "antd";
import { Tabs } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import CustomOffCanvas from "../part/CustomOffCanvas";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { training_api } from "../../library/training_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { country_array } from "../local_data/variousCountryListFormats";
import { timezone_array } from "../local_data/timezones";
import { currency_array } from "../local_data/currency";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormMultiSelectGroup from "../part/Form/FormMultiSelectGroup";
import SeoBox from "./SeoBox";
import { get_data } from "../server_data/get_data";
import AddNew from "../part/AddNew";
import MyLoading from "../part/MyLoading";
import FormRadioGroup from "../part/Form/FormRadioGroup";
import { back_api } from "../../library/back_api";
import Reviews from "./Reviews";
const { TabPane } = Tabs;
// ===============================================================================
class Training365 extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: training_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        course_name: { valMet: vj_val.checkLength, valLen: 1 },
        languages: { valMet: vj_val.checkLength, valLen: 1 },
        delivery_method: { valMet: vj_val.checkLength, valLen: 1 },
        region: { valMet: vj_val.checkLength, valLen: 1 },
        currency: { valMet: vj_val.checkLength, valLen: 1 },
        course_fees: { valMet: vj_val.checkNumber, valLen: 1 },
        certificate_fees: { valMet: vj_val.checkNumber, valLen: 1 },
        // ============================================================
        // ============================================================
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      seomodal: false,
      activeKey: "1",
      nextTabMsg: "",
      coffcanvas: false,
      selectedRecord: {},
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    course_name: {
      title: "Course Name",
      // width: 300,
      className: "course_name",
      sorter: true,
      ellipsis: true,
      order: 1,
      custom: (text, record) => this.state.course2[text],
    },
    region: {
      title: "Region",
      // width: 300,
      className: "region",
      order: 2,
      sorter: true,
      ellipsis: true,
      custom: (text, record) => this.state.region2[text],
    },
    course_fees: {
      title: "Course Fees",
      // width: 300,
      className: "course_fees",
      order: 3,
      sorter: true,
      ellipsis: true,
    },
    certificate_fees: {
      title: "Certificate Fees",
      // width: 300,
      className: "certificate_fees",
      order: 4,
      sorter: true,
      ellipsis: true,
    },
    languages: {
      title: "Language",
      // width: 300,
      className: "language",
      order: 5,
      sorter: true,
      ellipsis: true,
      custom: (text, record) => {
        return text.split(",").map((t) => this.state.language2[t]);
      },
    },
    delivery_method: {
      title: "Delivery Method",
      // width: 300,
      className: "delivery_method",
      order: 6,
      sorter: true,
      custom: (text, record) => {
        return this.state.delivery_method2[text];
      },
    },
    active: {
      title: "Status",
      width: 120,
      className: "active",
      sorter: true,
      ellipsis: true,
      order: 7,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Active</span>;
        return <span className="text-danger font-weight-bold">Deactive</span>;
      },
    },
  };
  componentDidMount = async () => {
    const { Chabi } = this.state;
    const response = await get_data({
      others: [
        "delivery_method",
        "learning_scheme",
        "course",
        "language",
        "region",
        "organization",
      ],
      primary: "training",
      extra: { type: "365" },
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          records,
          delivery_method,
          learning_scheme,
          course,
          language,
          region,
          organization,
        } = data;
        records = add_sno(records);
        let learning_scheme2 = create_object({
          arr: learning_scheme,
          title: "main_head",
        });
        let course2 = create_object({
          arr: course,
          title: "main_head",
        });
        let organization2 = create_object({
          arr: organization,
          title: "organization_name",
        });
        let language2 = create_object({
          arr: language,
          title: "language",
        });
        let delivery_method2 = create_object({
          arr: delivery_method,
          title: "title",
        });
        let region2 = create_object({ arr: region, title: "region_name" });
        this.setState({
          organization2,
          organization,
          learning_scheme,
          learning_scheme2,
          course,
          course2,
          language,
          language2,
          delivery_method,
          delivery_method2,
          region,
          region2,
          records,
        });
      }
    } else console.log("Network Error");
    this.setState({ CDM: true });
  };
  addTraining = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = training_api + "/365";
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
        const { records: r } = data;
        records.push({ ...r[0] });
        records = add_sno(records);
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };
  editTraining = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const {
      _id,
      course_name,
      languages,
      delivery_method,
      region,
      currency,
      course_fees,
      material_fees,
      certificate_fees,
      releated_training,
    } = request;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = training_api + "/365";
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: {
        _id,
        course_name,
        languages,
        delivery_method,
        region,
        currency,
        course_fees,
        material_fees,
        certificate_fees,
        releated_training,
      },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            rec["course_name"] = course_name;
            rec["delivery_method"] = delivery_method;
            rec["languages"] = languages;
            rec["region"] = region;
            rec["currency"] = currency;
            rec["course_fees"] = course_fees;
            rec["material_fees"] = material_fees;
            rec["certificate_fees"] = certificate_fees;
            rec["releated_training"] = releated_training;
          }
          return rec;
        });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };
  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, doUserLogout, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${training_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  render() {
    console.log("training State ", this.state);
    let {
      records,
      edit,
      loginStatus,
      cmodal,
      request,
      filtered_records,
      language,
      region,
      delivery_method,
      seomodal,
      course,
      CDM,
      coffcanvas,
      selectedRecord,
      organization2,
      region2,
      course2,
      delivery_method2,
      language2,
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    columns.push({
      width: 100,
      title: "SEO",
      dataIndex: "SEO",
      render: (text, record) => {
        return (
          <a
            onClick={() => {
              this.setState({ seomodal: record });
            }}
          >
            SEO
          </a>
        );
      },
    });
    columns.push({
      title: "Details",
      className: "details",
      // width: 70,
      dataIndex: "details",
      render: (text, selectedRecord) => {
        return (
          <span
            onClick={async () => {
              const { Chabi } = this.state;
              const url = back_api + "/training_details";
              const response = await myAxios({
                method: "post",
                url,
                request: { _id: selectedRecord._id },
                header: { Chabi },
              });
              let learner = {};
              let bulk_order = {};
              if (response.status == 200) {
                const { data } = response;
                learner = data.learner;
                bulk_order = data.bulk_order;
              }
              this.setState({
                coffcanvas: true,
                selectedRecord: {
                  ...selectedRecord,
                  extra: { bulk_order, learner },
                },
              });
            }}
            className="btn btn-warning btn-sm"
          >
            Details
          </span>
        );
      },
    });
    let filter_region = [];
    let selected_region_info;
    if (request.course_name) {
      selected_region_info = course.filter((ls) => {
        return ls._id == request.course_name;
      })[0]["region_info"];
      const selected_region = Object.keys(selected_region_info).filter(
        (sri) => selected_region_info[sri].active
      );
      filter_region = region.filter((r1) => {
        return selected_region.includes(r1._id);
      });
    }
    return (
      <>
        <PageHeading2 active="Training365" page={["Training365"]}>
          <AddNew
            disabled={!CDM}
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          />
        </PageHeading2>
        <MainArea>
          {/* <Row style={{ marginTop: "20px" }}>
            <Col span={8}>
              <FilterSelectGroup
                id="active"
                name="active"
                label="Status"
                placeholder="Status"
                options={[
                  { text: "Active", value: "1" },
                  { text: "Deactive", value: "0" },
                ]}
                option_value="value"
                option_text="text"
                sendValue={this.set_filter}
              />
            </Col>
          </Row>
          <Row>
            <Col span={2} className="m-2">
              <button className="filter-btn" onClick={this.run_filter}>
                Filter
              </button>
            </Col>
          </Row> */}
          {!CDM ? (
            <MyLoading />
          ) : records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              loadData={this.loadRecord}
              removeData={this.removeRecord}
              activeData={this.activeRecord}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Training365"
          close={this.close_modal}
          className="modal-form-course"
        >
          <FormContainer>
            <Row>
              <Col span={24}>
                <FormSelectGroup
                  allowClear
                  id="course_name"
                  name="course_name"
                  label="Course Name"
                  placeholder="Course Name"
                  data={this.state}
                  required
                  options={course}
                  option_value="_id"
                  option_text="main_head"
                  setValidation={this.setValidation}
                  sendValue={(obj) => {
                    const { name, value } = obj;
                    const selected_course = course.filter(
                      (c) => c._id == value
                    )[0];
                    const { languages } = selected_course;
                    request["languages"] = languages;
                    request[name] = value;
                    this.setState({ request });
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <FormSelectGroup
                  allowClear
                  id="region"
                  name="region"
                  label="Region"
                  placeholder="Region"
                  data={this.state}
                  required
                  options={filter_region}
                  option_value="_id"
                  option_text="region_name"
                  setValidation={this.setValidation}
                  sendValue={(obj) => {
                    const { name, value } = obj;
                    // const course_id = request["course_name"];
                    const selected_region = filter_region.filter(
                      (c) => c._id == value
                    )[0];
                    const { currency } = selected_region;
                    request["currency"] = currency;
                    const { fees } = selected_region_info[value];
                    request["course_fees"] = fees;
                    request[name] = value;
                    this.setState({ request });
                  }}
                />
              </Col>
              <Col span={8}>
                <FormMultiSelectGroup
                  allowClear
                  id="languages"
                  name="languages"
                  label="Languages"
                  placeholder="Languages"
                  data={this.state}
                  required
                  options={language}
                  option_value="_id"
                  option_text="language"
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={8}>
                <FormSelectGroup
                  allowClear
                  id="delivery_method"
                  name="delivery_method"
                  label="Delivery Method"
                  placeholder="Delivery Method"
                  data={this.state}
                  required
                  options={delivery_method}
                  option_value="_id"
                  option_text="title"
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
            </Row>
            <Row>
              <Col span={6}>
                <FormSelectGroup
                  id="currency"
                  name="currency"
                  label="Currency"
                  placeholder="Currency"
                  data={this.state}
                  required
                  options={currency_array}
                  option_value="code"
                  option_text="full_name"
                  // valMet={checkUuid}
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={6}>
                <FormInputGroup
                  type="number"
                  id="course_fees"
                  name="course_fees"
                  label="Course Fees"
                  placeholder="Course Fees"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={6}>
                <FormInputGroup
                  type="number"
                  id="material_fees"
                  name="material_fees"
                  label="Material Fees"
                  sub_label="(Optional)"
                  placeholder="Material Fees"
                  data={this.state}
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={6}>
                <FormInputGroup
                  type="number"
                  id="certificate_fees"
                  name="certificate_fees"
                  label="Certificate Fees"
                  placeholder="Certificate Fees"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
            </Row>
            {/* <Row  className="mb-4">
                  <Col span={24}>
                    <FormMultiSelectGroup
                      allowClear
                      id="releated_training"
                      name="releated_training"
                      label="Releated Training365"
                      placeholder="Releated Training365"
                      data={this.state}required
                      options={records}
                      option_value="_id"
                      option_text="course_name"
                      setValidation={this.setValidation}
                      sendValue={this.setRequest}
                    />
                  </Col>
                </Row> */}
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editTraining();
                else this.addTraining();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
        {selectedRecord._id ? (
          <CustomOffCanvas
            key={selectedRecord._id}
            status={coffcanvas}
            placement={"right"}
            title="Manage Training"
            close={() => {
              this.setState({
                coffcanvas: false,
                selectedRecord: {},
                chPwdMsg: "",
              });
            }}
            className="drawer-form-organization"
          >
            <Tabs defaultActiveKey="1" onChange={() => {}}>
              <TabPane tab="Info" key="1">
                <div className="bg-light p-2 mt-2">
                  {[selectedRecord].map((selectedRecord) => {
                    const {
                      course_name,
                      certificate_fees,
                      course_fees,
                      currency,
                      delivery_method,
                      duration,
                      languages,
                      region,
                      material_fees,
                      seats,
                      start_date,
                      start_time,
                      timezone,
                    } = selectedRecord;
                    return (
                      <div className="p-2">
                        {/* <p className="info-main-heading">Learner Details</p> */}
                        <div className="row mt-1 mb-1">
                          <div className="col-12 text-left">
                            <span className="d-block info-heading">
                              Course Name
                            </span>
                            <span className="d-block info-value">
                              {course2[course_name]}
                            </span>
                          </div>
                          <div className="col-4">
                            <span className="d-block info-heading">Seats</span>
                            <span className="d-block info-value">{seats}</span>
                          </div>
                          <div className="col-4">
                            <span className="d-block info-heading">
                              Start Date
                            </span>
                            <span className="d-block info-value">
                              {start_date}
                            </span>
                          </div>
                        </div>
                        <div className="row mt-1 mb-1">
                          <div className="col-4">
                            <span className="d-block info-heading">
                              start_time
                            </span>
                            <span className="d-block info-value">
                              {start_time}
                            </span>
                          </div>
                          <div className="col-4">
                            <span className="d-block info-heading">
                              timezone
                            </span>
                            <span className="d-block info-value">
                              {timezone}
                            </span>
                          </div>
                          <div className="col-4 text-left">
                            <span className="d-block info-heading">Region</span>
                            <span className="d-block info-value">
                              {region2[region]}
                            </span>
                          </div>
                        </div>
                        <div className="row mt-1 mb-1">
                          <div className="col-4">
                            <span className="d-block info-heading">
                              course_fees
                            </span>
                            <span className="d-block info-value">
                              {course_fees}
                            </span>
                          </div>
                          <div className="col-4">
                            <span className="d-block info-heading">
                              certificate_fees
                            </span>
                            <span className="d-block info-value">
                              {certificate_fees}
                            </span>
                          </div>
                          <div className="col-4 text-left">
                            <span className="d-block info-heading">
                              material_fees
                            </span>
                            <span className="d-block info-value">
                              {material_fees}
                            </span>
                          </div>
                        </div>
                        <div className="row mt-1 mb-1">
                          <div className="col-4">
                            <span className="d-block info-heading">
                              delivery_method
                            </span>
                            <span className="d-block info-value">
                              {delivery_method}
                            </span>
                          </div>
                          <div className="col-4">
                            <span className="d-block info-heading">
                              languages
                            </span>
                            <span className="d-block info-value">
                              {languages}
                            </span>
                          </div>
                          <div className="col-4 text-left">
                            <span className="d-block info-heading">
                              duration
                            </span>
                            <span className="d-block info-value">
                              {duration}
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </TabPane>
              <TabPane tab="Learners" key="2">
                {/* {selectedRecord.extra.user_training.length ? (
                  <div className="bg-light p-2 mt-2">
                    <p className="info-main-heading mt-3">Training Details </p>
                    {selectedRecord.extra.user_training.map((t) => {
                      const {
                        status,
                        order_id,
                        user_id,
                        create_at,
                        training,
                        removed_by_admin,
                      } = t;
                      const {
                        _id: training_id,
                        certificate_fees,
                        course_fees,
                        course_name,
                        languages: lang,
                        delivery_method: dm,
                        is365,
                        material_fees,
                        start_date,
                        start_time,
                        timezone,
                      } = training;
                      return (
                        <div
                          style={{
                            background: "#ffffff",
                            marginBottom: "20px",
                            padding: "10px",
                          }}
                        >
                          <div className="row mt-1 mb-1">
                            <div className="col-4 text-left">
                              <span className="d-block info-heading">
                                Course
                              </span>
                              <span className="d-block info-value">
                                {course2[course_name]}
                              </span>
                            </div>
                            {is365 ? (
                              ""
                            ) : (
                              <div className="col-4">
                                <span className="d-block info-heading">
                                  Delivery Method
                                </span>
                                <span className="d-block info-value">
                                  {dm
                                    ? dm
                                        .split(",")
                                        .map((t) => delivery_method2[t])
                                        .toString(",")
                                    : ""}
                                </span>
                              </div>
                            )}
                            <div className="col-4">
                              <span className="d-block info-heading">
                                Languages
                              </span>
                              <span className="d-block info-value">
                                {lang
                                  ? lang
                                      .split(",")
                                      .map((t) => language2[t])
                                      .toString(",")
                                  : ""}
                              </span>
                            </div>
                          </div>
                          {is365 ? (
                            ""
                          ) : (
                            <div className="row mt-1 mb-1">
                              <div className="col-4 text-left">
                                <span className="d-block info-heading">
                                  Date
                                </span>
                                <span className="d-block info-value">
                                  {start_date}
                                </span>
                              </div>
                              <div className="col-4">
                                <span className="d-block info-heading">
                                  Time
                                </span>
                                <span className="d-block info-value">
                                  {start_time}
                                </span>
                              </div>
                              <div className="col-4">
                                <span className="d-block info-heading">
                                  Timezone
                                </span>
                                <span className="d-block info-value">
                                  {timezone}
                                </span>
                              </div>
                            </div>
                          )}
                          <div className="row">
                            <div className="col-4 text-left">
                              <span className="d-block info-heading">
                                Course Fees
                              </span>
                              <span className="d-block info-value">
                                {course_fees}
                              </span>
                            </div>
                            <div className="col-4">
                              <span className="d-block info-heading">
                                Certificate Fees
                              </span>
                              <span className="d-block info-value">
                                {certificate_fees}
                              </span>
                            </div>
                            <div className="col-4">
                              <span className="d-block info-heading">
                                Material Fees
                              </span>
                              <span className="d-block info-value">
                                {material_fees}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-3">
                            <div className="col-4 text-left">
                              {removed_by_admin ? (
                                <a className="btn btn-warning btn-sm">
                                  Removed
                                </a>
                              ) : (
                                <a
                                  className="btn btn-danger btn-sm"
                                  onClick={async () => {
                                    if (!window.confirm("Are you sure?"))
                                      return false;
                                    const { Chabi } = this.state;
                                    const url = user_training_api + "/remove";
                                    const response = await myAxios({
                                      method: "put",
                                      url,
                                      request: {
                                        order_id,
                                        training_id,
                                        user_id,
                                      },
                                      header: { Chabi },
                                    });
                                    console.log("response ", response);
                                    if (
                                      response.status == 200 &&
                                      response.data.status == 200
                                    ) {
                                      const selectedRecord2 =
                                        selectedRecord.extra.user_training.map(
                                          (t) => {
                                            if (
                                              t.order_id == order_id &&
                                              t.training._id == training_id
                                            ) {
                                              t["removed_by_admin"] = 1;
                                              t["removed"] = 1;
                                            }
                                            return t;
                                          }
                                        );
                                      selectedRecord["extra"]["user_training"] =
                                        selectedRecord2;
                                      this.setState({ selectedRecord });
                                    } else {
                                    }
                                  }}
                                >
                                  Remove Training
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <MyEmpty />
                )} */}
              </TabPane>
              <TabPane tab="Bulk Orders" key="3">
                {selectedRecord.extra.bulk_order.length ? (
                  <div className="bg-light p-2 mt-2">
                    <p className="info-main-heading mt-3">Order Details </p>
                    {selectedRecord.extra.bulk_order.map((t) => {
                      const {
                        amount,
                        create_at,
                        discount,
                        items,
                        is365,
                        organization_id,
                        payment_ref,
                        status,
                        tax_amount,
                        total_amount,
                        _id: bulk_order_id,
                      } = t;
                      return (
                        <div
                          style={{
                            background: "#ffffff",
                            marginBottom: "20px",
                            padding: "10px",
                          }}
                        >
                          <div className="row mt-1 mb-1">
                            <div className="col-3 text-left">
                              <span className="d-block info-heading">Date</span>
                              <span className="d-block info-value">
                                {create_at}
                              </span>
                            </div>
                            <div className="col-3 text-left">
                              <span className="d-block info-heading">
                                Order id
                              </span>
                              <span className="d-block info-value">
                                {bulk_order_id}
                              </span>
                            </div>
                            <div className="col-3">
                              <span className="d-block info-heading">
                                Payment Code
                              </span>
                              <span className="d-block info-value">
                                {payment_ref}
                              </span>
                            </div>
                            <div className="col-3">
                              <span className="d-block info-heading">
                                Status
                              </span>
                              <span className="d-block info-value">
                                {status}
                              </span>
                            </div>
                          </div>
                          <div className="row mt-1 mb-1">
                            <div className="col-3 text-left">
                              <span className="d-block info-heading">
                                Amount
                              </span>
                              <span className="d-block info-value">
                                {amount}
                              </span>
                            </div>
                            <div className="col-3 text-left">
                              <span className="d-block info-heading">
                                Tax Amount
                              </span>
                              <span className="d-block info-value">
                                {tax_amount}
                              </span>
                            </div>
                            <div className="col-3">
                              <span className="d-block info-heading">
                                Discount
                              </span>
                              <span className="d-block info-value">
                                {discount}
                              </span>
                            </div>
                            <div className="col-3">
                              <span className="d-block info-heading">
                                Total Amount
                              </span>
                              <span className="d-block info-value">
                                {total_amount}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <MyEmpty />
                )}
              </TabPane>
              <TabPane tab="Purchase Order" key="4">
                {/* <Row className="border">
                  <Col spanb={8}>
                    <Row>
                      <Col span={24}>
                        <FormPasswordGroup
                          id="password"
                          name="password"
                          label="Password"
                          placeholder="Password"
                          value={request2["password"]}
                          sendValue={this.setRequest2}
                        />
                      </Col>
                      <Col span={24}>
                        <FormPasswordGroup
                          id="cpassword"
                          name="cpassword"
                          label="Confirm Password"
                          placeholder="Confirm Password"
                          value={request2["cpassword"]}
                          sendValue={this.setRequest2}
                        />
                      </Col>
                      <Col span={24} className="m-2">
                        {chPwdMsg == "1" ? (
                          <span
                            className="text-success"
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            Learner Password Changed
                          </span>
                        ) : chPwdMsg == "0" ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            Somethign Wrong
                          </span>
                        ) : chPwdMsg == "-1" ? (
                          <span
                            className="text-danger"
                            style={{ fontSize: "16px", fontWeight: "bold" }}
                          >
                            Password and Confirm Password must be same and not
                            blank
                          </span>
                        ) : (
                          ""
                        )}
                      </Col>
                      <Col span={24} className="m-2">
                        <FormButton
                          reset={() => {
                            this.setState({ request2: {}, chPwdMsg: "" });
                          }}
                          submit={async () => {
                            console.log("request2 ", request2);
                            const { password, cpassword } = request2;
                            if (
                              password &&
                              cpassword &&
                              password.length >= 6 &&
                              password == cpassword
                            ) {
                              const url = learners_api + "/cpa";
                              const response = await myAxios({
                                method: "put",
                                url,
                                header: { Chabi },
                                request: {
                                  _id: selectedRecord._id,
                                  ...request2,
                                },
                              });
                              if (response.status == 200) {
                                this.setState({ request2: {}, chPwdMsg: "1" });
                              } else this.setState({ chPwdMsg: "0" });
                            } else this.setState({ chPwdMsg: "-1" });
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col spanb={8}></Col>
                  <Col spanb={8}></Col>
                </Row> */}
              </TabPane>
              <TabPane tab="Payments" key="5">
                {/* <Row className="mb-4">
                  <Col span={8}>
                    <FormSelectGroup
                      allowClear
                      id="Training"
                      name="Training"
                      label="training"
                      placeholder="training"
                      // data={this.state}
                      // required
                      options={course_training}
                      option_value="_id"
                      option_text="course_name"
                      // setValidation={this.setValidation}
                      sendValue={({ value }) => {
                        this.setState({ training_id: value });
                      }}
                    />
                  </Col>
                  <FormButton
                    submit={async () => {
                      if (!window.confirm("Are you sure?")) return false;
                      const { selectedRecord, training_id, Chabi } = this.state;
                      if (training_id && training_id.length) {
                        const { _id: user_id } = selectedRecord;
                        const url = user_training_api + "/add";
                        const response = await myAxios({
                          method: "post",
                          url,
                          header: { Chabi },
                          request: { user_id, training_id },
                        });
                        console.log("add course ", response);
                        if (
                          response.status == 200 &&
                          response.data.status == 200
                        ) {
                          const { records } = response.data;
                          selectedRecord["extra"]["user_training"].push(
                            records
                          );
                          this.setState({ selectedRecord, training_id: "" });
                          alert("Training assigned to learner");
                        }
                      } else alert("please select training ");
                    }}
                  />
                </Row> */}
              </TabPane>
              <TabPane tab="Reviews" key="6">
                <Reviews training_id={selectedRecord._id} />
              </TabPane>
              <TabPane tab="Mailer" key="7">
                {/* <Row className="mb-4">
                  <Col span={8}>
                    <FormSelectGroup
                      allowClear
                      id="Training"
                      name="Training"
                      label="training"
                      placeholder="training"
                      // data={this.state}
                      // required
                      options={course_training}
                      option_value="_id"
                      option_text="course_name"
                      // setValidation={this.setValidation}
                      sendValue={({ value }) => {
                        this.setState({ training_id: value });
                      }}
                    />
                  </Col>
                  <FormButton
                    submit={async () => {
                      if (!window.confirm("Are you sure?")) return false;
                      const { selectedRecord, training_id, Chabi } = this.state;
                      if (training_id && training_id.length) {
                        const { _id: user_id } = selectedRecord;
                        const url = user_training_api + "/add";
                        const response = await myAxios({
                          method: "post",
                          url,
                          header: { Chabi },
                          request: { user_id, training_id },
                        });
                        console.log("add course ", response);
                        if (
                          response.status == 200 &&
                          response.data.status == 200
                        ) {
                          const { records } = response.data;
                          selectedRecord["extra"]["user_training"].push(
                            records
                          );
                          this.setState({ selectedRecord, training_id: "" });
                          alert("Training assigned to learner");
                        }
                      } else alert("please select training ");
                    }}
                  />
                </Row> */}
              </TabPane>
            </Tabs>
          </CustomOffCanvas>
        ) : (
          ""
        )}
        {seomodal ? (
          <SeoBox
            key={seomodal._id}
            record={seomodal}
            state={this.state}
            setSeoModal={() => {
              this.setState({ seomodal: false });
            }}
            updateSeo={(obj) => {
              const new_records = records.map((rec) => {
                if (rec._id == seomodal._id) rec = { ...rec, ...obj };
                return rec;
              });
              this.setState({ records: new_records });
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Training365);
