import React, { Component } from "react";
import { add_sno } from "../../library/object_functions";
export class FormParent extends Component {
  constructor(props) {
    super(props);
  }
  setRequest = (obj) => {
    const { name, value } = obj;
    const { filtered_records, request } = this.state;
    request[name] = value;
    this.setState({ request });
  };
  setRequest2 = (obj) => {
    const { name, value } = obj;
    const { request2 } = this.state;
    request2[name] = value;
    this.setState({ request2 });
  };
  setValidation = (obj) => {
    const { name, res, msg } = obj;
    const { validation } = this.state;
    validation[name] = { res, msg };
    this.setState({ validation });
  };
  setLogout = () => {
    let { doUserLogout } = this.state;
    this.setState({
      loggedUser: undefined,
      loginStatus: undefined,
    });
    doUserLogout();
  };
  checkValidation = () => {
    //checkValidation and checkNextValidation can be single function please check both function body
    const { validation_info, request } = this.state;
    let val_result = true;
    let reason = {};
    if (validation_info) {
      Object.keys(validation_info).forEach((field) => {
        if (request[field]) {
          const value = request[field];
          const { valMet, valLen, valPas } = validation_info[field];
          let password;
          if (valPas) password = request[valPas];
          const error = valMet({ value, valLen, password });
          if (error) {
            console.log(`${field} - ${error} - 2`);
            reason[field] = { res: false, msg: error, why: "invalid" };
            val_result = false;
          }
        } else {
          console.log(`${field} - ${field} Is Required - 1`);
          reason[field] = {
            res: false,
            msg: `${field} Is Required`,
            why: "blank",
          };
          val_result = false;
        }
      });
    }
    this.setState({ validation: reason });
    return val_result;
  };
  checkNextValidation = ({ fields }) => {
    const { validation_info, request } = this.state;
    //checkValidation and checkNextValidation can be single function please check both function body
    let val_result = true;
    let reason = {};
    fields.forEach((field) => {
      if (request[field]) {
        const value = request[field];
        const { valMet, valLen } = validation_info[field];
        const error = valMet({ value, valLen });
        if (error) {
          reason[field] = { res: false, msg: error, why: "invalid" };
          val_result = false;
        }
      } else {
        reason[field] = {
          res: false,
          msg: `${field} Is Required`,
          why: "blank",
        };
        val_result = false;
      }
    });
    return { val_result, reason };
  };
  checkRegionValidation = ({ val_info_obj, region_info }) => {
    let val_result = true;
    let reason = {};
    Object.keys(val_info_obj).forEach((field) => {
      if (region_info[field]) {
        const value = region_info[field];
        const { valMet, valLen } = val_info_obj[field];
        const error = valMet({ value, valLen });
        if (error) {
          reason[field] = { res: false, msg: error, why: "invalid" };
          val_result = false;
        }
      } else {
        reason[field] = {
          res: false,
          msg: `${field} Is Required`,
          why: "blank",
        };
        val_result = false;
      }
    });
    return { val_result, reason };
  };
  set_filter = (obj) => {
    const { value, name } = obj;
    let { filter } = this.state;
    if (value && value.length) filter[name] = value;
    else delete filter[name];
    this.setState({ filter });
  };
  run_filter = () => {
    let { filter, records, filtered_records } = this.state;
    const fields = Object.keys(filter);
    if (fields.length) {
      filtered_records = records;
      fields.forEach((f) => {
        filtered_records = filtered_records.filter((r) => r[f] == filter[f]);
      });
      this.setState({ filtered_records });
    } else {
      const state = this.state;
      delete state["filtered_records"];
      this.setState(state);
    }
  };
  close_modal = () => {
    const { regionEnabled } = this.state;
    const request = {};
    if (regionEnabled) request["region_info"] = {};
    this.setState({
      validation: {},
      request,
      cmodal: false,
      uploadImageList: [],
      uploadFileList: [],
      activeKey: "1",
      selectedTab: undefined,
      selectedRecord: {},
    });
  };
  changeUploadImageList = (uploadImageList) => {
    if (uploadImageList.length == 0)
      this.setRequest({ name: "image", value: "" });
    this.setState({ uploadImageList });
  };
  changeUploadFileList = (uploadFileList) => {
    if (uploadFileList.length == 0)
      this.setRequest({ name: "file", value: "" });
    this.setState({ uploadFileList });
  };
  removeRecord = async (record) => {
    let { records } = this.state;
    const { _id } = record;
    if (!window.confirm("Are you sure? Record will be delete!")) return false;
    const result = await this.fastEdit({ field: "removed", value: 1, record });
    if (result) {
      records = records.filter((r) => r._id != _id);
      records = add_sno(records);
      this.setState({ records });
    }
  };
  activeRecord = async (record) => {
    const active = +!parseInt(record["active"]);
    this.fastEdit({ field: "active", value: active, record });
  };
  loadRecord = async (_id) => {
    let { records, request } = this.state;
    const record = records.filter((r) => r._id == _id)[0];
    request = { ...request, ...record };
    this.setState({
      edit: true,
      request,
      cmodal: true,
    });
  };
  set_region_request = (obj) => {
    const { name, value, _id } = obj;
    console.log("obj 2", obj);
    const { request } = this.state;
    let { region_info } = request;
    if (!region_info) region_info = {};
    const single_region = { ...region_info[_id] };
    single_region[name] = value;
    region_info[_id] = single_region;

    request["region_info"] = region_info;
    this.setState({ request });
  };
}
export default FormParent;
