import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { Image, message, Row, Col } from "antd";
import { userLogout } from "../../Action/userAction";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
// ===============================================================================
import { user_api } from "../../library/user_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
// ===============================================================================
import MainArea from "../part/MainArea/";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormMobileGroup from "../part/Form/FormMobileGroup";
import FormEmailGroup from "../part/Form/FormEmailGroup";
import FormPasswordGroup from "../part/Form/FormPasswordGroup";
import FormCPasswordGroup from "../part/Form/FormCPasswordGroup";
import FormParent from ".//FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable/";
import PageHeading2 from "../part/PageHeading2";
import { add_sno } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FormImageUploadGroup from "../part/Form/FormImageUploadGroup";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";
import { PORTAL } from "../../currrent_info";
import { get_data } from "../server_data/get_data";
// ===============================================================================
class User extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: user_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        image: { valMet: vj_val.checkImage },
        name: { valMet: vj_val.checkLength, valLen: 1 },
        email: { valMet: vj_val.checkEmail },
        mobile: { valMet: vj_val.checkNumber },
        password: { valMet: vj_val.checkPassword },
        cpassword: { valMet: vj_val.checkCPassword, valPas: "password" },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      uploadImageList: [],
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 80,
      defaultSortOrder: "descend",
      sorter: true,
      ellipsis: true,
      order: 0,
    },
    name: {
      title: "Name",
      width: 250,
      className: "name",
      sorter: true,
      ellipsis: true,
      order: 1,
    },
    email: {
      title: "Email",
      width: 250,
      className: "email",
      order: 2,
    },
    mobile: {
      title: "Mobile",
      width: 200,
      className: "mobile",
      order: 3,
    },
    role: {
      title: "Role",
      width: 200,
      className: "role",
      order: 4,
    },
    active: {
      title: "Status",
      width: 80,
      className: "active",
      sorter: true,
      ellipsis: true,
      order: 5,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Active</span>;
        return <span className="text-danger font-weight-bold">Deactive</span>;
      },
    },
    image: {
      title: "Image",
      width: 120,
      className: "image",
      order: 6,
      custom: (text, record) => {
        if (text && text.length)
          return (
            <Image
              width={50}
              // src={`${user_api}/image/${text}`}
              src={`${PORTAL.api_url}uploaded_images/${text}`}
              alt="logo"
            />
          );
        return "";
      },
    },
  };
  imageUpload = async (fl) => {
    const { uploadImageList, Chabi } = this.state;
    const dont_run = uploadImageList.some(
      (upl) => upl.uid && upl.uid == fl.uid
    );
    if (dont_run) return false;
    const { originFileObj: file } = fl;
    const url = user_api + "/image_upload";
    const formData = new FormData();
    formData.append("file", file, file.name); //notice here
    const response = await myAxios({
      method: "post",
      url,
      request: formData,
      header: { Chabi },
    });
    if (response.status == 200) {
      const { data } = response;
      const { records } = data;
      this.setRequest({ name: "image", value: records[0].filename });
      const final_obj = { ...fl, ...records[0] };
      uploadImageList.push(final_obj);
      this.setState({ uploadImageList });
      this.setValidation({ name: "image", res: true });
    }
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
    const response = await get_data({
      primary: "user",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let { records } = data;
        records = add_sno(records);
        this.setState({
          records,
        });
      }
    } else console.log("Network Error");
    // const url = user_api;
    // const response = await myAxios({ method: "get", url, header: { Chabi } });
    // console.log("user response", response);
    // if (response.status == 200) {
    //   const { data } = response;
    //   if (data.status == 420) {
    //     this.setState({
    //       loggedUser: undefined,
    //       loginStatus: undefined,
    //     });
    //     doUserLogout();
    //   }
    //   if (data.status == 200) {
    //     let { records } = data;
    //     records = add_sno(records);
    //     this.setState({ records });
    //   }
    // } else console.log("something wrong");
  };
  addUser = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = user_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    console.log("add user ", response);
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
        const { records: r } = data;
        records.push({ ...r[0] });
        records = add_sno(records);
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };
  loadUser = async (useruid) => {
    let { records, filtered_records, request } = this.state;
    const user = records.filter((r) => r._id == useruid)[0];
    request = { ...request, ...user };
    this.setState({ request, edit: true, cmodal: true });
  };
  editUser = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const { useruid, name, mobile, email, code } = request;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = user_api;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { useruid, name, mobile, email, code },
    });
    console.log(response);
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec.useruid == useruid) {
            rec["name"] = name;
            rec["mobile"] = mobile;
            rec["email"] = email;
            rec["code"] = code;
          }
          return rec;
        });
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };
  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, doUserLogout, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${user_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    console.log(response);
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) return message.error(messages);
      }
    } else alert("Network Error !");
  };
  render() {
    console.log("user State ", this.state);
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      filtered_records,
      request,
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
    records = [...records];
    let columns = [];
    if (records.length) {
      columns = createColumn({
        records,
        displayFields: this.displayFields,
        fastEdit: this.fastEdit,
      });
    }
    return (
      <>
        <PageHeading2 active="User" page={["Website Administration", "User"]}>
          <button
            className="add-btn"
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          >
            Add New
          </button>
        </PageHeading2>
        <MainArea>
          <Row style={{ marginTop: "20px" }}>
            <Col span={12}>
              <FilterSelectGroup
                id="active"
                name="active"
                label="Status"
                placeholder="Status"
                options={[
                  { text: "Active", value: "1" },
                  { text: "Deactive", value: "0" },
                ]}
                option_value="value"
                option_text="text"
                sendValue={this.set_filter}
              />
            </Col>
          </Row>
          <Row>
            <Col span={2} className="m-2">
              <button className="filter-btn" onClick={this.run_filter}>
                Filter
              </button>
            </Col>
          </Row>
          {records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              loadData={this.loadUser}
              removeData={this.removeRecord}
              activeData={this.activeRecord}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Users"
          close={this.close_modal}
          className="drawer-form"
        >
          <FormContainer>
            <FormImageUploadGroup
              // src={`${page_api}/image/`}
              src={`${PORTAL.api_url}uploaded_images/`}
              id="image"
              name="image"
              label="User Image"
              sub_label="(Size: Below 5Mb, Format: jpeg, jpg, png, Resolution: 217x90 pixel)"
              imageCount="1"
              data={this.state}
              required
              imageUpload={this.imageUpload}
              changeUploadImageList={this.changeUploadImageList}
            />
            <Row>
              <Col span={12}>
                <FormInputGroup
                  id="name"
                  name="name"
                  label="Name"
                  placeholder="Name"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={12}>
                <FormSelectGroup
                  id="role"
                  name="role"
                  label="Role"
                  sub_label="(Optional)"
                  placeholder="Role"
                  data={this.state}
                  options={[{ role: "admin" }, { role: "super" }]}
                  option_value="role"
                  option_text="role"
                  sendValue={this.setRequest}
                />
              </Col>
            </Row>
            <Row>
              <Col span={12}>
                <FormMobileGroup
                  id="mobile"
                  name="mobile"
                  label="Mobile"
                  type="number"
                  placeholder="Mobile"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={12}>
                <FormEmailGroup
                  id="email"
                  name="email"
                  label="Email"
                  placeholder="Email"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
            </Row>
            {!edit ? (
              <Row>
                <Col span={12}>
                  <FormPasswordGroup
                    id="password"
                    name="password"
                    label="Password"
                    type="password"
                    placeholder="Password"
                    data={this.state}
                    required
                    setValidation={this.setValidation}
                    sendValue={this.setRequest}
                  />
                </Col>
                <Col span={12}>
                  <FormCPasswordGroup
                    id="cpassword"
                    name="cpassword"
                    label="Confirm Password"
                    type="password"
                    placeholder="Confirm Password"
                    data={this.state}
                    required
                    setValidation={this.setValidation}
                    sendValue={this.setRequest}
                  />
                </Col>
              </Row>
            ) : (
              ""
            )}
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editUser();
                else this.addUser();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(User);
