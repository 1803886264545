import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message, Row, Col } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { region_api } from "../../library/region_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FilterSelectGroup from "../part/Filter/FilterSelectGroup";
import {
  language_array,
  language_obj,
} from "../local_data/browser-language-codes";
import { currency_array, currency_obj } from "../local_data/currency";
import { timezone_array, timezone_obj } from "../local_data/timezones";
import { get_data } from "../server_data/get_data";
// ===============================================================================
class Region extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: region_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        region_name: { valMet: vj_val.checkLength, valLen: 1 },
        code: { valMet: vj_val.checkLength, valLen: 1 },
        iso2code: { valMet: vj_val.checkLength, valLen: 1 },
        currency: { valMet: vj_val.checkLength, valLen: 1 },
        payment_type: { valMet: vj_val.checkLength, valLen: 1 },
        timezone: { valMet: vj_val.checkLength, valLen: 1 },
        language: { valMet: vj_val.checkLength, valLen: 1 },
        order: { valMet: vj_val.checkNumber },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
      cmodal2: false,
      selectedRecord: {},
      order_no: [],
    };
  }
  set_order_no = ({ records }) => {
    const order_no = records.map((r) => r.order);
    this.setState({ order_no });
  };
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    region_name: {
      title: "Region",
      // width: 300,
      className: "region_name",
      sorter: true,
      ellipsis: true,
      order: 1,
    },
    code: {
      title: "Code",
      // width: 300,
      className: "code",
      sorter: true,
      ellipsis: true,
      order: 2,
    },
    // iso2code: {
    //   title: "Iso2Code",
    //   // width: 300,
    //   className: "iso2code",
    //   sorter: true,
    //   ellipsis: true,
    //   order: 3,
    // },
    currency: {
      title: "Currency",
      // width: 300,
      className: "currency",
      sorter: true,
      ellipsis: true,
      order: 4,
    },
    payment_type: {
      title: "Payment Method",
      // width: 300,
      className: "payment_type",
      sorter: true,
      ellipsis: true,
      order: 5,
      custom: (text, record) => this.state.payment_type2[text],
    },
    tax: {
      title: "Tax (in %)",
      // width: 300,
      className: "tax",
      sorter: true,
      ellipsis: true,
      order: 6,
    },
    timezone: {
      title: "Timezone",
      // width: 300,
      className: "timezone",
      sorter: true,
      ellipsis: true,
      order: 7,
      // custom: (text, record) => timezone_obj[text].full_name,
    },
    language: {
      title: "Language",
      // width: 300,
      className: "language",
      sorter: true,
      ellipsis: true,
      order: 8,
      custom: (text, record) => language_obj[text],
    },
    order: {
      title: "Order",
      // width: 300,
      className: "order",
      sorter: true,
      ellipsis: true,
      order: 9,
    },
    active: {
      title: "Status",
      width: 80,
      className: "active",
      sorter: true,
      ellipsis: true,
      order: 10,
      custom: (text, record) => {
        if (text)
          return <span className="text-success font-weight-bold">Active</span>;
        return <span className="text-danger font-weight-bold">Deactive</span>;
      },
    },
  };
  componentDidMount = async () => {
    const payment_type = [
      { name: "Elavon Payment Gateway", _id: "el" },
      { name: "Stripe Payment Gateway", _id: "st" },
    ];
    const { doUserLogout, Chabi } = this.state;
    const response = await get_data({
      primary: "region",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let { records } = data;
        records = add_sno(records);
        let payment_type2 = create_object({
          arr: payment_type,
          title: "name",
        });
        this.set_order_no({ records });
        this.setState({
          records,
          payment_type,
          payment_type2,
        });
      }
    } else console.log("Network Error");
  };
  addRegion = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const url = region_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    console.log("add region ", response);
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
        const { records: r } = data;
        records.push({ ...r[0] });
        records = add_sno(records);
        this.set_order_no({ records });
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };
  editRegion = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    const { request, Chabi } = this.state;
    let { records } = this.state;
    const {
      _id,
      region_name,
      code,
      iso2code,
      currency,
      payment_type,
      timezone,
      language,
      order,
      tax,
    } = request;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const url = region_api;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: {
        _id,
        region_name,
        code,
        iso2code,
        currency,
        payment_type,
        timezone,
        language,
        order,
        tax,
      },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 2000) {
        message.success("Nothing Changed");
        this.setState({
          validation: {},
          request: {},
          cmodal: false,
        });
      } else if (data.status == 200) {
        message.success("Record Updated");
        records = records.map((rec) => {
          if (rec._id == _id) {
            rec["region_name"] = region_name;
            rec["code"] = code;
            rec["iso2code"] = iso2code;
            rec["currency"] = currency;
            rec["payment_type"] = payment_type;
            rec["timezone"] = timezone;
            rec["language"] = language;
            rec["tax"] = tax;
            rec["order"] = order;
          }
          return rec;
        });
        this.set_order_no({ records });
        this.setState({
          records,
          validation: {},
          request: {},
          cmodal: false,
        });
      } else {
        const { messages } = data;
        if (messages) return alert(messages);
      }
    } else alert("Network Error !");
  };
  fastEdit = async (obj) => {
    const { field, value, record } = obj;
    let { records, doUserLogout, Chabi } = this.state;
    if (record[field] == value) return;
    const _id = record["_id"];
    const url = `${region_api}/f`;
    const response = await myAxios({
      method: "put",
      url,
      header: { Chabi },
      request: { _id, [field]: value },
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        records = records.map((r) => {
          if (r._id == _id) r[field] = value;
          return r;
        });
        this.set_order_no({ records });
        this.setState({ records });
        return true;
      } else {
        const { messages } = data;
        if (messages) {
          message.error(messages);
          return false;
        }
      }
    } else alert("Network Error !");
  };
  render() {
    let {
      records,
      edit,
      loginStatus,
      cmodal,
      cmodal2,
      request,
      filtered_records,
      selectedRecord,
      payment_type,
      order_no,
    } = this.state;
    console.log("state ", this.state);
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    return (
      <>
        <PageHeading2
          active="Region"
          page={["Website Administration", "Region"]}
        >
          <button
            className="add-btn"
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          >
            Add New
          </button>
        </PageHeading2>
        <MainArea>
          {records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              loadData={this.loadRecord}
              removeData={this.removeRecord}
              activeData={this.activeRecord}
              pagination={true}
              onRowClick={(selectedRecord) => {
                this.setState({ cmodal2: true, selectedRecord });
              }}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          placement={"right"}
          title="Add / Edit Region"
          close={this.close_modal}
          className="modal-form-region"
          // wrapClassName="region-container"
        >
          <FormContainer>
            <Row>
              <Col span={8}>
                <FormInputGroup
                  id="region_name"
                  name="region_name"
                  label="Region Name"
                  placeholder="Region"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={8}>
                <FormInputGroup
                  id="code"
                  name="code"
                  label="Code"
                  placeholder="Code"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={8}>
                <FormInputGroup
                  id="iso2code"
                  name="iso2code"
                  label="Iso2Code"
                  placeholder="Iso2Code"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <FormSelectGroup
                  id="currency"
                  name="currency"
                  label="Currency"
                  placeholder="Currency"
                  data={this.state}
                  required
                  options={currency_array}
                  option_value="code"
                  option_text="full_name"
                  // valMet={checkUuid}
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={8}>
                <FormSelectGroup
                  id="payment_type"
                  name="payment_type"
                  label="Payment Type"
                  placeholder="Payment Type"
                  data={this.state}
                  required
                  options={payment_type}
                  option_value="_id"
                  option_text="name"
                  // valMet={checkUuid}
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={8}>
                <FormInputGroup
                  type="number"
                  id="tax"
                  name="tax"
                  label="Tax(in %)"
                  sub_label="(Optional)"
                  placeholder="Tax"
                  data={this.state}
                  sendValue={this.setRequest}
                />
              </Col>
            </Row>
            <Row>
              <Col span={8}>
                <FormSelectGroup
                  id="timezone"
                  name="timezone"
                  label="Timezone"
                  placeholder="Timezone"
                  data={this.state}
                  required
                  options={timezone_array}
                  option_value="name"
                  option_text="full_name"
                  // valMet={checkUuid}
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={8}>
                <FormSelectGroup
                  id="language"
                  name="language"
                  label="Language"
                  placeholder="Language"
                  data={this.state}
                  required
                  options={language_array}
                  option_value="language_code"
                  option_text="country_name"
                  // valMet={checkUuid}
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                />
              </Col>
              <Col span={8}>
                <FormInputGroup
                  type="number"
                  id="order"
                  name="order"
                  label="Order"
                  placeholder="Order"
                  data={this.state}
                  required
                  setValidation={this.setValidation}
                  sendValue={this.setRequest}
                  onBlur={(e) => {
                    const order = parseInt(e.target.value);
                    if (order_no.includes(order)) {
                      window.alert("Order must be unique");
                      this.setRequest({ name: "order", value: "" });
                    }
                  }}
                />
              </Col>
            </Row>
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editRegion();
                else this.addRegion();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Region);
