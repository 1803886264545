import React, { Component } from "react";
import { myAxios } from "../../library/networkFunction";
import { PORTAL } from "../../currrent_info";
import { end_points } from "../../end_points.js";
// ===============================================================================
class ApiTest extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = async () => {
    console.log(end_points)
    const ignore_open_path = [
      "/home",
      "/front/home/",
      "/front/oev/:_id",
      "/front/lev/:_id",
      "/front/courses/",
      "/front/single_course/:slug",
      "/front/contact_us/",
      "/front/noba/:reset_password",
      "/front/single_training/:slug",
      "/front/single_consulting/:slug",
      "/front/single_auditing/:slug",
      "/front/organization_registration/",
      "/front/dashboard",
      "/front/consulting/",
      "/front/auditing/",
      "/front/industries/",
      "/front/about_us/",
      "/front/registration/",
      "/front/consultant_registration/",
      "/front/training/",
      "/consultant/login",
      "/consultant/ce",
      "/consultant/cm",
      "/consultant/registration",
      "/learners/login",
      "/learners/cm",
      "/learners/ce",
      "/learners/reset",
      "/learners/forgot",
      "/learners/registration",
      "/organization/login",
      "/organization/ce",
      "/organization/cm",
      "/organization/registration",
      "/organization/forgot",
      "/organization/reset",
      "/user/login",
      "/enquiry/training",
      "/enquiry/course",
      "/enquiry/contact",
    ];
    const ignore_path = [...ignore_open_path];
    end_points.forEach((element) => {
      const { path, methods, middlewares } = element;
      if (ignore_path.includes(path)) return;
      methods.forEach(async (method) => {
        const p = path.split(":");
        if (p.length > 1) {
          // =============================================================
          // =============================================================
          // const url = PORTAL.test_url + p[0];
          // const response = await myAxios({ method: method.toLowerCase(), url });
          // const { status, data } = response;
          // if (status == 200) {
          //   const { status, messages } = data;
          //   if (status != 420) {
          //     console.log(data);
          //     console.log(path);
          //     console.log(method);
          //   }
          //   console.log("------------------------------------------");
          // =============================================================
          // =============================================================
          const url = PORTAL.test_url + p[0] + "564654654654654654654654";
          const response = await myAxios({ method: method.toLowerCase(), url });
          const { status, data } = response;
          if (status == 200) {
            const { status, messages } = data;
            if (status != 420) {
              console.log(data);
              console.log(path);
              console.log(method);
            }
            console.log("------------------------------------------");
            // =============================================================
            // =============================================================
          }
          // else {
          // =============================================================
          // =============================================================
          //   const url = PORTAL.test_url + path;
          //   const response = await myAxios({ method: method.toLowerCase(), url });
          //   const { status, data } = response;
          //   if (status == 200) {
          //     const { status, messages } = data;
          //     if (status != 420) {
          //       console.log(data);
          //       console.log(path);
          //       console.log(method);
          //     }
          //     console.log("------------------------------------------");
          // =============================================================
          // =============================================================
          //   }
        }
      });
    });
  };
  render() {
    return "";
  }
}
export default ApiTest;
