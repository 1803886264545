const PORTAL = {
  name: "LMS",
  title: "LMS",
  // basename: "/",
  // api_url: "https://apiv2.kelmacgroup.com/",
  // domain: "https://www.kelmacgroup.com/",
  // api_url: "http://localhost:5555/",
  // domain: "http://localhost:3000/",

  api_url: "https://stagingnew-api.kelmacgroup.com/",
  domain: "https://staging.kelmacgroup.com/",
};

export { PORTAL };