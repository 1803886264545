import ApiTest from "./component/pages/ApiTest";
import Dashboard from "./component/pages/Dashboard";
import Logout from "./component/pages/Logout";
import NotFound from "./component/pages/NotFound";
import Clients from "./component/pages/Clients";
import Login from "./component/pages/Login";
import Testimonial from "./component/pages/Testimonial";
import ChangePassword from "./component/pages/ChangePassword";
import Accreditation from "./component/pages/Accreditation";
import Auditing_scheme from "./component/pages/Auditing_scheme";
import Auditing_service_type_point from "./component/pages/Auditing_service_type_point";
import Auditing_type from "./component/pages/Auditing_type";
import Auditing_service from "./component/pages/Auditing_service";
import Banner from "./component/pages/Banner";
import Compentency_units from "./component/pages/Compentency_units";
import Consultants from "./component/pages/Consultants";
import Consulting_scheme from "./component/pages/Consulting_scheme";
import Consulting_service_type_point from "./component/pages/Consulting_service_type_point";
import Consulting_type from "./component/pages/Consulting_type";
import Consulting_service from "./component/pages/Consulting_service";
import Contact_details from "./component/pages/Contact_details";
import Content from "./component/pages/Content";
import Course from "./component/pages/Course";
import Course_category from "./component/pages/Course_category";
import Course_type from "./component/pages/Course_type";
import Delivery_method from "./component/pages/Delivery_method";
import Evaluation from "./component/pages/Evaluation";
import Evaluation_question from "./component/pages/Evaluation_question";
import Evaluation_section from "./component/pages/Evaluation_section";
import Exam from "./component/pages/Exam";
import Exam_section from "./component/pages/Exam_section";
import Faq from "./component/pages/Faq";
import Insight from "./component/pages/Insight";
import Insight_category from "./component/pages/Insight_category";
import Language from "./component/pages/Language";
import Lca from "./component/pages/Lca";
import Learners from "./component/pages/Learners";
import Learning_scheme from "./component/pages/Learning_scheme";
import Location from "./component/pages/Location";
import Mapping from "./component/pages/Mapping";
import Message from "./component/pages/Message";
import Newsletter from "./component/pages/Newsletter";
import News_update from "./component/pages/News_update";
import Organization from "./component/pages/Organization";
import Product_fam_points from "./component/pages/Product_fam_points";
import Payment_orders from "./component/pages/Payment_orders";
import Review_question from "./component/pages/Review_question";
import Region from "./component/pages/Region";
import Seo from "./component/pages/Seo";
import Settings from "./component/pages/Settings";
import Social_media from "./component/pages/Social_media";
import Training from "./component/pages/Training";
import Team from "./component/pages/Team";
import Team_group from "./component/pages/Team_group";
import User from "./component/pages/User";
import Venue from "./component/pages/Venue";
import Corporate_brochure from "./component/pages/Corporate_brochure";
import Nda from "./component/pages/Nda";
import Training_enquiry from "./component/pages/Training_enquiry";
import Course_enquiry from "./component/pages/Course_enquiry";
import Contact_enquiry from "./component/pages/Contact_enquiry";
import Industries from "./component/pages/Industries";
import Ticket from "./component/pages/Ticket";
import Coupon_learner from "./component/pages/Coupon_learner";
import Coupon_organization from "./component/pages/Coupon_organization";
import Career from "./component/pages/Career";
import Training365 from "./component/pages/Training365";
import Bulk_order from "./component/pages/Bulk_order";
import Training_bundal from "./component/pages/Training_bundal";
import Purchase_order from "./component/pages/Purchase_order";
let urls = {
  APITEST: {
    to: () => `/apitest`,
    path: "/apitest",
    component: ApiTest,
    text: "ApiTest",
  },
  ACCREDITATION: {
    to: () => `/accreditation`,
    path: "/accreditation",
    component: Accreditation,
    text: "Accreditation",
  },
  AUDITING_SCHEME: {
    to: () => `/auditing_scheme`,
    path: "/auditing_scheme",
    component: Auditing_scheme,
    text: "Scheme",
  },
  AUDITING_SERVICE_TYPE_POINT: {
    to: () => `/auditing_service_type_point`,
    path: "/auditing_service_type_point",
    component: Auditing_service_type_point,
    text: "Service Type Point",
  },
  AUDITING_TYPE: {
    to: () => `/auditing_type`,
    path: "/auditing_type",
    component: Auditing_type,
    text: "Auditing Type",
  },
  AUDITING_SERVICE: {
    to: () => `/auditing_service`,
    path: "/auditing_service",
    component: Auditing_service,
    text: "Auditing Service",
  },
  BANNER: {
    to: () => `/banner`,
    path: "/banner",
    component: Banner,
    text: "Banner",
  },
  CLIENTS: {
    to: () => `/clients`,
    path: "/clients",
    component: Clients,
    text: "Clients",
  },
  COMPENTENCY_UNITS: {
    to: () => `/compentency_units`,
    path: "/compentency_units",
    component: Compentency_units,
    text: "Compentency Units",
  },
  CONSULTANTS: {
    to: () => `/consultants`,
    path: "/consultants",
    component: Consultants,
    text: "Consultants",
  },
  CONSULTING_SCHEME: {
    to: () => `/consulting_scheme`,
    path: "/consulting_scheme",
    component: Consulting_scheme,
    text: "Scheme",
  },
  CONSULTING_SERVICE_TYPE_POINT: {
    to: () => `/consulting_service_type_point`,
    path: "/consulting_service_type_point",
    component: Consulting_service_type_point,
    text: "Service Type Point",
  },
  CONSULTING_TYPE: {
    to: () => `/consulting_type`,
    path: "/consulting_type",
    component: Consulting_type,
    text: "Consulting Type",
  },
  CONSULTING_SERVICE: {
    to: () => `/consulting_service`,
    path: "/consulting_service",
    component: Consulting_service,
    text: "Consulting Service",
  },
  CONTACT_DETAILS: {
    to: () => `/contact_details`,
    path: "/contact_details",
    component: Contact_details,
    text: "Contact Details",
  },
  CONTENT: {
    to: () => `/content`,
    path: "/content",
    component: Content,
    text: "Main Content",
  },
  COURSE: {
    to: () => `/course`,
    path: "/course",
    component: Course,
    text: "Course",
  },
  CAREER: {
    to: () => `/career`,
    path: "/career",
    component: Career,
    text: "Career",
  },
  COURSE_CATEGORY: {
    to: () => `/course_category`,
    path: "/course_category",
    component: Course_category,
    text: "Category",
  },
  COURSE_TYPE: {
    to: () => `/course_type`,
    path: "/course_type",
    component: Course_type,
    text: "Course Type",
  },
  CORPORATE_BROCHURE: {
    to: () => `/corporate_brochure`,
    path: "/corporate_brochure",
    component: Corporate_brochure,
    text: "Corporate Brochure",
  },
  DELIVERY_METHOD: {
    to: () => `/delivery_method`,
    path: "/delivery_method",
    component: Delivery_method,
    text: "Delivery Method",
  },
  EVALUATION: {
    to: () => `/evaluation`,
    path: "/evaluation",
    component: Evaluation,
    text: "Evaluation",
  },
  EVALUATION_QUESTION: {
    to: () => `/evaluation_question`,
    path: "/evaluation_question",
    component: Evaluation_question,
    text: "Evaluation Question",
  },
  EVALUATION_SECTION: {
    to: () => `/evaluation_section`,
    path: "/evaluation_section",
    component: Evaluation_section,
    text: "Evaluation Section",
  },
  EXAM: {
    to: () => `/exam`,
    path: "/exam",
    component: Exam,
    text: "Exam",
  },
  COUPON_LEARNER: {
    to: () => `/coupon_learner`,
    path: "/coupon_learner",
    component: Coupon_learner,
    text: "Coupon Learner",
  },
  COUPON_ORGANIZATION: {
    to: () => `/coupon_organization`,
    path: "/coupon_organization",
    component: Coupon_organization,
    text: "Coupon Organization",
  },
  TICKET: {
    to: () => `/ticket`,
    path: "/ticket",
    component: Ticket,
    text: "Ticket",
  },
  EXAM_SECTION: {
    to: () => `/exam_section`,
    path: "/exam_section",
    component: Exam_section,
    text: "Exam Section",
  },
  FAQ: {
    to: () => `/faq`,
    path: "/faq",
    component: Faq,
    text: "Faq",
  },
  INDUSTRIES: {
    to: () => `/industries`,
    path: "/industries",
    component: Industries,
    text: "Industries",
  },
  INSIGHT: {
    to: () => `/insight`,
    path: "/insight",
    component: Insight,
    text: "Insight",
  },
  INSIGHT_CATEGORY: {
    to: () => `/insight_category`,
    path: "/insight_category",
    component: Insight_category,
    text: "Category",
  },
  PAYMENT_ORDERS: {
    to: () => `/payment_orders`,
    path: "/payment_orders",
    component: Payment_orders,
    text: "Payments Orders",
  },
  BULK_ORDER: {
    to: () => `/bulk_order`,
    path: "/bulk_order",
    component: Bulk_order,
    text: "Bulk Orders",
  },
  PURCHASE_ORDER: {
    to: () => `/purchase_order`,
    path: "/purchase_order",
    component: Purchase_order,
    text: "purchase Orders",
  },
  LANGUAGE: {
    to: () => `/language`,
    path: "/language",
    component: Language,
    text: "Language",
  },
  LCA: {
    to: () => `/lca`,
    path: "/lca",
    component: Lca,
    text: "Lca",
  },
  LEARNERS: {
    to: () => `/learners`,
    path: "/learners",
    component: Learners,
    text: "Learners",
  },
  LEARNING_SCHEME: {
    to: () => `/learning_scheme`,
    path: "/learning_scheme",
    component: Learning_scheme,
    text: "Scheme",
  },
  LOCATION: {
    to: () => `/location`,
    path: "/location",
    component: Location,
    text: "Location",
  },
  MAPPING: {
    to: () => `/mapping`,
    path: "/mapping",
    component: Mapping,
    text: "Mapping",
  },
  MESSAGE: {
    to: () => `/message`,
    path: "/message",
    component: Message,
    text: "Message",
  },
  NEWSLETTER: {
    to: () => `/newsletter`,
    path: "/newsletter",
    component: Newsletter,
    text: "Newsletter",
  },
  NEWSUPDATE: {
    to: () => `/news_update`,
    path: "/news_update",
    component: News_update,
    text: "News & Update",
  },
  NDA: {
    to: () => `/nda`,
    path: "/nda",
    component: Nda,
    text: "Nda",
  },
  ORGANIZATION: {
    to: () => `/organization`,
    path: "/organization",
    component: Organization,
    text: "Organization",
  },
  TEAM: {
    to: () => `/team`,
    path: "/team",
    component: Team,
    text: "Our Team",
  },
  PRODUCT_FAM_POINTS: {
    to: () => `/product_fam_points`,
    path: "/product_fam_points",
    component: Product_fam_points,
    text: "Product Fam Points",
  },
  REVIEW_QUESTION: {
    to: () => `/review_question`,
    path: "/review_question",
    component: Review_question,
    text: "Review Question",
  },
  REGION: {
    to: () => `/region`,
    path: "/region",
    component: Region,
    text: "Region",
  },
  SEO: {
    to: () => `/seo`,
    path: "/seo",
    component: Seo,
    text: "Seo",
  },
  SETTINGS: {
    to: () => `/settings`,
    path: "/settings",
    component: Settings,
    text: "Settings",
  },
  SOCIAL_MEDIA: {
    to: () => `/social_media`,
    path: "/social_media",
    component: Social_media,
    text: "Social Media",
  },
  TEAM_GROUP: {
    to: () => `/team_group`,
    path: "/team_group",
    component: Team_group,
    text: "Team Group",
  },
  TRAINING: {
    to: () => `/training`,
    path: "/training",
    component: Training,
    text: "Training",
  },
  TRAINING_BUNDAL: {
    to: () => `/training_bundal`,
    path: "/training_bundal",
    component: Training_bundal,
    text: "Training Bundal",
  },
  TRAINING365: {
    to: () => `/training365`,
    path: "/training365",
    component: Training365,
    text: "Training365",
  },
  TRAINING_ENQUIRY: {
    to: () => `/training_enquiry`,
    path: "/training_enquiry",
    component: Training_enquiry,
    text: "Training",
  },
  COURSE_ENQUIRY: {
    to: () => `/course_enquiry`,
    path: "/course_enquiry",
    component: Course_enquiry,
    text: "Course",
  },
  CONTACT_ENQUIRY: {
    to: () => `/contact_enquiry`,
    path: "/contact_enquiry",
    component: Contact_enquiry,
    text: "Contact Us",
  },
  TEAM: {
    to: () => `/team`,
    path: "/team",
    component: Team,
    text: "Team",
  },
  USER: {
    to: () => `/user`,
    path: "/user",
    component: User,
    text: "Users",
  },
  VENUE: {
    to: () => `/venue`,
    path: "/venue",
    component: Venue,
    text: "Venue",
  },
  TESTIMONIAL: {
    to: () => `/testimonial`,
    path: "/testimonial",
    component: Testimonial,
    text: "Testimonial",
  },
  DASHBOARD: {
    to: () => `/dashboard`,
    path: "/dashboard",
    component: Dashboard,
    text: "Dashboard",
  },
  LOGIN: {
    to: () => `/`,
    path: "/",
    component: Login,
    text: "Login",
  },
  LOGOUT: {
    to: () => `/logout`,
    path: "/logout",
    component: Logout,
    text: "Logout",
  },
  NOT_FOUND: {
    to: () => ``,
    path: "",
    component: NotFound,
  },
  CHANGE_PASSWORD: {
    to: () => `/change-password`,
    path: "/change-password",
    component: ChangePassword,
    text: "Change Password",
  },
};
export default urls;
