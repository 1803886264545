import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { pre_order_api } from "../../library/pre_order_api";
import { learners_api } from "../../library/learners_api";
import { organization_api } from "../../library/organization_api";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import CustomOffCanvas from "../part/CustomOffCanvas";
import { get_data } from "../server_data/get_data";
import { countryListAlpha2 } from "../local_data/variousCountryListFormats";
import { currency_obj } from "../local_data/currency";
// ===============================================================================
class Purchase_order extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: pre_order_api,
      filter: {},
      records: [],
      request: {},
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      uploadImageList: [],
      cmodal: false,
      coffcanvas: false,
      selectedRecord: {},
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    user_id: {
      title: "Learner",
      // width: 300,
      sorter: true,
      ellipsis: true,
      order: 2,
      custom: (text, record) => this.state.learner2[text],
    },
    payment_ref: {
      title: "Payment Ref",
      width: 300,
      order: 1,
    },
    total_amount: {
      title: "Amount",
      // width: 300,
      sorter: true,
      ellipsis: true,
      order: 4,
      custom: (text, record) => {
        const { currency } = record;
        console.log("currency ", currency);
        return (
          <>
            {/* {record.currency ? currency_obj[record.currency] : ""} */}
            {currency ? currency_obj[currency].symbol_native : ""}
            {text}
          </>
        );
      },
    },
    create_at: {
      title: "Date",
      // width: 300,
      sorter: true,
      ellipsis: true,
      order: 6,
    },
    status: {
      title: "Status",
      // width: 300,
      sorter: true,
      ellipsis: true,
      order: 7,
      custom: (text, record) => {
        if (text == "success")
          return <span className="text-success font-weight-bold">{text}</span>;
        return <span className="text-danger font-weight-bold">{text}</span>;
      },
    },
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
    const response = await get_data({
      others: [
        "region",
        "organization",
        "course",
        "language",
        "delivery_method",
      ],
      primary: "purchase_order",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let {
          region,
          records,
          organization,
          course,
          language,
          delivery_method,
        } = data;
        const learner_ids = records.map(({ user_id }) => user_id);
        const url = learners_api + "/learner_list";
        const learner_ids_response = await myAxios({
          method: "post",
          url,
          request: { learner_ids },
          header: { Chabi },
        });
        records = add_sno(records);
        let learner2 = create_object({
          arr: learner_ids_response.data.records,
          title: "first_name",
        });
        let region2 = create_object({
          arr: region,
          title: "region_name",
        });
        let organization2 = create_object({
          arr: organization,
          title: "organization_name",
        });
        let course2 = create_object({
          arr: course,
          title: "main_head",
        });
        let language2 = create_object({
          arr: language,
          title: "language",
        });
        let delivery_method2 = create_object({
          arr: delivery_method,
          title: "title",
        });
        this.setState({
          records,
          region,
          learner2,
          region2,
          organization2,
          course2,
          language2,
          delivery_method2,
        });
      }
    } else console.log("Network Error");
  };
  render() {
    console.log("Pre Order State ", this.state);
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
      selectedRecord,
      coffcanvas,
      region2,
      organization2,
      course2,
      language2,
      delivery_method2,
    } = this.state;
    console.log("selectedRecord ", selectedRecord);
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    columns.push({
      title: "Details",
      className: "details",
      // width: 70,
      dataIndex: "details",
      render: (text, selectedRecord) => {
        const { create_by, create_role } = selectedRecord;

        return (
          <span
            onClick={async () => {
              const { Chabi } = this.state;
              let user = {};
              let url;
              if (create_role == "learner") url = learners_api + "/get";
              else if (create_role == "organization")
                url = organization_api + "/get";

              const response = await myAxios({
                method: "post",
                url,
                request: { _id: create_by },
                header: { Chabi },
              });
              if (response.status == 200) user = response.data.records[0];

              this.setState({
                coffcanvas: true,
                selectedRecord: { ...selectedRecord, user },
              });
            }}
            className="btn btn-warning btn-sm"
          >
            Details
          </span>
        );
      },
    });
    return (
      <>
        <PageHeading2
          active="Payment Orders"
          page={["Orders", "Payment Orders"]}
        ></PageHeading2>
        <MainArea>
          {/* <Row style={{ marginTop: "20px" }}>
            <Col span={8}>
              <FilterSelectGroup
                id="active"
                name="active"
                label="Status"
                placeholder="Status"
                options={[
                  { text: "Active", value: "1" },
                  { text: "Deactive", value: "0" },
                ]}
                option_value="value"
                option_text="text"
                sendValue={this.set_filter}
              />
            </Col>
          </Row>
          <Row>
            <Col span={2} className="m-2">
              <button className="filter-btn" onClick={this.run_filter}>
                Filter
              </button>
            </Col>
          </Row> */}
          {records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        {coffcanvas ? (
          <CustomOffCanvas
            key={selectedRecord._id}
            status={coffcanvas}
            placement={"right"}
            title="Order Details"
            close={() => {
              this.setState({ coffcanvas: false, selectedRecord: {} });
            }}
            className="drawer-form-order"
          >
            <div className="p-2">
              <p className="info-main-heading">Payment Order Details</p>
              <div className="row mt-1 mb-1">
                <div className="col-3 text-left">
                  <span className="d-block info-heading">Booked On</span>
                  <span className="d-block info-value">
                    {selectedRecord.create_at}
                  </span>
                </div>
                <div className="col-3">
                  <span className="d-block info-heading">Amount</span>
                  <span className="d-block info-value">
                    {selectedRecord.total_amount}
                  </span>
                </div>
                <div className="col-2 text-left">
                  <span className="d-block info-heading">Status</span>
                  <span className="d-block info-value">
                    {selectedRecord.status}
                  </span>
                </div>
                <div className="col-2">
                  <span className="d-block info-heading">Discount</span>
                  <span className="d-block info-value">
                    {selectedRecord.discount}
                  </span>
                </div>
                <div className="col-2">
                  <span className="d-block info-heading">Tax</span>
                  <span className="d-block info-value">
                    {selectedRecord.tax}
                  </span>
                </div>
              </div>
              <div className="row mt-1 mb-1">
                <div className="col-6">
                  <span className="d-block info-heading">
                    Payment Refference
                  </span>
                  <span className="d-block info-value">
                    {selectedRecord.payment_ref}
                  </span>
                </div>
                <div className="col-6">
                  <span className="d-block info-heading">
                    Gateway Refference
                  </span>
                  <span className="d-block info-value">
                    {selectedRecord.gatway_ref}
                  </span>
                </div>
              </div>
            </div>
            {selectedRecord.learner ? (
              <div className="p-2 bg-light">
                <p className="info-main-heading">Learner Details</p>
                {[selectedRecord.learner].map((learner) => {
                  const {
                    first_name,
                    last_name,
                    email,
                    organization,
                    country,
                    region,
                    mobile,
                  } = learner;
                  return (
                    <div className="row mb-1">
                      <div className="col-2 text-left">
                        <span className="d-block info-heading">
                          Learner Name
                        </span>
                        <span className="d-block info-value">
                          {first_name} {` `}
                          {last_name}
                        </span>
                      </div>
                      <div className="col-2">
                        <span className="d-block info-heading">Email</span>
                        <span className="d-block info-value">{email}</span>
                      </div>
                      <div className="col-2">
                        <span className="d-block info-heading">Mobile</span>
                        <span className="d-block info-value">{mobile}</span>
                      </div>
                      <div className="col-2">
                        <span className="d-block info-heading">
                          Organization
                        </span>
                        <span className="d-block info-value">
                          {organization2[organization]}
                        </span>
                      </div>
                      <div className="col-2">
                        <span className="d-block info-heading">Country</span>
                        <span className="d-block info-value">
                          {countryListAlpha2[country]}
                        </span>
                      </div>
                      <div className="col-2 text-left">
                        <span className="d-block info-heading">Region</span>
                        <span className="d-block info-value">
                          {region2[region]}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              ""
            )}
            <div className="p-2">
              <p className="info-main-heading">Training Details</p>
              {/* {selectedRecord.items.map(({ product }) => {
                const {
                  delivery_method,
                  languages,
                  material_fees,
                  start_date,
                  start_time,
                  timezone,
                  duration,
                  course_name,
                  course_fees,
                  certificate_fees,
                } = product;
                return (
                  <div
                    style={{
                      background: "#ffffff",
                      marginBottom: "20px",
                      padding: "10px",
                    }}
                  >
                    <div className="row">
                      <div className="col-6 text-left">
                        <span className="d-block info-heading">Course</span>
                        <span className="d-block info-value">
                          {course2[course_name]}
                        </span>
                      </div>
                      <div className="col-2 text-left">
                        <span className="d-block info-heading">
                          Course Fees
                        </span>
                        <span className="d-block info-value">
                          {course_fees}
                        </span>
                      </div>
                      <div className="col-2">
                        <span className="d-block info-heading">
                          Certificate Fees
                        </span>
                        <span className="d-block info-value">
                          {certificate_fees}
                        </span>
                      </div>
                      <div className="col-2">
                        <span className="d-block info-heading">
                          Material Fees
                        </span>
                        <span className="d-block info-value">
                          {material_fees}
                        </span>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-3">
                        <span className="d-block info-heading">
                          Delivery Method
                        </span>
                        <span className="d-block info-value">
                          {delivery_method
                            .split(",")
                            .map((dm) => {
                              return delivery_method2[dm];
                            })
                            .toString(",")}
                        </span>
                      </div>
                      <div className="col-3">
                        <span className="d-block info-heading">Languages</span>
                        <span className="d-block info-value">
                          {languages
                            .split(",")
                            .map((lm) => {
                              return language2[lm];
                            })
                            .toString(",")}
                        </span>
                      </div>
                      <div className="col-2 text-left">
                        <span className="d-block info-heading">Date</span>
                        <span className="d-block info-value">{start_date}</span>
                      </div>
                      <div className="col-2">
                        <span className="d-block info-heading">Time</span>
                        <span className="d-block info-value">{start_time}</span>
                      </div>
                      <div className="col-2">
                        <span className="d-block info-heading">Timezone</span>
                        <span className="d-block info-value">{timezone}</span>
                      </div>
                    </div>
                    <div className="row"></div>
                  </div>
                );
              })} */}
            </div>
          </CustomOffCanvas>
        ) : (
          ""
        )}
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Purchase_order);
