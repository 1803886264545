import React from "react";
import { Navigate } from "react-router";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
import { myAxios } from "../../library/networkFunction";
// ===============================================================================
import FormInputGroup from "../part/Form/FormInputGroup";
import FormParent from "./FormParent";
import { user_api } from "../../library/user_api";
import { userLogin } from "../../Action/userAction";
import { connect } from "react-redux";
// ===============================================================================
class ChangePassword extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: user_api,
      dis_mob_sec: true,
      request: {},
      validation: {},
      validation_fields: ["password", "cpassword", "old_password"],
      doUserLogin: props.doUserLogin,
      loginStatus: props.loginStatus,
    };
  }
  change = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    let state = this.state;
    const { request, doUserLogout, Chabi } = state;
    const url = user_api;
    const response = await myAxios({
      method: "post",
     url,
      header: { Chabi },
      request,
     });
    console.log(response);
    if (response.status == 200) {
      const { data } = response;
      if(data.status == 420)
  this.setLogout();
      const { error, messages, status, records } = data;
      if (messages) return alert(messages);
      if (status == 200) {
      } else console.log("something wrong 2");
    } else alert("Network Error !");
  };
  render() {
    console.log("ChangePassword State ", this.state);
    // console.log("login props", this.props);
    const { error_message, loginStatus } = this.state;
    if (loginStatus != true) return <Navigate  to="/" />;
    return (
      <>
        <div className="row mt-5">
          <div className=" col-lg-2 col-sm-12 offset-lg-5 bg-light rounded">
            <div className="row">
              <div className="col-12 pt-3">
                <h2 className="text-center">Change Password</h2>
              </div>
            </div>
            <>
              {/* <div className="row mt-4 ">
                    <div className="col text-center font-weight-bold">
                      Mobile Number
                    </div>
                  </div> */}
              <FormInputGroup
                groupClassName="mt-2"
                // labelClassName="mt-5"
                inputClassName="form-control"
                id="old_password"
                type="password"
                name="old_password"
                data={this.state}required
                placeholder="Old Password"
                valMet={vj_val.checkPassword}
                setValidation={this.setValidation}
                sendValue={this.setRequest}
              />
              <FormInputGroup
                groupClassName="mt-2"
                // labelClassName="mt-5"
                inputClassName="form-control"
                id="password"
                type="password"
                name="password"
                data={this.state}required
                placeholder="Password"
                valMet={vj_val.checkPassword}
                setValidation={this.setValidation}
                sendValue={this.setRequest}
              />
              <FormInputGroup
                groupClassName="mt-2"
                // labelClassName="mt-5"
                inputClassName="form-control"
                id="cpassword"
                type="password"
                name="cpassword"
                data={this.state}required
                placeholder="Confirm Password"
                valMet={vj_val.checkPassword}
                setValidation={this.setValidation}
                sendValue={this.setRequest}
              />
              <div className="row mt-4 mb-4">
                {error_message && error_message.length ? (
                  <>
                    <div className="col-12 text-center m-2 text-danger">
                      {error_message}
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="col-12 text-center">
                  <a
                    className="btn btn-block btn-success form-button"
                    onClick={() => {
                      this.change();
                    }}
                  >
                    Change Password
                  </a>
                </div>
              </div>
            </>
          </div>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return { loginStatus: state.loginStatus };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogin: (obj) => {
      dispatch(userLogin(obj));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
// export default ChangePassword;
