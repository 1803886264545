import React from "react";
import { connect } from "react-redux";
import { Navigate } from "react-router";
import { userLogout } from "../../Action/userAction";
import { message } from "antd";
// ===============================================================================
import CustomModal from "../part/CustomModal";
import FormContainer from "../part/Form/FormContainer";
// ===============================================================================
import { message_api } from "../../library/message_api";
import { back_api } from "../../library/back_api";
// ===============================================================================
import { vj_val } from "../../library/validation_function";
// ===============================================================================
import MainArea from "../part/MainArea";
import FormButton from "../part/Form/FormButton";
import FormInputGroup from "../part/Form/FormInputGroup";
import FormTextareaGroup from "../part/Form/FormTextareaGroup";
import FormParent from "./FormParent";
import { myAxios } from "../../library/networkFunction";
import MyDataTable, { createColumn } from "../part/Display/MyDataTable";
import PageHeading2 from "../part/PageHeading2";
import { add_sno, create_object } from "../../library/object_functions";
import MyEmpty from "../part/MyEmpty/MyEmpty";
import FormMultiSelectGroup from "../part/Form/FormMultiSelectGroup";
import FormSelectGroup from "../part/Form/FormSelectGroup";
import FormCheckboxSingleGroup from "../part/Form/FormCheckboxSingleGroup";
import { get_data } from "../server_data/get_data";
// ===============================================================================
class Message extends FormParent {
  constructor(props) {
    super(props);
    this.state = {
      page_api: message_api,
      filter: {},
      records: [],
      request: {},
      validation: {},
      validation_info: {
        type: { valMet: vj_val.checkLength, valLen: 1 },
        subject: { valMet: vj_val.checkLength, valLen: 1 },
        message: { valMet: vj_val.checkLength, valLen: 1 },
      },
      Chabi: props.Chabi,
      loggedUser: props.loggedUser,
      loginStatus: props.loginStatus,
      doUserLogout: props.doUserLogout,
      cmodal: false,
    };
  }
  displayFields = {
    sno: {
      title: "SN",
      width: 70,
      defaultSortOrder: "descend",
      sorter: true,
      order: 0,
    },
    type: {
      title: "Type",
      // width: 300,
      className: "type",
      sorter: true,
      ellipsis: true,
      order: 1,
    },
    subject: {
      title: "Subject",
      // width: 300,
      className: "subject",
      sorter: true,
      ellipsis: true,
      order: 2,
    },
    message: {
      title: "Message",
      // width: 300,
      className: "message",
      sorter: true,
      ellipsis: true,
      order: 3,
    },
  };
  componentDidMount = async () => {
    const { doUserLogout, Chabi } = this.state;
    const response = await get_data({
      others: ["learner", "consultant"],
      primary: "message",
      Chabi,
    });
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        let { learner, consultant, records } = data;
        records = add_sno(records);
        let consultant2 = create_object({
          arr: consultant,
          title: "consultant_name",
        });
        let learner2 = create_object({
          arr: learner,
          title: "first_name",
        });
        this.setState({
          learner,
          learner2,
          consultant,
          consultant2,
          records,
        });
      }
    } else console.log("Network Error");
    // const url = back_api + "/message";
    // const response = await myAxios({ method: "post", url, header: { Chabi } });
    // if (response.status == 200) {
    //   const { data } = response;
    //   if (data.status == 420) {
    //     this.setState({
    //       loggedUser: undefined,
    //       loginStatus: undefined,
    //     });
    //     doUserLogout();
    //   }
    //   if (data.status == 200) {
    //     let { records } = data;
    //     records = add_sno(records);
    //     this.setState({ ...data, records });
    //   }
    // } else console.log("Network Error");
  };
  addMessage = async () => {
    this.setState({ showErr: true });
    const val_result = this.checkValidation();
    if (!val_result) return false;
    // --------------------------------------------------------------------------------
    // --------------------------------------------------------------------------------
    const { request, Chabi } = this.state;
    let { records } = this.state;
    if (request.all && request.all.length) request["all"] = request.all[0];
    const url = message_api;
    const response = await myAxios({
      method: "post",
      url,
      header: { Chabi },
      request,
    });
    console.log("add message ", response);
    if (response.status == 200) {
      const { data } = response;
      if (data.status == 420) this.setLogout();
      else if (data.status == 200) {
        message.success("Record Saved");
        const { records: r } = data;
        records.push({ ...r[0] });
        records = add_sno(records);
        this.setState({
          records,
          request: {},
          validation: {},
          uploadImageList: [],
          cmodal: false,
        });
      } else {
        const { error, message } = data;
        if (message) return console.log(message);
      }
    } else alert("Network Error !");
  };
  // editMessage = async () => {
  //   this.setState({ showErr: true });
  //   const val_result = this.checkValidation();
  //   if (!val_result) return false;
  //       const { request, Chabi } = this.state;
  //   let { records } = this.state;
  //   const { type, _id, subject, message } = request;
  //   // --------------------------------------------------------------------------------
  //   // --------------------------------------------------------------------------------
  //   const url = message_api;
  //   const response = await myAxios({
  //     method: "put",
  //     url,
  //     request: { _id, type, subject },
  //   });
  //   if (response.status == 200) {
  //     const { data } = response;
  //     if (data.status == 420) {
  //       this.setState({
  //         loggedUser: undefined,
  //         loginStatus: undefined,
  //       });
  //       doUserLogout();
  //     } else if (data.status == 2000) {
  //       message.success("Nothing Changed");
  //       this.setState({
  //         validation: {},
  //         request: {},
  //         cmodal: false    ,
  //       });
  //     } else if (data.status == 200) {
  //       message.success("Record Updated");
  //       records = records.map((rec) => {
  //         if (rec._id == _id) {
  //           rec["type"] = type;
  //           rec["subject"] = subject;
  //           rec["message"] = message;
  //         }
  //         return rec;
  //       });
  //       this.setState({
  //         records,
  //         validation: {},
  //         request: {},
  //         cmodal: false    ,
  //       });
  //     } else {
  //       const { messages } = data;
  //       if (messages) return alert(messages);
  //     }
  //   } else alert("Network Error !");
  // };
  // fastEdit = async (obj) => {
  //   const { field, value, record } = obj;
  //       let { records, doUserLogout, Chabi } = this.state;
  //   if (record[field] == value) return;
  //   const _id = record["_id"];
  //   const url = `${message_api}/f`;
  //   const response = await myAxios({
  //     method: "put",
  //     url,
  //     request: { _id, [field]: value },
  //   });
  //   if (response.status == 200) {
  //     const { data } = response;
  //     if (data.status == 420) {
  //       this.setState({
  //         loggedUser: undefined,
  //         loginStatus: undefined,
  //       });
  //       doUserLogout();
  //     }
  //     if (data.status == 200) {
  //       records = records.map((r) => {
  //         if (r._id == _id) r[field] = value;
  //         return r;
  //       });
  //       this.setState({ records });
  //       return true;
  //     } else {
  //       const { messages } = data;
  //       if (messages) {
  //         message.error(messages);
  //         return false;
  //       }
  //     }
  //   } else alert("Network Error !");
  // };
  render() {
    console.log("message State ", this.state);
    let {
      records,
      edit,
      page_api,
      loginStatus,
      cmodal,
      request,
      filtered_records,
      learner,
      consultant,
    } = this.state;
    if (loginStatus != true) return <Navigate to="/" />;
    if (filtered_records) records = filtered_records;
    records = [...records];
    const columns = createColumn({
      records,
      displayFields: this.displayFields,
      fastEdit: this.fastEdit,
    });
    return (
      <>
        <PageHeading2 active="Message" page={["Message"]}>
          <button
            className="add-btn"
            onClick={() => {
              this.setState({ cmodal: !cmodal, edit: false, request: {} });
            }}
          >
            Add New
          </button>
        </PageHeading2>
        <MainArea>
          {/* <Row style={{ marginTop: "20px" }}>
            <Col span={8}>
              <FilterSelectGroup
                id="active"
                name="active"
                label="Status"
                placeholder="Status"
                options={[
                  { text: "Active", value: "1" },
                  { text: "Deactive", value: "0" },
                ]}
                option_value="value"
                option_text="text"
                sendValue={this.set_filter}
              />
            </Col>
          </Row>
          <Row>
            <Col span={2} className="m-2">
              <button className="filter-btn" onClick={this.run_filter}>
                Filter
              </button>
            </Col>
          </Row> */}
          {records && records.length && columns && columns.length > 0 ? (
            <MyDataTable
              rowKey="_id"
              columns={columns}
              dataSource={records}
              // loadData={this.loadRecord}
              // removeData={this.removeRecord}
              // activeData={this.activeRecord}
              pagination={true}
            />
          ) : (
            <MyEmpty />
          )}
        </MainArea>
        <CustomModal
          key={request._id}
          status={cmodal}
          title="Manage Message"
          close={this.close_modal}
          className="drawer-form-message"
        >
          <FormContainer>
            <FormSelectGroup
              id="type"
              name="type"
              label="Type"
              placeholder="Type"
              data={this.state}
              required
              options={[{ value: "learner" }, { value: "consultant" }]}
              option_value="value"
              option_text="value"
              setValidation={this.setValidation}
              sendValue={(obj) => {
                this.setRequest({ name: "to", value: "" });
                const { name, value } = obj;
                if (value.length) this.setRequest(obj);
                else this.setRequest({ name, value: [] });
              }}
            />
            {request.type && request.type.length ? (
              <>
                <FormCheckboxSingleGroup
                  id="all"
                  name="all"
                  label="Send To All"
                  sub_label="(Optional)"
                  data={this.state}
                  options={{ text: "Yes", value: "yes" }}
                  option_value="value"
                  option_text="text"
                  sendValue={this.setRequest}
                />
                {!request.all || !request.all.length ? (
                  <FormMultiSelectGroup
                    id="to"
                    name="to"
                    label="To"
                    placeholder="To"
                    data={this.state}
                    required
                    options={request.type == "learner" ? learner : consultant}
                    option_value="_id"
                    option_text={"first_name"}
                    setValidation={this.setValidation}
                    sendValue={this.setRequest}
                  />
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            <FormInputGroup
              id="subject"
              name="subject"
              label="Subject"
              placeholder="Subject"
              data={this.state}
              required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            <FormTextareaGroup
              id="message"
              name="message"
              label="Message"
              placeholder="Message"
              data={this.state}
              required
              setValidation={this.setValidation}
              sendValue={this.setRequest}
            />
            <FormButton
              submit={() => {
                //check validation status here
                if (edit) this.editMessage();
                else this.addMessage();
              }}
              reset={() => this.setState({ request: {} })}
              cancel={this.close_modal}
            />
          </FormContainer>
        </CustomModal>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    loginStatus: state.loginStatus,
    Chabi: state.Chabi,
    loggedUser: state.loggedUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    doUserLogout: () => dispatch(userLogout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Message);
