export const end_points = [
  { path: "/bulk_order", methods: ["POST"], middlewares: ["create"] },
  { path: "/bulk_order/page/", methods: ["POST"], middlewares: ["get"] },
  {
    path: "/bulk_order/payment_part1",
    methods: ["POST"],
    middlewares: ["payment_part1"],
  },
  {
    path: "/bulk_order/payment_part2",
    methods: ["POST"],
    middlewares: ["payment_part2"],
  },
  { path: "/bulk_order/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/career", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/career/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/career/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/front/home/", methods: ["GET"], middlewares: ["home"] },
  { path: "/front/lev/:_id", methods: ["GET"], middlewares: ["lev"] },
  {
    path: "/front/lrp/:_id/:reset_password",
    methods: ["GET"],
    middlewares: ["lrp"],
  },
  {
    path: "/front/nlba/:_id/:reset_password",
    methods: ["GET"],
    middlewares: ["nlba"],
  },
  { path: "/front/oev/:_id", methods: ["GET"], middlewares: ["oev"] },
  {
    path: "/front/orp/:_id/:reset_password",
    methods: ["GET"],
    middlewares: ["orp"],
  },
  {
    path: "/front/noba/:reset_password",
    methods: ["GET"],
    middlewares: ["noba"],
  },
  { path: "/front/courses/", methods: ["GET"], middlewares: ["courses"] },
  { path: "/front/consulting/", methods: ["GET"], middlewares: ["consulting"] },
  { path: "/front/auditing/", methods: ["GET"], middlewares: ["auditing"] },
  { path: "/front/industries/", methods: ["GET"], middlewares: ["industries"] },
  { path: "/front/about_us/", methods: ["GET"], middlewares: ["about_us"] },
  { path: "/front/training/", methods: ["GET"], middlewares: ["training"] },
  { path: "/front/contact_us/", methods: ["GET"], middlewares: ["contact_us"] },
  { path: "/front/cart_page/", methods: ["POST"], middlewares: ["cart_page"] },
  {
    path: "/front/registration/",
    methods: ["GET"],
    middlewares: ["registration"],
  },
  {
    path: "/front/organization_registration/",
    methods: ["GET"],
    middlewares: ["organization_registration"],
  },
  {
    path: "/front/consultant_registration/",
    methods: ["GET"],
    middlewares: ["consultant_registration"],
  },
  {
    path: "/front/single_course/:slug",
    methods: ["GET"],
    middlewares: ["single_course"],
  },
  {
    path: "/front/single_training/:slug",
    methods: ["GET"],
    middlewares: ["single_training"],
  },
  {
    path: "/front/single_consulting/:slug",
    methods: ["GET"],
    middlewares: ["single_consulting"],
  },
  {
    path: "/front/single_auditing/:slug",
    methods: ["GET"],
    middlewares: ["single_auditing"],
  },
  {
    path: "/front/bulk_order/:slug",
    methods: ["GET"],
    middlewares: ["bulk_order"],
  },
  { path: "/front/dashboard", methods: ["POST"], middlewares: ["dashboard"] },
  {
    path: "/front/user_profile",
    methods: ["POST"],
    middlewares: ["user_profile"],
  },
  {
    path: "/front/user_tickets",
    methods: ["POST"],
    middlewares: ["user_tickets"],
  },
  {
    path: "/front/user_messages",
    methods: ["POST"],
    middlewares: ["user_messages"],
  },
  {
    path: "/front/user_orders",
    methods: ["POST"],
    middlewares: ["user_orders"],
  },
  {
    path: "/front/user_bulk_orders",
    methods: ["POST"],
    middlewares: ["user_bulk_orders"],
  },
  {
    path: "/front/user_trainings",
    methods: ["POST"],
    middlewares: ["user_trainings"],
  },
  { path: "/industries", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/industries/image/:img", methods: ["GET"], middlewares: ["image"] },
  { path: "/industries/:id?", methods: ["GET"], middlewares: ["get"] },
  {
    path: "/industries/image_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "image_upload"],
  },
  { path: "/industries/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/accreditation", methods: ["POST", "PUT"], middlewares: ["create"] },
  {
    path: "/accreditation/image/:img",
    methods: ["GET"],
    middlewares: ["image"],
  },
  { path: "/accreditation/:id?", methods: ["GET"], middlewares: ["get"] },
  {
    path: "/accreditation/image_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "image_upload"],
  },
  { path: "/accreditation/f/", methods: ["PUT"], middlewares: ["fast"] },
  {
    path: "/auditing_scheme",
    methods: ["POST", "PUT"],
    middlewares: ["create"],
  },
  {
    path: "/auditing_scheme/image/:img",
    methods: ["GET"],
    middlewares: ["image"],
  },
  { path: "/auditing_scheme/:id?", methods: ["GET"], middlewares: ["get"] },
  {
    path: "/auditing_scheme/image_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "image_upload"],
  },
  { path: "/auditing_scheme/f/", methods: ["PUT"], middlewares: ["fast"] },
  {
    path: "/auditing_service_type_point",
    methods: ["POST", "PUT"],
    middlewares: ["create"],
  },
  {
    path: "/auditing_service_type_point/:id?",
    methods: ["GET"],
    middlewares: ["get"],
  },
  {
    path: "/auditing_service_type_point/f/",
    methods: ["PUT"],
    middlewares: ["fast"],
  },
  { path: "/auditing_type", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/auditing_type/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/auditing_type/f/", methods: ["PUT"], middlewares: ["fast"] },
  {
    path: "/auditing_service",
    methods: ["POST", "PUT"],
    middlewares: ["create"],
  },
  { path: "/auditing_service/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/auditing_service/seo", methods: ["PUT"], middlewares: ["seo"] },
  {
    path: "/auditing_service/image_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "image_upload"],
  },
  {
    path: "/auditing_service/image/:img",
    methods: ["GET"],
    middlewares: ["image"],
  },
  { path: "/auditing_service/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/banner", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/banner/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/banner/image/:img", methods: ["GET"], middlewares: ["image"] },
  {
    path: "/banner/image_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "image_upload"],
  },
  { path: "/banner/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/cart", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/cart/page/", methods: ["POST"], middlewares: ["get"] },
  { path: "/cart/remove", methods: ["POST"], middlewares: ["remove"] },
  { path: "/cart/checkout", methods: ["POST"], middlewares: ["checkout"] },
  { path: "/cart/payment", methods: ["POST"], middlewares: ["payment"] },
  {
    path: "/cart/payment_done",
    methods: ["POST"],
    middlewares: ["payment_done"],
  },
  {
    path: "/cart/set_material",
    methods: ["POST"],
    middlewares: ["set_material"],
  },
  { path: "/cart/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/coupon", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/coupon/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/coupon/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/client", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/client/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/client/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/client/image/:img", methods: ["GET"], middlewares: ["image"] },
  {
    path: "/client/image_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "image_upload"],
  },
  { path: "/consultant/login", methods: ["POST"], middlewares: ["login"] },
  { path: "/consultant/logout", methods: ["POST"], middlewares: ["logout"] },
  { path: "/consultant/ce", methods: ["POST"], middlewares: ["check_email"] },
  { path: "/consultant/cm", methods: ["POST"], middlewares: ["check_mobile"] },
  {
    path: "/consultant/registration",
    methods: ["POST"],
    middlewares: ["registration"],
  },
  { path: "/consultant/get/:id?", methods: ["POST"], middlewares: ["get"] },
  { path: "/consultant/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/consultant/image/:img", methods: ["GET"], middlewares: ["image"] },
  {
    path: "/consultant/image_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "image_upload"],
  },
  {
    path: "/corporate_brochure",
    methods: ["POST", "PUT"],
    middlewares: ["create"],
  },
  { path: "/corporate_brochure/file", methods: ["GET"], middlewares: ["file"] },
  { path: "/corporate_brochure/:id?", methods: ["GET"], middlewares: ["get"] },
  {
    path: "/corporate_brochure/file_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "file_upload"],
  },
  { path: "/corporate_brochure/f/", methods: ["PUT"], middlewares: ["fast"] },
  {
    path: "/compentency_units",
    methods: ["POST", "PUT"],
    middlewares: ["create"],
  },
  { path: "/compentency_units/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/compentency_units/f/", methods: ["PUT"], middlewares: ["fast"] },
  {
    path: "/consulting_scheme",
    methods: ["POST", "PUT"],
    middlewares: ["create"],
  },
  {
    path: "/consulting_scheme/image/:img",
    methods: ["GET"],
    middlewares: ["image"],
  },
  { path: "/consulting_scheme/:id?", methods: ["GET"], middlewares: ["get"] },
  {
    path: "/consulting_scheme/image_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "image_upload"],
  },
  { path: "/consulting_scheme/f/", methods: ["PUT"], middlewares: ["fast"] },
  {
    path: "/consulting_service_type_point",
    methods: ["POST", "PUT"],
    middlewares: ["create"],
  },
  {
    path: "/consulting_service_type_point/:id?",
    methods: ["GET"],
    middlewares: ["get"],
  },
  {
    path: "/consulting_service_type_point/f/",
    methods: ["PUT"],
    middlewares: ["fast"],
  },
  {
    path: "/consulting_type",
    methods: ["POST", "PUT"],
    middlewares: ["create"],
  },
  { path: "/consulting_type/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/consulting_type/f/", methods: ["PUT"], middlewares: ["fast"] },
  {
    path: "/consulting_service",
    methods: ["POST", "PUT"],
    middlewares: ["create"],
  },
  { path: "/consulting_service/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/consulting_service/seo", methods: ["PUT"], middlewares: ["seo"] },
  {
    path: "/consulting_service/image_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "image_upload"],
  },
  {
    path: "/consulting_service/image/:img",
    methods: ["GET"],
    middlewares: ["image"],
  },
  { path: "/consulting_service/f/", methods: ["PUT"], middlewares: ["fast"] },
  {
    path: "/contact_details",
    methods: ["POST", "PUT"],
    middlewares: ["create"],
  },
  { path: "/contact_details/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/contact_details/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/content", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/content/image/:img", methods: ["GET"], middlewares: ["image"] },
  { path: "/content/:id?", methods: ["GET"], middlewares: ["get"] },
  {
    path: "/content/image_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "image_upload"],
  },
  { path: "/content/f/", methods: ["PUT"], middlewares: ["fast"] },
  {
    path: "/course_category",
    methods: ["POST", "PUT"],
    middlewares: ["create"],
  },
  { path: "/course_category/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/course_category/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/course_type", methods: ["POST", "PUT"], middlewares: ["createe"] },
  { path: "/course_type/:id?", methods: ["GET"], middlewares: ["gete"] },
  { path: "/course_type/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/course", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/course/image/:img", methods: ["GET"], middlewares: ["image"] },
  { path: "/course/file/:fle", methods: ["GET"], middlewares: ["file"] },
  { path: "/course/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/course/seo", methods: ["PUT"], middlewares: ["seo"] },
  {
    path: "/course/image_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "image_upload"],
  },
  {
    path: "/course/file_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "file_upload"],
  },
  { path: "/course/f/", methods: ["PUT"], middlewares: ["fast"] },
  {
    path: "/delivery_method",
    methods: ["POST", "PUT"],
    middlewares: ["create"],
  },
  { path: "/delivery_method/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/delivery_method/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/evaluation", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/evaluation/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/evaluation/f/", methods: ["PUT"], middlewares: ["fast"] },
  {
    path: "/evaluation_question",
    methods: ["POST", "PUT"],
    middlewares: ["create"],
  },
  { path: "/evaluation_question/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/evaluation_question/f/", methods: ["PUT"], middlewares: ["fast"] },
  {
    path: "/evaluation_section",
    methods: ["POST", "PUT"],
    middlewares: ["create"],
  },
  { path: "/evaluation_section/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/evaluation_section/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/exam", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/exam/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/exam/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/exam_section", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/exam_section/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/exam_section/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/faq", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/faq/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/faq/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/home", methods: ["GET"], middlewares: ["anonymous"] },
  { path: "/insight", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/insight/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/insight/f/", methods: ["PUT"], middlewares: ["fast"] },
  {
    path: "/insight_category",
    methods: ["POST", "PUT"],
    middlewares: ["create"],
  },
  { path: "/insight_category/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/insight_category/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/language", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/language/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/language/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/lca", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/lca/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/lca/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/learners/login", methods: ["POST"], middlewares: ["login"] },
  { path: "/learners/logout", methods: ["POST"], middlewares: ["logout"] },
  { path: "/learners/ce", methods: ["POST"], middlewares: ["check_email"] },
  { path: "/learners/cm", methods: ["POST"], middlewares: ["check_mobile"] },
  { path: "/learners/add", methods: ["POST"], middlewares: ["add"] },
  { path: "/learners/forgot", methods: ["POST"], middlewares: ["forgot"] },
  { path: "/learners/reset", methods: ["POST"], middlewares: ["reset"] },
  { path: "/learners/cp", methods: ["POST"], middlewares: ["change_password"] },
  {
    path: "/learners/cpa",
    methods: ["PUT"],
    middlewares: ["change_password_by_admin"],
  },
  {
    path: "/learners/registration",
    methods: ["POST"],
    middlewares: ["registration"],
  },
  { path: "/learners/get/:id?", methods: ["POST"], middlewares: ["get"] },
  { path: "/learners", methods: ["PUT"], middlewares: ["update"] },
  { path: "/learners/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/learners/image/:img", methods: ["GET"], middlewares: ["image"] },
  {
    path: "/learners/image_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "image_upload"],
  },
  {
    path: "/learning_scheme",
    methods: ["POST", "PUT"],
    middlewares: ["create"],
  },
  {
    path: "/learning_scheme/image/:img",
    methods: ["GET"],
    middlewares: ["image"],
  },
  { path: "/learning_scheme/:id?", methods: ["GET"], middlewares: ["get"] },
  {
    path: "/learning_scheme/image_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "image_upload"],
  },
  { path: "/learning_scheme/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/location", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/location/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/location/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/mapping", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/mapping/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/mapping/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/message", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/message/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/message/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/nda", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/nda/file/:fl", methods: ["GET"], middlewares: ["file"] },
  { path: "/nda/:id?", methods: ["GET"], middlewares: ["get"] },
  {
    path: "/nda/file_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "file_upload"],
  },
  { path: "/nda/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/newsletter", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/newsletter/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/newsletter/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/news_update", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/news_update/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/news_update/image/:img", methods: ["GET"], middlewares: ["image"] },
  {
    path: "/news_update/image_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "image_upload"],
  },
  { path: "/news_update/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/organization/login", methods: ["POST"], middlewares: ["login"] },
  { path: "/organization/logout", methods: ["POST"], middlewares: ["logout"] },
  { path: "/organization/ce", methods: ["POST"], middlewares: ["check_email"] },
  {
    path: "/organization/cm",
    methods: ["POST"],
    middlewares: ["check_mobile"],
  },
  { path: "/organization/add", methods: ["POST"], middlewares: ["add"] },
  { path: "/organization/forgot", methods: ["POST"], middlewares: ["forgot"] },
  { path: "/organization/reset", methods: ["POST"], middlewares: ["reset"] },
  {
    path: "/organization/cp",
    methods: ["POST"],
    middlewares: ["change_password"],
  },
  {
    path: "/organization/registration",
    methods: ["POST"],
    middlewares: ["registration"],
  },
  { path: "/organization/get/:id?", methods: ["POST"], middlewares: ["get"] },
  { path: "/organization/update", methods: ["PUT"], middlewares: ["update"] },
  { path: "/organization/f/", methods: ["PUT"], middlewares: ["fast"] },
  {
    path: "/organization/image/:img",
    methods: ["GET"],
    middlewares: ["image"],
  },
  {
    path: "/organization/image_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "image_upload"],
  },
  {
    path: "/product_fam_points",
    methods: ["POST", "PUT"],
    middlewares: ["create"],
  },
  { path: "/product_fam_points/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/product_fam_points/f/", methods: ["PUT"], middlewares: ["fast"] },
  {
    path: "/review_question",
    methods: ["POST", "PUT"],
    middlewares: ["create"],
  },
  { path: "/review_question/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/review_question/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/region", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/region/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/region/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/seo", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/seo/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/seo/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/settings", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/settings/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/settings/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/social_media", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/social_media/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/social_media/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/team", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/team/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/team/image/:img", methods: ["GET"], middlewares: ["image"] },
  {
    path: "/team/image_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "image_upload"],
  },
  { path: "/team/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/team_group", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/team_group/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/team_group/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/testimonial", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/testimonial/image/:img", methods: ["GET"], middlewares: ["image"] },
  { path: "/testimonial/:id?", methods: ["GET"], middlewares: ["get"] },
  {
    path: "/testimonial/image_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "image_upload"],
  },
  { path: "/testimonial/f/", methods: ["PUT"], middlewares: ["fast"] },
  {
    path: "/training/365",
    methods: ["POST", "PUT"],
    middlewares: ["create_365"],
  },
  { path: "/training", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/training/365/:id?", methods: ["GET"], middlewares: ["get_365"] },
  { path: "/training/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/training/seo", methods: ["PUT"], middlewares: ["seo"] },
  { path: "/training/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/user/login", methods: ["POST"], middlewares: ["login"] },
  { path: "/user", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/user/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/user/image/:img", methods: ["GET"], middlewares: ["image"] },
  {
    path: "/user/image_upload",
    methods: ["POST"],
    middlewares: ["multerMiddleware", "image_upload"],
  },
  { path: "/user/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/venue", methods: ["POST", "PUT"], middlewares: ["create"] },
  { path: "/venue/:id?", methods: ["GET"], middlewares: ["get"] },
  { path: "/venue/f/", methods: ["PUT"], middlewares: ["fast"] },
  {
    path: "/enquiry/course",
    methods: ["POST"],
    middlewares: ["course_enquiry"],
  },
  {
    path: "/enquiry/training",
    methods: ["POST"],
    middlewares: ["training_enquiry"],
  },
  {
    path: "/enquiry/contact",
    methods: ["POST"],
    middlewares: ["contact_enquiry"],
  },
  { path: "/enquiry/get", methods: ["POST"], middlewares: ["get"] },
  { path: "/enquiry/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/stripe/checkout", methods: ["POST"], middlewares: ["checkout"] },
  {
    path: "/stripe/checkout_org",
    methods: ["POST"],
    middlewares: ["checkout_org"],
  },
  { path: "/ticket", methods: ["POST"], middlewares: ["create"] },
  { path: "/ticket/get", methods: ["POST"], middlewares: ["get"] },
  { path: "/ticket/f/", methods: ["PUT"], middlewares: ["fast"] },
  { path: "/back/message", methods: ["POST"], middlewares: ["pageMessage"] },
];
